import { Container, Row, Col, Image } from 'react-bootstrap'

export default function LPBrokersCustomerExp() {
  return (
    <>
      <section className="space-top space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center g-5 space-small-bottom">
                <Col lg={6}>
                  <p className="subtitle">Customer experience</p>
                  <h2 className="mb-0">
                    Ace your end-to-end customer experience
                  </h2>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend fw-400 mb-0">
                    Until now, investor communications have been uninviting,
                    uninformative, and unimaginative. Lilypads provides brokers
                    the opportunity to transform this compliance function into
                    an experience that customers deserve
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-5 text-center">
            <Col lg={6}>
              <div className="customer-exp-img">
                <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/message-ads1.png" />
                <div className="customer-exp-img-content">
                  <p className="font-lexend fw-600 mb-3 big">
                    Your customers, your brand
                  </p>
                  <p className="font-lexend fw-400 mb-0">
                    From promoting listings to improving marketing support,
                    Lilypads helps you in enhancing your brand awareness and
                    customer experience
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="customer-exp-img">
                <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/message-ads2.png" />
                <div className="customer-exp-img-content">
                  <p className="font-lexend fw-600 mb-3 big">
                    The information investors want
                  </p>
                  <p className="font-lexend fw-400 mb-0">
                    {' '}
                    Lilypads enables you to gain real-time visibility into your
                    acquisitions and transactions in an engaged and integrated
                    environment
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from './../../Components/Slider/Slider'
import onePng from '../../assets/images/newLanding/scroll/one.png'
import twoPng from '../../assets/images/newLanding/scroll/two.png'
import threePng from '../../assets/images/newLanding/scroll/three.png'
import fourPng from '../../assets/images/newLanding/scroll/four.png'
import fivePng from '../../assets/images/newLanding/scroll/five.png'
const JoinCommunity = [
  {
    title: 'CRM Network',
    text:
      'Our integrated CRM platform effectively facilitates collaboration and partnerships between fund managers, and owner-operators through simplified investor communication portals with centralized communication features, team activity trackers, and contact and lead management tools.',
    Img: onePng,
  },
  {
    title: 'Fundraising',
    text:
      'Our leading-edge fundraising tool with custom-built fundraising dashboards and data rooms allows investors and owner-operators to raise more capital and close deals faster with seamless KYC and NDA automation',
    Img: twoPng,
  },
  {
    title: 'Investor Reporting',
    text:
      'Institutional managers, Deal Managers, and Owner Operators can now generate automated investor and asset reports, statements, notices capital calls, and distributions using personalized templates',
    Img: threePng,
  },
  {
    title: 'Investor Portal',
    text:
      'We maximize investor engagement and satisfaction through our robust and interactive investor portal that allows investor activity tracking, secures document sharing, provides detailed asset reporting and performance-metric analysis, and enterprise-level data encryption',
    Img: fourPng,
  },
  {
    title: 'Insights',
    text:
      'Using our intelligent Insights tool, Owner-operators can access a personal dashboard that aggregates all their data gathered from multiple platform tools and generates data-driven actionable insights to boost their business performance.',
    Img: fivePng,
  },
]

export default function LPNSecFourMOB() {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <>
      <section className="space-bottom lpspacer">
        <Container>
          <p className="headNav">/ Platform</p>
          <Slider {...settings} className="custom-slider arrows-center">
            {JoinCommunity.map((item, i) => {
              return (
                <div className="" key={i}>
                  <h2>{item.title}</h2>
                  <p className="font-lexend mb-5 fw-400">{item.text}</p>
                  <a href="/join-waiting-list">
                    <Button variant="none" className="btn-bordered-purple">
                      Join the Community
                    </Button>
                  </a>
              
                  <Image fluid src={item.Img} className="mx-auto lpspacerIMG" />
                </div>
              )
            })}
          </Slider>
        </Container>
      </section>
    </>
  )
}

import inteligencemind from '../../../assets/images/Discover/Group 48096542.png'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'


const BecomeInvestor = [
    {
      text:
        'You can discover and research assets quicker with Lilypads platform',
    },
    {
      text: 'Intelligence assets matching algorithm helps you win more businesses and invest intelligently',
    },
    {
      text:
        'Our database contains every source you will need to network and invest',
    },
    {
      text:
        'Close deals faster, get a warm introduction from those with a close relationship.',
    },
  ]
  

export default function LPDiscoverSection2(){
    return(
        <div className='inteligencemindalingnment Discoverresponsiveness'>
            
              <Col lg={6}>
            <div className='inteligencemind'>
             
                <img className='inteligencemindimg' src={inteligencemind}/>
               
            </div>
            </Col>
            <Col lg={4}>
             <div> 
            
              <Row>
               
                  <h2 className="color-font mb-4">
                    Become an Investor on Lilypads
                  </h2>
                  {BecomeInvestor.map((item, i) => {
                    return (
                      <div className="d-flex">
                        <span className="">
                          {LPSVGIcons.DoubleCheck}
                        </span>
                        <p
                          key={i}
                          className="font-lexend mb-4 fw-400 color-font small"
                        >
                          {item.text}
                        </p>
                      </div>
                    )
                  })}
                  <a href="/join-waiting-list">
                    <Button
                      variant="none"
                      className="bold btn-border  fw-600 mt-4"
                    >
                      Become an Investor
                    </Button>
                  </a>
               
              </Row>
            
             </div>
             </Col>
             
        </div>
    );
}
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'

import React, { useEffect, useState } from 'react'
export default function LPJoinWaitingListNow() {
  function Heading({ text, arc, radius }) {
    const characters = text.split('')
    const degree = arc / characters.length

    return (
      <>
        {characters.map((char, i) => (
          <span
            key={`heading-span-${i}`}
            style={{
              height: `${radius}px`,
              transform: `rotate(${degree * i - arc / 2}deg)`,
              transformOrigin: `0 ${radius}px 0`,
            }}
          >
            {char}
          </span>
        ))}
      </>
    )
  }
  // Heading.propTypes = {
  //   text: PropTypes.string.isRequired,
  //   arc: PropTypes.number, // how curved do you want the text
  //   radius: PropTypes.number // how big do you want the curve
  // };

  return (
    <>
      <div className="space-top join-waiting-list-now position-relative">
        <Container className="pt-5">
          <Row>
            <Col md={6} className="space-bottom mb-5">
              <span className="text-white h1 small mb-4 d-block">
                Join the waiting list now for early benefits
              </span>
              <p className="color-light-dark mb-5 fw-400">
                Join the Waitlist to avail of early access to lucrative
                investment opportunities in private placements and top-notch
                commercial real estate deals across the US
              </p>
              <Row>
                <Col md={8}>
                  <div className="footer-subscribe mb-4">
                    {/* <Form.Control
                      value={email}
                      type="email"
                      onChange={handleChange}
                      placeholder="Enter Your Email Here"
                    /> */}
                  </div>
                  <a href="#">
                    <Button
                      variant="none"
                      // onClick={handleEmailSubmit}
                      className="btn-purple btn-shadow w-100"
                    >
                      Join the waiting list
                    </Button>
                  </a>
                </Col>
              </Row>
              <Row className="g-4 d-xl-none d-flex mt-5">
                <Col>
                  <span className="h2 big color-purple font-lexend fw-800 mb-1">
                    658+
                  </span>
                  <p className="color-white">Properties</p>
                </Col>
                <Col>
                  <span className="h2 big color-purple font-lexend fw-800 mb-1">
                    658+
                  </span>
                  <p className="color-white">Deals</p>
                </Col>
                <Col>
                  <span className="h2 big color-purple font-lexend fw-800 mb-1">
                    255+
                  </span>
                  <p className="color-white">Investors</p>
                </Col>
              </Row>
            </Col>
            <Col md={6} className="text-center position-relative">
              <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/join-waiting-list/join-waiting-list-now.png" className="mx-auto" />
            </Col>
          </Row>
        </Container>
        <div className="cricle-wrapper d-xl-flex d-none">
          <div className="color-white cricle">
            <h4 className="big fw-700 mb-0 color-purple">658+</h4>
            <Heading
              text="Properties Properties Properties Properties Properties "
              arc={360}
              radius={80}
            />
          </div>
          <div className="color-white cricle">
            <h4 className="big fw-700 mb-0 color-purple">658+</h4>
            <Heading
              text="Deals Deals Deals Deals Deals Deals Deals Deals "
              arc={360}
              radius={80}
            />
          </div>
          <div className="color-white cricle">
            <h4 className="big fw-700 mb-0 color-purple">255+</h4>
            <Heading
              text="Investors Investors Investors Investors Investors"
              arc={360}
              radius={80}
            />
          </div>
        </div>
      </div>
    </>
  )
}

import { Container, Row, Col, Image } from 'react-bootstrap'

export const Discoverperpectivecard = (props) => {
    return(
        <>
        <Row className='DicoverCard'>
          <div className='CardHeading'> {props.CardHeading}</div>
          <div className='CardParagraph'>{props.Cardparagraph}</div>  
        </Row>
        </>
    );
}
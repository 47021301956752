import { Container, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
import Slider from 'react-slick'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../Components/Slider/Slider'

const members = [
  {
    img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/story/member1.png',
    fName: 'Thomas',
    lName: 'Lah',
    designation: 'Founder, Chairman, & CEO',
    sign: 'Thomas Lah',
    desc:
      'Thomas P. Lah directs investment strategy, and decisions, governance and investor relations. ecological responsibility and diversity and inclusion for the prosperity of all.',
    // facebook: 'https://facebook.com',
    linkedin: 'https://www.linkedin.com/in/thomas-p-lah/',
    // twitter: 'https://twitter.com',
  },
  {
    img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/story/member2.png',
    fName: 'Joe ',
    lName: 'Copsey',
    designation: 'CO FOUNDER & COO',
    sign: 'Joe Copsey',
    desc:
      'Meet our culture agent and steward obsessed with creating efficiencies and great experiences for our clients and business partners',
    facebook: 'https://facebook.com',
    linkedin: 'https://linkedin.com',
    twitter: 'https://twitter.com',
  },
  {
    img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/story/member3.png',
    fName: 'Karthik ',
    lName: 'Krishnan',
    designation: 'CO FOUNDER & EVP',
    sign: 'Karthik Krishnan',
    desc:
      'Karthik Krishnan is the co-founder and executive vice president of the Lilypads Platform.',
    // facebook: 'https://facebook.com',
    linkedin: 'https://www.linkedin.com/in/kkrishnan93/',
    // twitter: 'https://twitter.com',
  },
]

export default function LPStoryOurTeamMob() {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <>
      <section className="space-top space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle font-lexend">Our team </p>
                  <Row className="align-items-center">
                    <Col lg={10}>
                      <h2 className="">Team behind the success stories</h2>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <p className="fw-400">
                    We believe in nurturing the spirit of collaboration to
                    achieve relentless innovations and growth in our operations
                  </p>
                </Col>
              </Row>
              <div className="member-slider custom-slider">
                <Slider {...settings}>
                  {members.map((item, i) => {
                    return (
                      <div className="">
                        <Row className="justify-content-between">
                          <Col md={5}>
                            <Image fluid src={item.img} className="ms-auto" />
                          </Col>
                          <Col md={6}>
                            <div className="space-small-top">
                              <div className="d-flex member-details align-items-center mb-4">
                                <div className="member-name">
                                  <p className="big font-dm-serif w-400 color-maroon mb-1">
                                    {item.fName} {item.lName}
                                  </p>
                                  <p>{item.designation}</p>
                                </div>
                                <div className="member-sign ms-5 ps-3">
                                  <h6 className="font-reey color-maroon">
                                    {item.sign}
                                  </h6>
                                </div>
                              </div>
                              <div className="member-desc">
                                <p className="small fw-400">{item.desc}</p>
                                <div className="memeber-socials">
                                  {/* <a
                                    href={item.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="me-3"
                                  >
                                    {LPSVGIcons.Twitter}
                                  </a> */}
                                  <a
                                    href={item.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="me-3"
                                  >
                                    {LPSVGIcons.LinkedIn}
                                  </a>
                                  {/* <a
                                    href={item.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className=""
                                  >
                                    {LPSVGIcons.Facebook}
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

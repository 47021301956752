import { Col, Container, Image, Row } from "react-bootstrap";
export default function LPLendersLiquidAssest() {
  const LiquidAssestList = [
    {
      title: "100% of projects fully paid back",
      text: "You will receive your capital plus interest, once the project you've invested in is complete and the investors or General Partner has repaid.",
    },
    {
      title: "The Lilypads Shield",
      text: "Lilypads utilizes an industry-leading blockchain security system backed by SEC regulations for safeguarding your investments",
    },
    {
      title: "Up to 8% per year backed by first charge security",
      text: "Invest through Lilypads to earn the dual benefits of up to 8% returns per year on investment along with first charge security",
    },
  ];
  return (
    <>
      <section className="space-top space-small-bottom">
        <Container>
          <Row className="justify-content-center g-5">
            <Col lg={6}>
              <Row className="justify-content-end">
                <Col lg={10}>
                  <p className="subtitle fw-700">Liquid assest</p>
                  <h2>Why invest through Lilypads?</h2>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={10}>
                  <p className="font-lexend fw-400 small">
                    At Lilypads, we see thousands of deals a year and we know it
                    takes time and work for you to review deals and figure out
                    what you can offer
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="justify-content-end">
                <Col lg={10}>
                  {LiquidAssestList.map((item, i) => {
                    return (
                      <>
                        <div key={i} className=" mb-5">
                          <p className="font-lexend big fw-600 mb-2">
                            {item.title}
                          </p>
                          <p className="font-lexend fw-400 small mb-0">
                            {item.text}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="text-center" >
              <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/lenders/liquid-assest.png" className="theme-shadow" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

import  mutualgoals from '../../../assets/images/Manage/Group 48096550.png'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'


const BecomeInvestor = [
    {
      text:
        'Give investor on-demand access to their investmeent information',
    },
    {
      text: 'Provide professional experience to the investor with seamless communications',
    },
    {
      text:
        'Guarantee the security of crucial documents and provides more confidence to conduct business',
    },
    
  ]
  

export default function LPManageSection5(){
    return(
      <Container>
        <div className='inteligencemindalingnment Discoverresponsiveness'>
          
              <Col lg={6}>
            <div className='cashflowimg'>
             
                <img className='mutualgoals' src={ mutualgoals}/>
               
            </div>
            </Col>
            <Col lg={4}>
             <div> 
            
              <Row>
               
                  <h2 className="color-font mb-4">
                  We help to achieve mutual goals
                  </h2>
                  {BecomeInvestor.map((item, i) => {
                    return (
                      <div className="d-flex">
                        <span className="">
                          {LPSVGIcons.DoubleCheck}
                        </span>
                        <p
                          key={i}
                          className="font-lexend mb-4 fw-400 color-font small"
                        >
                          {item.text}
                        </p>
                      </div>
                    )
                  })}
                  <a href="/join-waiting-list">
                    <Button
                      variant="none"
                      className="bold btn-border  fw-600 mt-4"
                    >
                      Strat Investing Now
                    </Button>
                  </a>
               
              </Row>
            
             </div>
             </Col>
           
        </div>
        </Container>
    );
}
import React from "react";
import { Helmet } from "react-helmet";
import SecOneApplication from "./SecOneApplication.jsx";
import SecTwoCl from "./SecTwoCl";

export default function LPCareerApplication() {
  return (
    <>
      <Helmet>
        <title>Lilypads | Apply</title>
      </Helmet>

      {/* <SecTwoCl/> */}

      <SecOneApplication />

      {/* <LPHomeHero />
            <LPHomeJoinLilypads />
            <LPHomeAboutUs />
            <LPHomeProductImages />
            <LPHomeManageDeadflow />
            <LPWaitingList /> */}
    </>
  );
}

import { Container, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard';
import Discoverone from '../../../assets/images/Discover/01 (12).png'
import Discovertwo from '../../../assets/images/Discover/02 (12).png'
import Discoverthree from '../../../assets/images/Discover/03 (10).png'

export default function LPDiscoverSection1(){
    return(
     <>
    
        <section className="space-bottom">
        <Container style={{marginTop:100}}>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle">
                    Product {LPSVGIcons.AngleArrow} Discover
                  </p>
                  <h1 className="small">Community</h1>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend">
                    The Lilypads real estate investor network allows you to
                    expand your book of contacts to access opportunities and
                    grow your business
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </section>
        <section className='Discoverresponsiveness'> 
        <Container>
        
            <div className='perpectivecard '>
            <Col lg={3}>
              <div style={{margin:20}}>
                <img src={Discoverone}/>
                 <div className='perpectivecardindividual'> 
                   <Discoverperpectivecard
                    CardHeading='Get perspecive insight when it matters'
                    Cardparagraph='We have got the data, you have got the expertise combine two to get your next opporunity'
                   />
                 </div>
                 </div>
                 </Col>
                 <Col lg={3}>
                 <div style={{margin:20}}>
                 <img src={Discovertwo}/>
                 <div className='perpectivecardindividual'> 
                   <Discoverperpectivecard
                    CardHeading='Profile based opportunity'
                    Cardparagraph='Uncover any commercial property,anywhere in the U.S and identify properties of interest in fraction of time'
                   />
                 </div>
                 </div>
                 </Col>
                 <Col lg={3}>
                 <div style={{margin:20}}>
                 <img src={Discoverthree}/>
                 <div className='perpectivecardindividual'> 
                   <Discoverperpectivecard
                    CardHeading='Identify your real estate assest'
                    Cardparagraph='Narrow your search by : Owner name, Owner Record, Owner Occuppied, Mailing Address '
                   />
                 </div>
                 </div>
                 </Col>
            </div>
         
         </Container>
        </section>
        
     </>
    );
}
import Helmet from "react-helmet";
import LPWaitingList from "../../../Components/LPWaitingList/LPWaitingList";
import LPIInvestorBestDiscover from "./LPIInvestorBestDiscover/LPIInvestorBestDiscover";
import LPIInvestorDeatMaking from "./LPIInvestorDeatMaking/LPIInvestorDeatMaking";
import LPIInvestorFDT from "./LPIInvestorFDT/LPIInvestorFDT";
import LPIInvestorHero from "./LPIInvestorHero/LPIInvestorHero";
import LPIInvestorManageAccount from "./LPIInvestorManageAccount/LPIInvestorManageAccount";
import LPIInvestorWhoWeAre from "./LPIInvestorWhoWeAre/LPIInvestorWhoWeAre";
import "./LPInstitutionalInvestors.css";

export default function LPInstitutionalInvestors() {
    return (
        <>
            <Helmet>
                <title>Lilypads | Institutional Investors</title>
            </Helmet>
            <LPIInvestorHero />
            <LPIInvestorBestDiscover />
            <div className="who-we-are-wrapper">
                <LPIInvestorWhoWeAre />
                <LPIInvestorDeatMaking />
            </div>
            <LPIInvestorManageAccount />
            {/* <LPIInvestorFDT /> */}
            <LPWaitingList />
        </>
    )
}
import { Container, Row, Col } from 'react-bootstrap'
export default function LPStoryWhyInvest() {
  const WhyInvestList = [
    {
      title: 'Multiple Options',
      text:
        'Lilypads investors can directly invest in specific commercial real estate assets, funds, and syndicate of their choice rather than investing into a blind pool',
    },
    {
      title: 'Direct Investments',
      text:
        'We provide a summary of information about each property, including business model, project improvements, prospective tenants, projected performance metrics, peer review grade on investment, and transaction experience',
    },
    {
      title: 'Marketplace',
      text:
        ' Lilypads offers real estate investors a free investment marketplace and an advantageous environment of numerous investment options',
    },
    {
      title: 'Creating an Ecosystem',
      text:
        'The Lilypads investor community ecosystem allows investors to locate funds or start and manage a syndicate, connect with Lenders or Brokers, and curate content',
    },
    {
      title: 'Cutting Edge Technology',
      text:
        'We use data analysis, AI/ML, distributed computing, and other innovations to simplify CRE investing and improve investment experiences and decision-making',
    },
    {
      title: 'Web 3.0 Strategy',
      text:
        'Lilypads platform allows its network of active Investors and Brokers to execute their transactions and meet their investment goals seamlessly with the help of data-driven real estate acquisition, secured transactions, and transparency in property ownership and transfers. ',
    },
  ]
  return (
    <>
      <section className="space-top space-bottom bg-dark">
        <Container>
          <Row className="justify-content-center space-bottom">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <h2 className="text-white">Why invest on Lilypads?</h2>
                </Col>
                <Col lg={6}>
                  <p className="text-white fw-400">
                    Lilypads allows you to choose your investment strategy
                    across a series of diversified funds and syndicates to
                    maximize your returns
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-5">
            {WhyInvestList.map((item, i) => {
              return (
                <>
                  <Col lg={4} md={6} key={i}>
                    <div className="why-invest-card space-top">
                      <div className="bordered-text maroon">0{i + 1}</div>
                      <p className="big text-white mb-3">{item.title}</p>
                      <p className="text-white mb-0 fw-400 small">
                        {item.text}
                      </p>
                    </div>
                  </Col>
                </>
              )
            })}
          </Row>
        </Container>
      </section>
    </>
  )
}

import React from 'react'
import './LandingNew.scss'
import { Helmet } from 'react-helmet'
import LPNSecOne from './LPNSecOne'
import LPNSecTwo from './LPNSecTwo'
import LPNSecSix from './LPNSecSix'
import LPNSecFive from './LPNSecFive'
import LPNSecThree from './LPNSecThree'
import LPContactUsNEW from './../LPContactUs/LPContactUsNEW'
import LPNSecFour from './LPNSecFour'
import LPFullPageScroll from './LPFullPageScroll'
import LPHomeHero from './../LPHome/LPHomeHero/LPHomeHero'
import LPNSecFourMOB from './LPNSecFourMOB'
import LPFullPageScrollMob from './LPFullPageScrollMob';

const LandingNew = () => {
  return (
    <div>
      <Helmet>
        <title>Lilypads | Home</title>
      </Helmet>

      {/* <LPNSecOne /> */}
      <LPHomeHero />
      <LPNSecTwo />
      <LPNSecThree />

      <div className="d-none d-md-block">
        <LPNSecFour />
      </div>
      <div className="d-block d-md-none">
        <LPNSecFourMOB />
      </div>

      <LPNSecFive />
      
      <div className="d-none d-md-block">
        <LPFullPageScroll />
      </div>
      <div className="d-block d-md-none">
        <LPFullPageScrollMob />
      </div>

      <LPNSecSix />
      <LPContactUsNEW />
    </div>
  )
}

export default LandingNew

import { Col, Container, Image, Row } from "react-bootstrap";
import LPSVGIcons from "../../../../Components/LPSVGIcons/LPSVGIcons";

export default function LPRInvestmentPlatform() {
    return (
        <>
            <section className="investment-platform retail-investor space-top space-bottom">
                <Container>
                    <Row className="" >
                        <Col lg="6">
                            <Row className="space-bottom" >
                                <Col lg="2">
                                </Col>
                                <Col>
                                    <h2 className="">Lilypads investment platform</h2>
                                    <p className='font-lexend mb-5 fw-400'>Everyday new investment-grade properties are added to the marketplace, investors join the network and resources are shared throughout the platform. Lilypads helps you turn an opportunity into transaction</p>
                                    <div className="social-list mt-0 align-items-center">
                                        <a href="#" rel="noopener noreferrer">
                                            {LPSVGIcons.Apple}
                                        </a>
                                        <a href="#" rel="noopener noreferrer">
                                            {LPSVGIcons.GooglePlay}
                                        </a>
                                        <span className="color-purple opacity-50 fw-600 font-lexend" >Coming soon</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/invest-plateform.png" className="investment-platform-main-img img-reset-mob"/>
            </section>
        </>
    )
}
import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import featuresimg from '../../../assets/images/Discover/Group 48096540.png'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import VisualizeYourNetwork from '../../../assets/images/Discover/154-handle 1.png'
import CrowdFundingOpportunity from '../../../assets/images/Discover/056-growth 1.png'
import ImproveDueDiligence from '../../../assets/images/Discover/007-business and finance 1.png'


export default function LPDiscoverSection3(){
    return(
        <div className="LPDiscoverSection3  .Discoverresponsiveness">
          <div className='LPDiscoverSection3background'>
          <Container>
            <Row className="justify-content-center Section3space">
             
              <Col lg={12}>
              <div className='discoverfeatureresponsiveness'>
                  <Col lg={6}>
                  <Col lg={12}>
                <Row className="align-items-center">
                  <Col lg={6}>
                    <p className="subtitle">
                    Our features
                    </p>
                    <h1 className="small">Feature</h1>
                  </Col>
                  <Col lg={6}>
                    {/* <p className="font-lexend">
                      The Lilypads real estate investor network allows you to
                      expand your book of contacts to access opportunities and
                      grow your business
                    </p> */}
                  </Col>
                </Row>
              </Col>
                       <div className='discoverfeatureresponsivenessrow1'>
                         <div>
                         <Col lg={11}>
                             <img className='featuresicons' src={VisualizeYourNetwork}/>
                             <Discoverperpectivecard
                             CardHeading='Visualize Your Network'
                             Cardparagraph='Our platform analyses billions of data points to surface clear pathways to people and companies that matter most to you. Auto -enhance profile industry data and schedule smart triggers and reminders'
                             />
                              </Col>
                         </div>
                         <div>
                         <Col lg={11}>
                         <img className='featuresicons' src={CrowdFundingOpportunity}/>
                         <Discoverperpectivecard
                             CardHeading='Crowd Funding Opportunity'
                             Cardparagraph='The modern way to discover real estate offerings from institutional investment firms. Raise capital from LPs, execute on deals, and manage a portfolio – all from a single platform'
                             />
                              </Col>
                         </div>
                       </div>
                       <div >
                        <div>
                            <Col lg={6}>
                            <img className='featuresicons' src={ImproveDueDiligence}/>
                             <Discoverperpectivecard
                             CardHeading='Improve Due Diligence'
                             Cardparagraph='Tap into your network to backfill your next big deal. Easily manage deal flow and improve diligence'
                             />
                             </Col>
                        </div>
                       </div>
                   </Col>
                   <Col lg={6}>
                   <div>
                       <img className='featureimg' src={featuresimg}/>
                   </div>
                   </Col>
              </div>
              </Col>
            </Row>
          </Container>
          </div>
        </div>
    );
}
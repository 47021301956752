import React from "react";
import { useEffect, useState } from "react";
import "./KnowledgeCenter.scss";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import { useNavigate } from "react-router-dom";
const KnowledgeCenterMob = () => {
  const [searchT, setSearchT] = useState("");
  const [mainData, setMainData] = useState("");
  const [loading, setLoading] = useState(false);
  const [mainMenu, setMainMenu] = useState({});
  const onlyWidth = useWindowWidth();
  const [navigationData, setNavigationData] = useState({
    mainIndex: 0,
    subIndex: 0,
    menu: "",
  });
  const [rightMenu, setRightMenu] = useState([]);
  const [menuSelector, setMenuSelector] = useState(0);
  const [accordianData, setAccordianData] = useState([]);
  const [submenuHead, setSubmenuHead] = useState("");
  const [rightmenuIndex, setRightmenuIndex] = useState(0);
  const [vModeMOB, setVModeMOB] = useState(false);
  const [dMode, setDMode] = useState(1);

  const [mainM, setMainM] = useState("");

  useEffect(() => {
    // const sessionStorageData = sessionStorage.getItem("knowledgeData");
    const sessionStorageDataACC = sessionStorage.getItem("accordianData");
    const submenuHeadSS = sessionStorage.getItem("submenuHead");
    const subItemSS = sessionStorage.getItem("subItem");
    setMainM(subItemSS);
    setSubmenuHead(submenuHeadSS);
    setAccordianData(JSON.parse(sessionStorageDataACC));
    // setMainData(JSON.parse(sessionStorageData));
  }, []);

  // window.onbeforeunload = function () { return "Your work will be lost."; };
  useEffect(() => {
    //add class on page load and remove on page change

    document.querySelector("body").classList.remove("header-secondary");
    document.querySelector("body").classList.add("header-none");

    return () => {
      document.querySelector("body").classList.remove("header-none");
    };
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (onlyWidth > 768) {
      document.querySelector("body").classList.remove("header-secondary");
      document.querySelector("body").classList.add("header-none");
      setVModeMOB(false);
      // alert("Please use mobile view for this page")
      navigate("/knowledgeCenter");
    } else {
      setVModeMOB(true);
      document.querySelector("body").classList.remove("header-none");
      // document.querySelector("body").classList.add("header-secondary");
    }
  }, [onlyWidth]);

  useEffect(() => {
    if (searchT === "") {
      return;
    } else {
      navigate("/knowledgeCenter");
    }
  }, [searchT]);

  return (
    <div className="KnwoledgeCenterMain sectionTwoCL">
      {loading ? <Loader /> : <></>}
      <div className="mobTopName">
        <div className="mobTopName-i-l">
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.5" width="18" height="3" fill="#9E2349" />
          </svg>

          <p> {mainM}</p>
        </div>
        <div className="mobTopName-i">
          <svg
            onClick={() => {
              navigate("/knowledgeCenter");
            }}
            width="6"
            height="11"
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.14244 5.28564L4.71387 0.999931"
              stroke="#9E2349"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.14244 5.28557L4.71387 9.57129"
              stroke="#9E2349"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="KC-content mobN">
        <div className="KC-content-M">
          <div className="kc-m-con-title">
            {submenuHead === "" ? (
              <></>
            ) : (
              <>
                {" "}
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.9987 19.0339C24.8596 23.1211 20.3229 26.0404 15.0008 26.9051C9.52877 27.7943 4.22742 26.3488 0.0078125 23.2753C3.14691 19.1881 7.68482 16.2688 13.0057 15.4041C18.4765 14.5162 23.7791 15.9604 27.9987 19.0339Z"
                    fill="#7C5AC7"
                  />
                  <path
                    d="M27.9987 19.0339C24.8596 23.1211 20.3229 26.0404 15.0008 26.9051C9.52877 27.7943 4.22742 26.3488 0.0078125 23.2753C3.14691 19.1881 7.68482 16.2688 13.0057 15.4041C18.4765 14.5162 23.7791 15.9604 27.9987 19.0339Z"
                    stroke="white"
                  />
                  <g opacity="0.8">
                    <path
                      d="M18.6122 0.835937C18.7609 6.09241 17.1255 11.4208 13.6242 15.7316C10.0242 20.1632 5.10942 22.7086 0.00929944 23.2765C-0.139484 18.02 1.49592 12.6916 4.99722 8.3808C8.59608 3.94922 13.5108 1.40257 18.6122 0.835937Z"
                      fill="#9E2349"
                    />
                    <path
                      d="M18.6122 0.835937C18.7609 6.09241 17.1255 11.4208 13.6242 15.7316C10.0242 20.1632 5.10942 22.7086 0.00929944 23.2765C-0.139484 18.02 1.49592 12.6916 4.99722 8.3808C8.59608 3.94922 13.5108 1.40257 18.6122 0.835937Z"
                      stroke="white"
                    />
                  </g>
                </svg>
              </>
            )}

            {submenuHead}
          </div>
          <div className="itopMargin"></div>
          <Accordion defaultActiveKey="0" className="accordionMain   p-5">
            {accordianData.length > 0 ? (
              <>
                {accordianData.map((item, i) => {
                  return (
                    <Accordion.Item
                      key={i}
                      eventKey={`${i}`}
                      className="bg-transparent border-0"
                    >
                      <Accordion.Header className="bg-transparent">
                        {item.title}
                      </Accordion.Header>
                      <Accordion.Body className="">
                        <div dangerouslySetInnerHTML={{ __html: item.body }} />
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}{" "}
              </>
            ) : (
              <></>
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeCenterMob;

import React from 'react'
import im from "./../assets/images/notFound.png"
const NoPage = () => {
    return (
        <div className='nopCON'>
            <img src={im} alt="" />
        </div>
    )
}

export default NoPage

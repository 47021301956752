import { Container, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

import featuresimg from '../../../assets/images/Discover/Group 48096540.png'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import InvestmentManagement from '../../../assets/images/Manage/Group 48096545.png'
import StreamlineYourDaytoDay from '../../../assets/images/Manage/adsfghjk.png'
import StreamlineWaterfallDistributions from '../../../assets/images/Manage/Group 48096548.png'
import ExchangeDocumentssecurely from '../../../assets/images/Manage/Group 48096547.png'
import lineManage from '../../../assets/images/Manage/Line 17.png'

export default function LPManageSection2(){
    return(
      <>
      <section className="space-bottom">
        <Container >
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle">
                  What we do
                  </p>
                  <h1 className="small">Investment management</h1>
                </Col>
                <Col lg={6}>
                  {/* <p className="font-lexend">
                    The Lilypads real estate investor network allows you to
                    expand your book of contacts to access opportunities and
                    grow your business
                  </p> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </section>

        <Col lg={12}>
                       <div className='Investmentmanagementfullcard'>
                         <div className='Investmentmanagementcard'>
                        
                             <img className='featuresicons' src={InvestmentManagement}/>
                             <Discoverperpectivecard
                             CardHeading='Investment Management'/>
                             <img src={lineManage}/>
                            <Discoverperpectivecard
                              Cardparagraph='Investors can access a summary of their investment positions, and their invested and distributed capital 24/7 in real-time'
                             />
                             
                         </div>
                         <div className='Investmentmanagementcard'>
                        
                             <img className='featuresicons' src={StreamlineYourDaytoDay}/>
                             <Discoverperpectivecard
                             CardHeading='Streamline Your Day-to-Day'/>
                              <img src={lineManage}/>
                              <Discoverperpectivecard
                             Cardparagraph='Communicate with prospective investors and track their degree of interest. Manage investor commitments & get notified'
                             />
                             
                         </div>
                         <div className='Investmentmanagementcard'>
                       
                         <img className='featuresicons' src={StreamlineWaterfallDistributions}/>
                         <Discoverperpectivecard
                             CardHeading='Streamline Waterfall Distributions'/>
                             <img src={lineManage}/>
                              <Discoverperpectivecard
                             Cardparagraph='Calculate distributions faster with an intuitive waterfall distributions tool that reduces the risk of error and makes auditing easier'
                             />
                          
                         </div>
                         <div className='Investmentmanagementcard'>
                            
                            <img className='featuresicons' src={ExchangeDocumentssecurely}/>
                             <Discoverperpectivecard
                             CardHeading='Exchange Documents securely'/>
                              <img src={lineManage}/>
                              <Discoverperpectivecard
                             Cardparagraph='Email investors personally, or in custom groupings, or en masse. All communications are tracked automatically for easy reference'
                             />
                            
                        </div>
                       </div>
          </Col>    
                  
      </>
    );
}
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import blackone from '../../../assets/images/Manage/01 (14).png'
import blacktwo from '../../../assets/images/Manage/02 (14).png'
import blackthree from '../../../assets/images/Manage/03 (12).png'
import blackfour from '../../../assets/images/Manage/04 (4).png'

export default function LPManageSection4(){
    return(
        
        <div className="LPmanageSection4 .Discoverresponsiveness">
        <Col lg={12}>
             
                </Col>
           <Col lg={12}>
             <Container className='container '>
                  <div className=' Discoverresponsiveness'>
                  <h1 className='StreamlineDaytoDay'>Streamline Day-to-Day<br/> Investing</h1>
                    <div className='professionalexperience' >
                        <Col lg={6}>
                       <div className='Guaranteesecurity'>
                       <img className='' src={blackone}/>
                        <div className='perpectivecardindividual'> 
                        <Discoverperpectivecard
                        CardHeading='Provide a professional experience to investors'
                        Cardparagraph='Our investors are invested in profitable funds and syndicates, and the experience  of investing with us has been better than any institutional investment'
                         />
                      </div>
                       </div>
                       </Col>
                       <Col lg={6}>
                       <div>
                         <img className='' src={blacktwo}/>
                         <div className='perpectivecardindividual'> 
                         <Discoverperpectivecard
                         CardHeading='Easily communicate with investors'
                         Cardparagraph='Investors can visit the Lilypads Investor Portal to easily find reports, tax forms, and other shared documents and securely sign agreements'
                         />
                         </div>
                        </div>
                        </Col>
                    </div>
                    
                    <div className='professionalexperience importantdocuments'>
                    <Col lg={6}>
                        <div>
                          <img className='' src={blackthree}/>
                          <div className='perpectivecardindividual'> 
                          <Discoverperpectivecard
                          CardHeading='Give investors on-demand access to their investment info'
                          Cardparagraph='Investors can view detailed investment performance metrics, including total distributions, and check project updates from anywhere—including property descriptions, photos, locations, valuations and loan balances'
                           />
                           </div>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div>
                          <img className='' src={blackfour}/>
                          <div className='perpectivecardindividual'> 
                          <Discoverperpectivecard
                          CardHeading='Guarantee security of important documents'
                          Cardparagraph='Email investors individually, by custom groupings, or all at once. All communication is automatically tracked for easy reference'
                          />
                          </div> 
                        </div>
                        </Col>
                    </div>
                   
                  </div>
              </Container>
            </Col>
       
 
        </div>
    );
}

{/* <













*/}
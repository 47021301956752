import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import legalstructuring from '../../../assets/images/invest/Group 48096541.png'
import investorsonboarding from '../../../assets/images/invest/Group 48096542.png'
import swissknife from '../../../assets/images/invest/Group 48096543.png'
import Complianceandsecurity from '../../../assets/images/invest/Group 48096544.png'
import Qualityaccounting from '../../../assets/images/invest/Group 48096546.png'



export default function LPInvestSection2(){
    return(
       <>
        <Col lg={12}>
        <Container className='container '>
         <div className='WhatWeEmphasize Discoverresponsiveness DataOrientedmain'>
             <div className='DataOrientedmain'>
              <h1 className='DataOriented'>Data Oriented</h1>
              <p className='DataOrientedpara'>Everything is sorted in a beautiful, integrated platform</p>
             
             </div>
             <div className='WhatWeEmphasizecard'>
             
               <img className='Dataorientcard' src={legalstructuring}/>
               <Discoverperpectivecard
                         CardHeading='Real-time legal structuring'
                         Cardparagraph='Update your fund documents in real-time and settle your negotiations in minutes'
                         />
             </div>
             <div className='WhatWeEmphasizecard'>
              
               <img className='Dataorientcard' src={investorsonboarding}/>
               <Discoverperpectivecard
                         CardHeading='Blazing fast investor onboarding'
                         Cardparagraph='Collect your investor subscriptions instantly with a fully-digital investor onboarding process'
                         />
             </div>
         </div>
         </Container>
         </Col>
         <Col lg={12}>
        <Container className='container '>
         <div className='WhatWeEmphasize Discoverresponsiveness'>
             <div className='WhatWeEmphasizecard'>
              
                <img className='Dataorientcard' src={swissknife}/>
                <Discoverperpectivecard
                         CardHeading='Legal and governance'
                    Cardparagraph='Implement policies, get FATCA/CRS reporting and update your committee'
                         />
             </div>
             <div className='WhatWeEmphasizecard'>
             
               <img className='Dataorientcard' src={Complianceandsecurity}/>
               <Discoverperpectivecard
                         CardHeading='Compliance and security'
                         Cardparagraph='Invite all your stakeholders to a safe, permissioned online dashboard'
                         />
             </div>
             <div className='WhatWeEmphasizecard'>
              
               <img className='Dataorientcard' src={Qualityaccounting}/>
               <Discoverperpectivecard
                         CardHeading='Quality accounting and reporting'
                         Cardparagraph='Administration does not have to be tiring. So we make it a joy to work with us'
                         />
             </div>
         </div>
         </Container>
         </Col>
       </>
    );
}
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import './LPWaitingList.css'

export default function LPWaitingList() {
  return (
    <section className="waiting-list space-top space-bottom">
      <Container>
        <Row>
          <Col lg="6">
            <Row>
              <Col lg="2"></Col>
              <Col>
                <h2 className="big">
                  Join the Waitlist now for early benefits
                </h2>
                <p className="font-lexend mb-5 pb-2">
                Join the Waitlist to unveil early access to productive investment opportunities in private placements and top-notch commercial real estate deals across the US
                </p>
                <div className="text-center text-sm-start">
                  <a href="/join-waiting-list">
                    <Button variant="none" className="btn-purple btn-shadow">
                      Join the Waitlist 
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Image
        fluid
        src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/waiting-list.png"
        className="waiting-list-img img-reset-mob mt-5 mt-lg-0 d-none d-lg-block"
      />
    </section>
  )
}

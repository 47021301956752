import LPWaitingList from "../../Components/LPWaitingList/LPWaitingList";
import LPLendersFDT from "./LPLendersFDT/LPLendersFDT";
import LPLendersHero from "./LPLendersHero/LPLendersHero";
import LPLendersLiquidAssest from "./LPLendersLiquidAssest/LPLendersLiquidAssest";
import LPLendersPayments from "./LPLendersPayments/LPLendersPayments";
import LPLendersWayToManage from "./LPLendersWayToManage/LPLendersWayToManage";
import './LPLenders.css';
import Helmet from "react-helmet";

export default function LPLenders() {
    return (
        <>
            <Helmet>
                <title>Lilypads | Lenders</title>
            </Helmet>
            <LPLendersHero />
            <LPLendersWayToManage />
            <LPLendersLiquidAssest />
            <LPLendersPayments />
            <LPLendersFDT />
            <LPWaitingList />
        </>
    )
}
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AnimatedCursor from 'react-animated-cursor'
import './App.css'
import React, { useEffect, useState } from 'react'
import LPFooter from './Components/LPFooter/LPFooter'
import LPHeader from './Components/LPHeader/LPHeader'
import LPBrokers from './Containers/LPBrokers'
import LPContactUS from './Containers/LPContactUs/LPContactUs'
import LPHome from './Containers/LPHome'
import LPInstitutionalInvestors from './Containers/LPInvestors/LPInstitutionalInvestors'
import LPRetailsInvestors from './Containers/LPInvestors/LPRetailInvestors'
import LPLenders from './Containers/LPLenders'
import LPNetwork from './Containers/LPNetwork'
import LPStory from './Containers/LPStory'
import LPBolg from './Containers/LPBlog/LPBolg'
import LPBlogInd from './Containers/LPBlog/LPBlogInd'
import LPSchedule from './Containers/LHSchedule/LPSchedule'
import LPJoinWaitingList from './Containers/LPJoinWaitingList'
import { saveToLocal } from './Components/AllFunctions'
import LPDiscover from './Containers/LPDiscover'
import LPInvest from './Containers/LPInvest'
import LPManage from './Containers/LPManage'
import NoPage from './Components/NoPage'
import LPTechAI from './Containers/LPTechAL/LPTechAL'
import LPBlockchain from './Containers/LPBlockchain/LPBlockchain'
import ScrollToTop from './Components/ScrollToTop'
// import SecOneApplication from './Containers/LPCareers/SecOneApplication'
import LPCareers from './Containers/LPCareers/index'
import LPCareerApplication from './Containers/LPCareers/LPcareerApplication'
import LPcareerDetail from './Containers/LPCareers/LPcareerDetail'
import KnwoledgeCenterMain from './Containers/LPKnowledgeCenter/KnwoledgeCenterMain'
import KnowledgeCenterMob from './Containers/LPKnowledgeCenter/KnowledgeCenterMob'
import LPRetailInvestor from './Containers/LPRetailInvestor/LPRetailInvestor'
import LPExploreOffering from './Containers/LPExploreOffering/LPExploreOffering'
import LandingNew from './Containers/LPLPNew/LandingNew'
import LPScheduleThomas from './Containers/LHSchedule/LPScheduleThomas'

export default function App() {
  useEffect(() => {
    // saveToLocal();
  }, [])

  return (
    <>
      <Router>
        <ScrollToTop>
          <AnimatedCursor
            innerSize={8}
            outerSize={35}
            color="158, 35, 73"
            outerAlpha={0.4}
            innerScale={1}
            trailingSpeed={4}
            outerScale={2}
            clickables={[
              'a',
              'input[type="text"]',
              'input[type="email"]',
              'input[type="number"]',
              'input[type="submit"]',
              'input[type="image"]',
              'label[for]',
              'select',
              'textarea',
              'button',
              '.link',
            ]}
          />
          <LPHeader />
          <div className="app-main font-lexend">
            <Routes>
              <Route path="/" element={<LandingNew />} />
              <Route path="/home" element={<LandingNew />} />
              {/* <Route path="/home" element={<LPHome />} /> */}
              <Route
                path="/retails-investors"
                element={<LPRetailsInvestors />}
              />
              <Route
                path="/institutional-investors"
                element={<LPInstitutionalInvestors />}
              />
              <Route path="/lenders" element={<LPLenders />} />
              <Route path="/brokers" element={<LPBrokers />} />
              <Route path="/network" element={<LPNetwork />} />
              <Route path="/story" element={<LPStory />} />
              <Route path="/contact" element={<LPContactUS />} />
              <Route path="/schedule" element={<LPSchedule />} />
              <Route path="/schedule-Thomas" element={<LPScheduleThomas />} />
              <Route path="/blog" element={<LPBolg />} />
              <Route path="/:category/:name/" element={<LPBlogInd />} />
              <Route
                path="/join-waiting-list"
                element={<LPJoinWaitingList />}
              />
              <Route path="*" element={<NoPage />} />
              <Route path="/Discover" element={<LPDiscover />} />
              <Route path="/Invest" element={<LPInvest />} />
              <Route path="/Manage" element={<LPManage />} />
              <Route path="/ai" element={<LPTechAI />} />
              <Route path="/blockchain" element={<LPBlockchain />} />
              <Route path="/career" element={<LPCareers />} />
              <Route path="/explore" element={<LPExploreOffering />} />

              <Route
                path="/knowledgeCenter"
                element={<KnwoledgeCenterMain />}
              />
              <Route
                path="/knowledgeCenterM"
                element={<KnowledgeCenterMob />}
              />
              <Route path="/howitworks" element={<LPRetailInvestor />} />
              <Route path="/career/detail/:id" element={<LPcareerDetail />} />
              <Route
                path="/career/application/:id"
                element={<LPCareerApplication />}
              />
            </Routes>
          </div>
          <LPFooter />
        </ScrollToTop>
      </Router>
    </>
  )
}

import { Col, Container, Row } from "react-bootstrap";
import LPSVGIcons from "../../../../Components/LPSVGIcons/LPSVGIcons";
export default function LPIInvestorDeatMaking() {
  const DeatMakingList = [
    {
      icon: LPSVGIcons.Timer,
      title: "Real-time legal structuring",
      text: "Tailor your vehicle with clicks By being able to update your fund documents in real-Time, you make investors negotiations last hours instead of months",
    },
    {
      icon: LPSVGIcons.LineChart,
      title: "Blazing fast investor' onboarding",
      text: "Collect your investors subscriptions instantly thanks to a streamlined, fully-digital onboarding and subscription process",
    },
    {
      icon: LPSVGIcons.Task,
      title: "Legal and governance swiss knife",
      text: "Implement required policies, pass various resolutions, get FATCA/CRS reporting and update your committee easily",
    },
    {
      icon: LPSVGIcons.Shield,
      title: "Compliance and security",
      text: "Invite all your stakeholders to a safe, permissioned online dashboard to execute your deals in a secure environment",
    },
    {
      icon: LPSVGIcons.FileFind,
      title: "Quality accounting and reporting",
      text: "Administration doesn't have to be complex, we make it a joy to work with",
    },
  ];
  return (
    <>
      <section className="space-bottom deal-making">
        <Container>
          <Row className="g-5">
            <Col lg={4} md={6}>
              <h2 className="mb-0">Focus on dealmaking</h2>
              <p className="font-lexend fw-400">
                Everything is sorted in a beautiful, integrated platform
              </p>
            </Col>
            {DeatMakingList.map((item, i) => {
              return (
                <Col key={i} lg={4} md={6} >
                  <div className="p-5 custom-card h-100">
                    <div className="circle-icon w-80p mb-4">{item.icon}</div>
                    <p className="fw-600 big font-lexend">{item.title}</p>
                    <p className="font-lexend fw-400 mb-0 small">{item.text}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}

import { Button, Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPJoinWaitingLisJoin() {
  return (
    <>
      <div className="space-top space-bottom join-waiting-list">
        <Container>
          <Row className="text-center justify-content-center space-small-bottom">
            <Col lg={10}>
              <h2>Lilypads essential workspace modules</h2>
              <p className="color-gray">
                Whether you are a Retail or an Institutional Investor, a Broker,
                or a Lender, join Lilypads to expand and streamline your CRE
                operations in one platform
              </p>
            </Col>
          </Row>
        </Container>
        <div className="home-tabs join-waitlist-tabs position-relative">
          <Tab.Container defaultActiveKey="first">
            <Container>
              <Row>
                <Col lg={5} xl={4}>
                  <Nav className="mb-0 flex-nowrap flex-lg-wrap mt-0">
                    <Nav.Item className="w-100 mb-5 px-0 d-flex d-lg-block me-5 me-md-0">
                      <Nav.Link as={'div'} eventKey="first" className="px-0">
                        <h5> Retail investors</h5>
                        <Link
                          to="/retails-investors"
                          className="circle-icon w-60p d-lg-flex d-none"
                        >
                          {LPSVGIcons.LongArrow}
                        </Link>
                      </Nav.Link>
                      <div className="list pt-4">
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Utilise the Lilypads platform to discover and
                            research investment opportunities quicker
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Track and manage your investment portfolio across
                            multiple projects and funds
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            The Lilypads Deal Management Dashboard can
                            facilitate accurate reporting
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Lilypads helps you create a customised AI
                            recommended portfolio using data-backed approach
                          </p>
                        </div>
                      </div>
                    </Nav.Item>
                    <Nav.Item className="w-100 mb-5 px-0 d-flex d-lg-block me-5 me-md-0">
                      <Nav.Link as={'div'} eventKey="second" className="px-0">
                        <h5>Institutional investor</h5>
                        <Link
                          to="/retails-investors"
                          className="circle-icon w-60p d-lg-flex d-none"
                        >
                          {LPSVGIcons.LongArrow}
                        </Link>
                      </Nav.Link>
                      <div className="list pt-4">
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Fundraise through private placement using the
                            Lilypads network
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Lilypads provides you investment advisory services
                            and due diligence
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Unlock property intelligence backed by unmatched AI
                            technology
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Lilypads streamlines investor communications, and LP
                            management and reporting
                          </p>
                        </div>
                      </div>
                    </Nav.Item>
                    {/* <Nav.Item className="w-100 mb-5 px-0 d-flex d-lg-block me-5 me-md-0">
                      <Nav.Link as={'div'} eventKey="third" className="px-0">
                        <h5>For Lenders</h5>
                        <Link
                          to="/lenders"
                          className="circle-icon w-60p d-lg-flex d-none"
                        >
                          {LPSVGIcons.LongArrow}
                        </Link>
                      </Nav.Link>
                      <div className="list pt-4">
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Monitor portfolio risks in real-time using our
                            leading builtin ledger systems
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Lilypads provides a seamless lien waiver management
                            and compliance tracking tool
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Digitise disbursement and regulate payment approval
                            using Lilypads DigiTrust payment module
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Automate your policies and procedures, contracts and
                            closing with Lilypads
                          </p>
                        </div>
                      </div>
                    </Nav.Item> */}
                    <Nav.Item className="w-100 mb-5 px-0 d-flex d-lg-block me-5 me-md-0">
                      <Nav.Link as={'div'} eventKey="fourth" className="px-0">
                        <h5>For Brokers</h5>
                        <Link
                          to="/brokers"
                          className="circle-icon w-60p d-lg-flex d-none"
                        >
                          {LPSVGIcons.LongArrow}
                        </Link>
                      </Nav.Link>
                      <div className="list pt-4">
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Post listings in your MLS and organize all your
                            offerings in one page through Lilypads
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Store unlimited documents securely by leveraging our
                            blockchain technology
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Create checklists, reminders, and alerts
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="me-3 d-inline-block">
                            {LPSVGIcons.DoubleCheck}
                          </span>
                          <p className="font-lexend mb-4 fw-400">
                            Lilypads provides customized solutions for your
                            brokerage and full API integration with your
                            internal process.
                          </p>
                        </div>
                      </div>
                    </Nav.Item>
                  </Nav>
                  {/* <Button
                    variant="none"
                    className="btn-bordered-purple w-100 my-5 my-lg-0"
                  >
                    Join the waitlist {LPSVGIcons.ShortArrow}
                  </Button> */}
                </Col>
              </Row>
            </Container>
            <div className="join-waiting-list-tab-content">
              <Tab.Content>
                <Tab.Pane eventKey="second">
                  <div className="position-relative investor-imgs">
                    <Link
                      to="/institutional-investors"
                      className="circle-icon w-70p d-md-none d-flex"
                    >
                      {LPSVGIcons.LongArrow}
                    </Link>
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-invitations1.png"
                      className="investor-img-left drop-shadow-light"
                    />
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-invitations2.png"
                      className="drop-shadow-light scale-img scale-transition"
                    />
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-invitations3.png"
                      className="investor-img-right scale-img scale-transition drop-shadow-light"
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div className="position-relative lenders-imgs">
                    <Link
                      to="/lenders"
                      className="circle-icon w-70p d-md-none d-flex"
                    >
                      {LPSVGIcons.LongArrow}
                    </Link>
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-lenders2.png"
                      className="lenders-img-left drop-shadow-light"
                    />
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-lenders3.png"
                      className="drop-shadow-light scale-img scale-transition"
                    />
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-lenders1.png"
                      className="lenders-img-right scale-img scale-transition drop-shadow-light"
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <div className="position-relative brokers-imgs">
                    <Link
                      to="/brokers"
                      className="circle-icon w-70p d-md-none d-flex"
                    >
                      {LPSVGIcons.LongArrow}
                    </Link>
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-brokers1.png"
                      className="brokers-img-left drop-shadow-light scale-img scale-transition"
                    />
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-brokers3.png"
                      className="drop-shadow-light scale-img scale-transition"
                    />
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/home-brokers2.png"
                      className="brokers-img-right scale-img scale-transition drop-shadow-light"
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="first">
                  <div className="retails-investors-images">
                    <Link
                      to="/retails-investors"
                      className="circle-icon w-70p d-md-none d-flex"
                    >
                      {LPSVGIcons.LongArrow}
                    </Link>
                    <Image
                      fluid
                      src='https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero1.png'
                      className="retails-investors-hero-left scale-transition drop-shadow-light"
                    />
                    <Image
                      fluid
                      src='https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero2.png'
                      className="retails-investors-hero-main scale-transition scale-img  drop-shadow-light w-100"
                    />
                    <Image
                      fluid
                      src='https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero3.png'
                      className="retails-investors-hero-right scale-transition scale-img  drop-shadow-light"
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}

import { Col, Container, Image, Row } from 'react-bootstrap'

export default function LPLendersPayments() {
  const LienWaiverList = [
    {
      title: 'Sync with current software to maximize efficiency',
      text:
        'Leveraging blockchain-based dApps to automate payments, asset management, and data storage processes, while reducing human errors and increasing productivity. ',
    },
    {
      title: 'Make investments easier with Smart Contracts ',
      text:
        'Indulge in asset digitization and tokenized ownership while automating transactions, increasing transparency and security with Lilypads platform. ',
    },
    {
      title: 'Gain Teamwide Visibility',
      text:
        'Data stored on Lilypads blockchain-based dashboard results in enhanced transparency and traceability with easy accessibility across the entire team. ',
    },
  ]
  const ComplianceTrackingList = [
    {
      title: 'Organized Dashboard',
      text:
        'Get a complete overview of the performance of your trading account with our customized dashboard providing real-time market updates, charting tools, news updates, and more.',
    },
    {
      title: 'Predictive Analysis',
      text:
        'Utilizing advanced technology such as AI/ML to identify investments that have a greater probability of generating profit.',
    },
    {
      title: 'Trade without intermediaries ',
      text:
        'eliminating the need for intermediaries by providing investors with detailed information and premium research into commercial properties.',
    },
  ]
  const BuiltPayList = [
    {
      title: 'Digitize Disbursement',
      text:
        'Our blockchain-based platform provides timely payouts of trust tokens used in purchasing shares.',
    },
    {
      title: 'Control Payment Approval',
      text:
        'Keeping an updated track of real-time payments received in your Digitrust wallet eliminates and manages risks.',
    },
    {
      title: 'Power Payee Preference',
      text:
        'Lilypads allows the direct purchase of shares by swapping crypto tokens for trust tokens by connecting external wallets seamlessly with lilypads. ',
    },
  ]
  return (
    <>
      <section className="space-small-top space-small-bottom vendor-payments">
        <Container>
          <h2 className="text-center mb-5">
            Streamlined and Simplified Vendor Payments
          </h2>
          <div className="space-bottom text-center">
            <Image
              className="maroon-shadow"
              fluid
              src="https://lilypads.s3.us-east-2.amazonaws.com/images/lenders/vendor-payments.png"
            />
          </div>
          <Row className="g-5">
            <Col lg={4}>
              <h4 className="font-dm-serif color-purple mb-4">Web3</h4>
              {LienWaiverList.map((item, i) => {
                return (
                  <>
                    <div key={i} className=" mb-5">
                      <p className="font-lexend fw-700 mb-2">{item.title}</p>
                      <p className="font-lexend fw-400 small mb-0">
                        {item.text}
                      </p>
                    </div>
                  </>
                )
              })}
            </Col>
            <Col lg={4}>
              <h4 className="font-dm-serif color-purple mb-4">Securities</h4>
              {ComplianceTrackingList.map((item, i) => {
                return (
                  <>
                    <div key={i} className=" mb-5">
                      <p className="font-lexend fw-700 mb-2">{item.title}</p>
                      <p className="font-lexend fw-400 small mb-0">
                        {item.text}
                      </p>
                    </div>
                  </>
                )
              })}
            </Col>
            <Col lg={4}>
              <h4 className="font-dm-serif color-purple mb-4">DigiTrust</h4>
              {BuiltPayList.map((item, i) => {
                return (
                  <>
                    <div key={i} className=" mb-5">
                      <p className="font-lexend fw-700 mb-2">{item.title}</p>
                      <p className="font-lexend fw-400 small mb-0">
                        {item.text}
                      </p>
                    </div>
                  </>
                )
              })}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

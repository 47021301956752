import { Container, Row, Col, Image } from 'react-bootstrap'

const LPRInvestorList = [
  {
    icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/analysis.svg',
    title: 'Portfolio Management ',
    text:
      'Retail investors and sponsors can easily track and manage their portfolio of investments across multiple projects and funds',
  },
  {
    icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/reporting.svg',
    title: 'Accurate Reporting ',
    text:
      'Sponsors can be proactive, responsive and transparent beyond their reporting obligations through the Lilypads Deal Management dashboard',
  },
  {
    icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/workflow.svg',
    title: 'Seamless Workflow ',
    text:
      'Retail investors can view and manage their investment positions, invested capital, investor activity and easily upload critical data',
  },
]

export default function LPRInvestorHero() {
  return (
    <>
      <section className="inner-hero space-bottom retails-investors-hero">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <h1 className="small">
                    Lilypads for{' '}
                    <span className="color-maroon">Retail Investors</span>
                  </h1>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend">
                    Retail investors can utilize the Lilypads platform to
                    connect, invest and discover opportunities from
                    institutional-grade commercial properties
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xl={9} className="text-center mt-5">
              <div className="retails-investors-images">
                <Image
                  fluid
                  src="https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero1.png"
                  className="retails-investors-hero-left scale-transition drop-shadow-light"
                />
                <Image
                  fluid
                  src="https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero2.png"
                  className="retails-investors-hero-main scale-transition scale-img  drop-shadow-light w-100"
                />
                <Image
                  fluid
                  src="https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero3.png"
                  className="retails-investors-hero-right scale-transition scale-img  drop-shadow-light"
                />
              </div>
            </Col>
          </Row>
          <Row className="g-5 space-small-top">
            {LPRInvestorList.map((item, i) => {
              return (
                <>
                  <Col lg={4} md={6} key={i}>
                    <div className="gredient-bg p-5 h-100">
                      <div className="circle-icon w-80p mb-4">
                        <Image src={item.icon} />
                      </div>
                      <p className="fw-600 big font-lexend">{item.title}</p>
                      <p className="font-lexend fw-400 mb-0 small">
                        {item.text}
                      </p>
                    </div>
                  </Col>
                </>
              )
            })}
          </Row>
        </Container>
      </section>
    </>
  )
}

import LPRInvestorHero from "./LPRInvestorHero/LPRInvestorHero";
import LPWaitingList from "../../../Components/LPWaitingList/LPWaitingList";
import LPRetailCustomPortfolio from "./LPRetailCustomPortfolio/LPRetailCustomPortfolio";
import './LPRetailsInvestors.css';
import LPRInvestmentJoinCommunity from "./LPRInvestmentJoinCommunity/LPRInvestmentJoinCommunity";
import LPRInvestmentHow from "./LPRInvestmentHow/LPRInvestmentHow";
import LPRInvestmentPlatform from "./LPRInvestmentPlatform/LPRInvestmentPlatform";
import LPRInvestorBecome from "./LPRInvestorBecome/LPRInvestorBecome";
import Helmet from "react-helmet";
import LPLendersPayments from './../../LPLenders/LPLendersPayments/LPLendersPayments';
export default function LPRetailsInvestors() {
    return (
        <>
            <Helmet>
                <title>Lilypads | Retails Investors</title>
            </Helmet>
            <LPRInvestorHero />
            <LPRetailCustomPortfolio />
            {/* <LPRInvestmentJoinCommunity /> */}
            <LPLendersPayments />
            <LPRInvestmentHow />
            <LPRInvestmentPlatform />
            <LPRInvestorBecome />
            <LPWaitingList />
        </>
    )
}
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPNetworkBecomeAnInvestor() {
  const BecomeInvestor = [
    {
      text:
        'Every day new investment-grade properties are added to the marketplace, with investors joining the network and resources being shared across the platform',
    },
    {
      text: 'Lilypads helps you turn an opportunity into an investment',
    },
    {
      text:
        'Lilypads provide you with an ever-growing library of information provided by the best in class investment opportunities, techniques and real estate strategies for beginners as well as experts',
    },
    {
      text:
        'Connect with the real estate investing world by becoming a part of the Lilypads platform',
    },
  ]
  return (
    <>
      <section className="space-bottom bg-light">
        <Container>
          <Row className="align-items-center g-5 justify-content-between">
            <Col lg="5" className="text-center">
              <Image fluid src='https://lilypads.s3.us-east-2.amazonaws.com/images/network/become-investor.jpg' />
            </Col>
            <Col lg="6">
              <p className="subtitle">Become an Investor</p>
              <h2 className="mb-4">Become an Investor on Lilypads</h2>
              {BecomeInvestor.map((item, i) => {
                return (
                  <div className="d-flex">
                    <span className="me-3 d-inline-block">
                      {LPSVGIcons.DoubleCheck}
                    </span>
                    <p key={i} className="font-lexend mb-4 fw-400 small">
                      {item.text}
                    </p>
                  </div>
                )
              })}
              <div className="text-center text-md-start">
                <a href="/join-waiting-list">
                  <Button
                    variant="none"
                    className="bold btn-bordered-purple fw-600 mt-4"
                  >
                    Become an Investor
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import LPStoryHero from "./LPStoryHero/LPStoryHero";
import LPStoryOurPrinciples from "./LPStoryOurPrinciples/LPStoryOurPrinciples";
import LPStoryOurTeam from "./LPStoryOurTeam/LPStoryOurTeam";
import LPStoryWhyInvest from "./LPStoryWhyInvest/LPStoryWhyInvest";
import "./LPStory.css"
import Helmet from "react-helmet";
import LPStoryOurTeamMob from "./LPStoryOurTeam/LPStoryOurTeamMob";

export default function LPStory() {
    return (
        <>
            <Helmet>
                <title>Lilypads | Story</title>
            </Helmet>
            <LPStoryHero />
            <LPStoryOurPrinciples />
            <LPStoryWhyInvest />
            <div className="d-none d-sm-block">
                <LPStoryOurTeam />
            </div>
            <div className="d-block d-sm-none">
                <LPStoryOurTeamMob/>
            </div>
        </>
    )
}
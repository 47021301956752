import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../Components/Slider/Slider'

const JoinCommunity = [
  {
    title: 'Build campaigns with Ads Studio',
    text:
      'Let the right people know about your investments and properties you are interested in. With the Timeline feature, you can privately tell Investors / owners or publicly share with the Lilypads community that you are looking for new investment oppurtunities/ properties/ real estate assets.',
    Img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/network/join-community-new1.png',
  },
  {
    title: 'Build campaigns with Ads Studio',
    text:
      'Conversations today could lead to opportunity tomorrow Sending messages to people you know is a great way to strengthen relationships as you take the next step in your investment strategy.',
    Img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/network/join-community-new2.png',
  },
  {
    title: 'Build campaigns with Ads Studio',
    text:
      'Stay up to date on your commercial real estate assets and technological/ financial aspects of the same. Lilypads is full of ways to stay up to date on the latest discussions in the CRE industry',
    Img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/network/join-community-new3.png',
  },
  {
    title: 'Build campaigns with Ads Studio',
    text:
      'Connect with people who can help you invest, advertise your asset, sponsor or even provide u with a credit line',
    Img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/network/join-community-new4.png',
  },
]

export default function LPNetworkCommunityMob() {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <>
      <section className="space-bottom">
        <Container>
          <p className="subtitle">Join the Community</p>
          <Slider {...settings} className="custom-slider arrows-center">
            {JoinCommunity.map((item, i) => {
              return (
                <div className="" key={i}>
                  <h2>{item.title}</h2>
                  <p className="font-lexend mb-5 fw-400">{item.text}</p>
                  <a href="/join-waiting-list">
                    <Button variant="none" className="btn-bordered-purple">
                      Join the Community
                    </Button>
                  </a>

                  <Image fluid src={item.Img} className="mx-auto" />
                </div>
              )
            })}
          </Slider>
        </Container>
      </section>
    </>
  )
}

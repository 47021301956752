import { Col, Container, Row, Image } from 'react-bootstrap'

export default function LPIInvestorWhoWeAre() {
  const HowWeAre = [
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/deal.svg',
      title: 'Source a deal',
      text:
        'Identify promising investment deals, and infrastructure projects.',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/palm-bag.svg',
      title: 'Fundraise and close your SPV',
      text:
        'Give your investors the streamlined experience they deserve. KYC/AML, investor certification, execution – all streamlined and digital.',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/palm-share.svg',
      title: 'Structure your SPV / co-Investment vehicle',
      text:
        'Tailor your Wealth Container to your needs. Target asset or company, liquidity mechanics, waterfall, carried interest, etc.',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/verified.svg',
      title: 'Close your deal and relax',
      text:
        "Sign the Purchase Agreement, wire the money to the underlying issuer or transferor, and you're done. We will deal with your administrative and accounting matters for the lifetime of the SPV.",
    },
  ]
  return (
    <>
      <section className="space-top space-bottom who-we-are">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <Row>
                <Col lg="2"></Col>
                <Col>
                  <Row>
                    <Col lg={8} className="">
                      <p className="subtitle"> Execute Deal Workflows</p>
                      <h2 className="mb-4 text-capitalize">
                        The new way to structure a deal
                      </h2>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Image
                fluid
                src='https://lilypads.s3.us-east-2.amazonaws.com/images/institutional-investor/who-we-are.png'
                className="drop-shadow-light mx-auto d-block"
              />
            </Col>
            <Col lg="6">
              <div className="who-we-are-content mt-5">
                {HowWeAre.map((item, i) => {
                  return (
                    <div
                      className="who-we-are-block space-small-bottom"
                      key={i}
                    >
                      <div className="circle-icon w-80p me-4">
                        <Image src={item.icon} />
                      </div>
                      <div>
                        <p className="fw-600 font-lexend big mb-2">
                          {item.title}
                        </p>
                        <p className="fw-400 font-lexend mb-0">{item.text}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import LPWaitingList from "../../Components/LPWaitingList/LPWaitingList";
import LPBrokersComplianceMgnt from "./LPBrokersComplianceMgnt/LPBrokersComplianceMgnt";
import LPBrokersCustomerExp from "./LPBrokersCustomerExp/LPBrokersCustomerExp";
import LPBrokersFDT from "./LPBrokersFDT/LPBrokersFDT";
import LPBrokersHero from "./LPBrokersHero/LPBrokersHero";
import LPBrokersStreamline from "./LPBrokersStreamline/LPBrokersStreamline";
import "./LPBrokers.css"
import Helmet from "react-helmet";

export default function LPBrokers() {
    return (
        <>
            <Helmet>
                <title>Lilypads | Brokers</title>
            </Helmet>
            <LPBrokersHero />
            <LPBrokersStreamline />
            <LPBrokersCustomerExp />
            <LPBrokersComplianceMgnt />
            {/* <LPBrokersFDT /> */}
            <LPWaitingList />
        </>
    )
}
import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPNetworkOurCommunity() {
  const OurCommunity = [
    {
      text: 'Real Estate Investors',
    },

    {
      text: 'Builders',
    },
    {
      text: 'Land Developers',
    },
  ]
  const InvestmentProperties = [
    {
      text: 'Multifamily ',
    },
    {
      text: 'Office ',
    },
    {
      text: 'Industrial ',
    },
    {
      text: 'Hospitality & Retail  ',
    },
    {
      text: 'Built-to-suit ',
    },
  ]
  const PrivateFunding = [
    {
      text: 'Private Lenders ',
    },
    {
      text: 'Peer-to-Peer ',
    },
    {
      text: 'Investment Banks',
    },
  ]
  return (
    <>
      <section className="space-top space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center space-small-bottom g-5">
                <Col lg={6}>
                  <p className="subtitle">Our Community Platform</p>
                  <h2>Start a Fund & Network</h2>
                  <a href="/contact">
                    <Button
                      variant="none"
                      className="bold btn-bordered-purple fw-600"
                    >
                      Start Investing Now
                    </Button>
                  </a>
                </Col>
                <Col lg={6}>
                  {/* <p className="font-lexend fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi
                    sodales duis fermentum neque dignissim pharetra. Viverra leo
                    eget pellentesque eget felis a lectus elementum egestas.
                    Vitae facilisis id lorem purus imperdiet. Vulputate diam
                    morbi varius nibh id semper.
                  </p> */}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-5">
            <Col lg={4} md={6}>
              <div className="custom-card p-5 h-100 our-community-card">
                <span className="bordered-text fw-96 maroon text-white">
                  01
                </span>
                <Image src='https://lilypads.s3.us-east-2.amazonaws.com/images/icons/deal-bg.svg' />
                <p className="font-lexend big fw-600">Discover Opportunities</p>
                <p className="font-lexend small fw-400">
                  This is where opportunities can connect with eager investors.
                  Upload your property & review offers from a nationwide buyers
                  list
                </p>
                {OurCommunity.map((item, i) => {
                  return (
                    <div className="d-flex mb-2">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p key={i} className="font-lexend mb-0 fw-400 small">
                        {item.text}
                      </p>
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="custom-card p-5 h-100 our-community-card">
                <span className="bordered-text fw-96 maroon  text-white">
                  02
                </span>
                <Image src='https://lilypads.s3.us-east-2.amazonaws.com/images/icons/salary-blue.svg' />
                <p className="font-lexend big fw-600">
                  Find Investment Properties
                </p>
                <p className="font-lexend small fw-400">
                  Become an Investor to gain access to investment-grade
                  properties listed on the Lilypads platform.
                </p>
                {InvestmentProperties.map((item, i) => {
                  return (
                    <div className="d-flex mb-2">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p key={i} className="font-lexend mb-0 fw-400 small">
                        {item.text}
                      </p>
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="custom-card p-5 h-100 our-community-card">
                <span className="bordered-text fw-96 maroon text-white">
                  03
                </span>
                <Image src='https://lilypads.s3.us-east-2.amazonaws.com/images/icons/private-funding.svg' />
                <p className="font-lexend big fw-600">Private Funding</p>
                <p className="font-lexend small fw-400">
                  Sponsors facilitate funding for assets on our Lilypads
                  platform
                </p>
                {PrivateFunding.map((item, i) => {
                  return (
                    <div className="d-flex mb-2">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p key={i} className="font-lexend mb-0 fw-400 small">
                        {item.text}
                      </p>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7L16 7" stroke="#7C5AC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.5 1L16.5 7L11.5 13.5" stroke="#7C5AC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    );
}

export const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 7L2 7" stroke="#7C5AC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.5 1L1.5 7L6.5 13.5" stroke="#7C5AC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    );
}
import { useEffect, useState } from "react";
import axios from "axios";
// import { useSelector, useDispatch } from "react-redux";
import "./SecOneJD.scss";
import CareerDetails from "../../assets/images/career-details.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
//import react router params

const SecOneJD = () => {
  const [allJobs, setAllJobs] = useState("");
  const [jobName, setJobName] = useState("");
  const [jobExp, setJobExp] = useState("");
  const [jobSummary, setJobSummary] = useState("");
  const [skillreq, setSkillreq] = useState("");
  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    const config = {
      headers: {
        "X-Api-Key": "FWwKVWXl.9wYfvH87RqYpfZCCp32IiraFjfQ1uVUf",
      },
    };
    const url = `https://api.cogniertechnology.com/api/jobs`;
    const urlTwo = `https://api.cogniertechnology.com/api/jobs/2`;

    async function getData() {
      const res = await axios.get(url, config);
      console.log("count value " + id);
      console.log(res.data);
      let mapr = res.data.map((item) => {
        if (item.id == id) {
          console.log(item);
          setJobName(item.job_name);
          setJobExp(item.experience[0]?.experience_time);
          setJobSummary(item.job_detail);
          let mapr = item.skill.map((item) => {
            return item.skill_name;
          });
          setSkillreq(mapr);
        }
      });
      // console.log(`${id - 1}`);
      // console.log(res.data[1].job_name);
      // //   setJobName(res.data[`${count}`].job_name);
      // setJobName(res.data[`${id - 1}`].job_name);
      // setJobExp(res.data[`${id - 1}`].experience);
      // setJobSummary(res.data[`${id - 1}`].job_detail);
    }

    getData();
  }, []);

  return (
    <div className="SectionOneJD bg-light">
      {/* <Header active="5" /> */}
      <div className="SectionOneJDWrapper">
        <Container>
          <div style={{ marginTop: "10px" }} className="top">
            <Link to="/Career">Home {">"} Careers</Link>
            <Row className="align-items-end g-5 mb-5 pb-4">
              <Col md={8}>
                <h1 className="small mb-0">{jobName}</h1>
              </Col>
              <Col md={4}>
                {/* <p className="text-end mb-0">Maryland, USA | May 12th, 2022</p> */}
                <p className="text-end mb-0">Maryland, USA </p>
              </Col>
            </Row>
            {/* <div className="inner">
              <div className="left">
                <h5>LOCATION</h5>
                <h6>Maryland, USA</h6>
              </div>
              <div className="mid">
                <h3></h3>
                <h5>EXPERIENCE</h5>
                <h6>{jobExp}</h6>
              </div>
              <div className="innerBottom">
                <button
                  onClick={() => {
                    console.log(id);
                    navigate(`/career/application/${id}`);
                  }}
                >
                  <p>Apply Now</p>
                </button>
              </div>
            </div> */}
          </div>
        </Container>
        <Image style={{ marginTop: "-200px" }} fluid src={CareerDetails} className="w-100 career-details-img" />
        <Container >
          <div className="bottom career-details-bottom">
            <div className="career-details-head">
              <p className="text-white">
                We are looking for a young and passionate individual who has a
                keen interest in the finance domain and have minimum {jobExp}{" "}
                years experience in {skillreq}.
              </p>
              <button
                className="btn-bordered-purple bold fw-600 mt-5 btn btn-none bg-white"
                onClick={() => {
                  console.log(id);
                  navigate(`/career/application/${id}`);
                }}
              >
                <p>Apply Now</p>
              </button>
            </div>
            <div className="career-details-shift">
              {/* <h2> {jobSummary}</h2> */}
              <div dangerouslySetInnerHTML={{ __html: jobSummary }}></div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SecOneJD;

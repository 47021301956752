import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import datacentricapproach from '../../../assets/images/invest/dxfc.png'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import pinkone from '../../../assets/images/invest/01 (13).png'
import pinktwo from '../../../assets/images/invest/02 (13).png'
import pinkthree from '../../../assets/images/invest/03 (11).png'
import DIVERSIFY from '../../../assets/images/invest/Group (9).png'
import INDIVIDUAL from '../../../assets/images/invest/Group (10).png'
import EXCHANGES from '../../../assets/images/invest/Group (11).png'




export default function LPInvestSection3(){
    return(
      <>
      
        <div className="LPDiscoverSection3  .Discoverresponsiveness invest3rdbg">
       
           <Col lg={12}>
          
        <Container className='container '>
        <h1 className='StreamlinedInvestmentProcesses'>Streamlined Investment<br/> Processes</h1>
         <div className='WhatWeEmphasize Discoverresponsiveness'>
           
             <div className=''>
              
                <img className='' src={pinkone}/>
                <div className='perpectivecardindividual'> 
                <Discoverperpectivecard
                         CardHeading='Faster analysis '
                    Cardparagraph='Standardized templates enable the investors to track and manage investments across multiple funds'
                         />
                         </div>
             </div>
             <div className=''>
             
               <img className='' src={pinktwo}/>
               <div className='perpectivecardindividual'> 
               <Discoverperpectivecard
                         CardHeading='Accurate reporting'
                         Cardparagraph='The platform allows General Partners to go beyond reporting obligations and be more transparent and intuitive'
                         />
                         </div>
             </div>
             <div className=''>
              
               <img className='' src={pinkthree}/>
               <div className='perpectivecardindividual'> 
               <Discoverperpectivecard
                         CardHeading='Seamless workflow'
                         Cardparagraph='General Partners can manage reporting across their funds, track investor activity and upload critical data effortlessly'
                         />
                         </div>
             </div>
         </div>
         </Container>
         </Col>
        <Container>
          <Row className="justify-content-center Section3space">
          
            <Col lg={12}>
            <div className='discoverfeatureresponsiveness'>
            <Col lg={6}>
                 <div>
                     <img className='datacentricapproach' src={datacentricapproach}/>
                 </div>
                 </Col>
            <Col lg={6}>
                     <div className=''>
                       <h1 className='Custombuilt'>Custom built portfolio using data centric approach</h1>
                       <div>
                       <Col lg={11}>
                           <img className='featuresicons' src={DIVERSIFY}/>
                           <Discoverperpectivecard
                           CardHeading='Diversify with one investment​'
                           Cardparagraph='Invest in a growing portfolio of diverse commercial real estate properties through our Integrated deal management transaction market place which has been designed for income and growth while offering retirement investing choices'
                           />
                            </Col>
                       </div>
                       <div>
                       <Col lg={11}>
                       <img className='featuresicons' src={INDIVIDUAL}/>
                       <Discoverperpectivecard
                           CardHeading='Individual property investment'
                           Cardparagraph='Qualified investors may invest in single properties or "Private Placements" which are designed to meet specific objectives​'
                           />
                            </Col>
                       </div>
                       <div>
                          <Col lg={11}>
                          <img className='featuresicons' src={EXCHANGES}/>
                           <Discoverperpectivecard
                           CardHeading='1031 exchanges'
                           Cardparagraph='For investors seeking capital gains deferment from the sale of an existing property, we can offer 1031 eligible Private Placement investments'
                           />
                           </Col>
                      </div>
                     </div>
                     <div >
                     
                     </div>
                 </Col>
            </div>
            </Col>
          </Row>
        </Container>
        </div>
        </>
    );
}
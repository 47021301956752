import { Container, Row, Col, Button, Image } from 'react-bootstrap'

export default function LPBrokersComplianceMgnt() {
  return (
    <>
      <section className="space-top space-bottom compliance-mgnt">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center g-5">
                <Col lg={5}>
                  <p className="subtitle">Compliance Management</p>
                  <Row className="g-0 pb-0">
                    <Col sm={10}>
                      <h2 className="mb-3">
                        Compliance has never looked so good
                      </h2>
                    </Col>
                  </Row>
                  <p className="font-lexend fw-400 mb-4">
                    Access real-time reporting through the Lilypads Portal.
                    Radical transparency assists the compliance teams while
                    sophisticated data analytics offers insights to enhance your
                    customer experience
                  </p>
                  <div className="text-center text-md-start">
                    <a href="/contact">
                      <Button
                        variant="none"
                        className="bold btn-bordered-purple fw-600 mt-4"
                      >
                        Get a Demo
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Image
          className="compliance-mgnt-img img-reset-mob"
          fluid
          src="https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/compliance-management.png"
        />
      </section>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from './../../Components/Loader/Loader'
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../Components/Slider/Slider'
import moment from 'moment'
const LPBlogInd = () => {
  const category = useParams().category
  const name = useParams().name
  const [newsSummary, setNewsSummary] = useState('')
  const [newsTitle, setNewsTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(true)
    console.log('https://business.lilypads.com/' + category + '/' + name)
    let url = 'https://business.lilypads.com/' + category + '/' + name + '/'

    let allPostsHASH = JSON.parse(localStorage.getItem('lilypadsBlogs'))

    try {
      allPostsHASH.map((post, i) => {
        if (post.slug === name) {
          console.log('====================================')
          console.log('found in local')
          console.log('====================================')
          let id = post.id
          axios
            .get(`https://business.lilypads.com/wp-json/wp/v2/posts/${id}?_embed`)

            .then((res) => {
              console.log('============LOCAL POST ID========================')
              // console.log();
              console.log(res)
              console.log('====================================')

              setNewsSummary(res.data.content.rendered)
              setNewsTitle(res.data.title.rendered)
              setAuthor(res.data._embedded.author[0].name)

              const tagsDATA = []
              let tagHandlet = res.data._embedded['wp:term'].map((tags, i) => {
                // console.log('====================================')
                let subtag = tags.map((subtag, i) => {
                  tagsDATA.push(subtag.name)
                  console.log('====================================')
                  console.log(subtag.name)
                  console.log('====================================')
                  // console.log(subtag.name)
                })
                setTags(tagsDATA)
              })
              // return

              setLoading(false)
            })
            .catch(function (error) {
              console.log(error)
              setLoading(false)
              // alert('Failed toget Details')
            })
        }
      })
    } catch (err) {
      console.log(err)
      let isFound = false
      axios
        // .get(`https://business.lilypads.com/wp-json/wp/v2/posts/${id}?_embed`)
        .get(
          `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed`,
        )
        // .get(`https://business.lilypads.com/wp-json/wp/v2/posts?filter[slug]=${name}&_embed`)
        .then((res) => {
          console.log('============SLUUUGGG========================')
          // console.log();
          console.log(res)
          console.log('====================================')

          // setNewsSummary(res.data.content.rendered)
          // setNewsTitle(res.data.title.rendered)
          // setAuthor(res.data._embedded.author[0].name)
          // setLoading(false)
          // return

          let MainBlogMapperTWO = res.data.map((item, i) => {
            // console.log(item.link)
            // if (item.slug === name) {
            if (item.link === url) {
              console.log('==============FOUND======================')
              console.log(item)
              //set isFound true
              isFound = true
              console.log('====================================')
              setNewsSummary(item.content.rendered)
              setNewsTitle(item.title.rendered)
              setAuthor(item._embedded.author[0].name)
              const tagsDATA = []
              let tagHandlet = item._embedded['wp:term'].map((tags, i) => {
                // console.log('====================================')
                let subtag = tags.map((subtag, i) => {
                  tagsDATA.push(subtag.name)
                  console.log('====================================')
                  console.log(subtag.name)
                  console.log('====================================')
                  // console.log(subtag.name)
                })
                setTags(tagsDATA)
              })
              // setTags(tagsDATA)

              // setLoading(false)
            }
          })
          if (!isFound) {
            // alert("notFound")
            axios
              // .get(`https://business.lilypads.com/wp-json/wp/v2/posts/${id}?_embed`)
              .get(
                `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&page=2&_embed`,
              ).then((resT) => {
                let MainBlogMapper = resT.data.map((item, i) => {
                  // console.log(item.link)
                  // if (item.slug === name) {



                  if (item.link === url) {
                    console.log('==============FOUND TWO======================')
                    console.log(item)
                    // alert("Found in two")
                    //set isFound true
                    isFound = true
                    console.log('====================================')
                    setNewsSummary(item.content.rendered)
                    setNewsTitle(item.title.rendered)
                    setAuthor(item._embedded.author[0].name)
                    const tagsDATA = []
                    let tagHandlet = item._embedded['wp:term'].map((tags, i) => {
                      // console.log('====================================')
                      let subtag = tags.map((subtag, i) => {
                        tagsDATA.push(subtag.name)
                        console.log('====================================')
                        console.log(subtag.name)
                        console.log('====================================')
                        // console.log(subtag.name)
                      })
                      setTags(tagsDATA)
                    })
                    // setTags(tagsDATA)

                    // setLoading(false)
                  }
                })

              })
              .catch(function (error) {
                console.log(error)
                setLoading(false)
                // alert('Failed toget Details')
              })



          }

          setLoading(false)
        })
        .catch(function (error) {
          console.log(error)
          setLoading(false)
          // alert('Failed toget Details')
        })
    }

    // setLoading(false)
  }, [])

  const [relatedBlogs, setRelatedBlogs] = useState([])

  function addStr(str, index, stringToAdd) {
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    )
  }

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 0)
    axios
      // .get('https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100')
      .get(
        `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=6&page=1&_embed`,
      )
      // .get('https://business.lilypads.com/wp-json/wp/v2/categories')
      .then((res) => {
        console.log(res)
        // setLoading(false)
        setRelatedBlogs(
          res.data.map((name, i) => {
            let datePub = name.date
            let datePubFINAL = moment(datePub).fromNow()
            const tagsDATA = []
            let dmtagd = name._embedded['wp:term'].map((tags, i) => {
              // console.log('====================================')
              let subtag = tags.map((subtag, i) => {
                tagsDATA.push(subtag.name)
                // console.log(subtag.name)
              })
            })
            let imgT = name._embedded['wp:featuredmedia'][0]?.source_url
            //check if image is present

            let splicedLink = name.link.replace(
              'https://business.lilypads.com/',
              '',
            )
            let img = name.featured_media
            if (img === 0 || img === null) {
              return (
                <BlogSmallIND
                  link={splicedLink}
                  id={name.id}
                  index={i}
                  img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              )
            }
            return (
              <BlogSmallIND
                link={splicedLink}
                id={name.id}
                index={i}
                img={imgT}
                title={name.title.rendered}
                content={name.excerpt.rendered}
                pub={datePubFINAL}
                author={name._embedded.author[0].name}
                tags={tagsDATA}
              />
            )
          }),
        )
      })
      .catch(function (error) {
        // setLoading(false)
        console.log(error)
        // alert('Failed toget Details')
      })
  }, [])

  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    mobileFirst: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="mlc">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="LPBolg-main-IND">
            <div className="NDTitle font-lexend">
              <h2>{newsTitle}</h2>
              <h3>{author}</h3>
              <div className="BLOG-Tags">
                {tags.map((tag, i) => {
                  return <p key={i}>{tag}</p>
                })}

                {/* <p className="font-lexend">Real estate</p>
                <p className="font-lexend">Investment</p>
                <p className="font-lexend">Commercial</p> */}
              </div>
            </div>
            <div className="NDcontent">
              <div dangerouslySetInnerHTML={{ __html: newsSummary }}></div>
            </div>
          </div>
          <div className="LPBolg-main LPBolg-main-IND d-block pt-0 min-h-auto">
            <p className="subtitle">Blogs</p>
            <span className="h2">Related Blogs</span>
            <Slider
              {...settings}
              className="custom-slider related-blog-slider  arrows-center"
            >
              {relatedBlogs}
            </Slider>
          </div>
        </>
      )}
    </div>
  )
}

export default LPBlogInd

function BLOGINDI({ title, img, content, index, pub, author, tags, id }) {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        // history.push('/NewsDetails')
        console.log(id)
        // navigate('/blog')
        // navigate(`/blog/${id}`)
        window.location.reload()
      }}
      className="solo-BLOGCON"
    >
      <img src={img} alt="" />
      <div className="BLOG-Tags">
        {tags.map((tag, i) => {
          return <p>{tag}</p>
        })}

        {/* <p className="font-lexend">Real estate{tags}</p>
        <p className="font-lexend">Investment</p>
        <p className="font-lexend">Commercial</p> */}
      </div>
      <p className="tp">{title}</p>

      <div
        className="BLOG-content"
        dangerouslySetInnerHTML={{
          __html: `${content}`,
        }}
      ></div>
      <div className="Blog-BOTM">
        <p className="lp">{author} </p>
        <p className="rp">{pub} </p>
      </div>
    </div>
  )
}

function BlogSmallIND({
  title,
  img,
  content,
  index,
  pub,
  author,
  tags,
  id,
  link,
}) {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        console.log(id)
        navigate(`/${link}`)
        window.location.reload()
      }}
      className="p-4"
    >
      <div className="solo-BLOGCON w-auto min-h-auto">
        <img src={img} alt="" />
        <div className="BLOG-Tags">
          {tags.map((tag, i) => {
            return <p>{tag}</p>
          })}
        </div>
        <p className="tp">{title}</p>
        <div className="Blog-BOTM">
          <p className="lp">{author}</p>
          <p className="rp">{pub} </p>
        </div>
      </div>
    </div>
  )
}

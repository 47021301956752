import LPManageSection1 from "./LPManageSection1/LPManageSection1";
import LPManageSection2 from "./LPManageSection2/LPManageSection2";
import LPManageSection3 from "./LPManageSection3/LPManageSection3";
import LPManageSection4 from "./LPManageSection4/LPManageSection4";
import LPManageSection5 from "./LPManageSection5/LPManageSection5";
import './Manage.css'

export default function LPManage(){
    return(
        <>
        <LPManageSection1/>
        <LPManageSection2/>
        <LPManageSection3/>
        <LPManageSection4/>
        <LPManageSection5/>
        </>
    );
}
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard';
import Startpropertysyndicate from '../../../assets/images/Discover/lilypadsDiscover.png'


export default function(){
    return(
       <Container>
        <div className='propertysyndicate'>  
      
       <div className='propertysyndicatecontent'> 
         <h2 className="color-font mb-4">
         Launch your property syndicate<br/> online

         </h2>
         <Discoverperpectivecard
         Cardparagraph='Setup the legal structure, raise from your investors, and close your syndicated real estate deals in a paperless, swift and cost-efficient manner'
         />
         <a href="/join-waiting-list">
             <Button variant="none" className="bold btn-border btn-space-dis fw-600 mt-4">
                Become an Investor
             </Button>
         </a>
      </div>         
      <div className='propertysyndicateimage'> 
          <img className='propertysyndicateimageresponsive' src={Startpropertysyndicate}/>   
      </div>
        
  </div>
  </Container>
    );
}
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Typical from 'react-typical'

export default function LPHomeHero() {
  return (
    <>
      <section className="hero space-bottom">
        <Container className="hero-txt-container">
          <Row>
            <Col lg={6}>
              <p className="subtitle">Welcome to the</p>
              <h1 className="headerHERO">
                Real Estate <br></br>Capital Markets <br></br>Made Efficient for{' '}
                <span className="color-maroon typanim mb-5">
                  <div className="insSUPPORT">a</div>
                  <Typical
                    loop={Infinity}
                    wrapper="b"
                    steps={[
                      'Retail investors ',
                      3000,
                      'Institutional Investors',
                      3000,
                      'Brokers',
                      3000,
                    ]}
                  />
                </span>
              </h1>
              {/* <h2>saas</h2> */}
              <p className="mb-0 mb-md-5 pb-0 pb-md-5 lexlendFONT">
                Curated real estate investment products for growth-focused
                investors. We make real estate investment accessible,
                collaborative, secure & instant.
              </p>
              <div className="text-center text-sm-start d-none d-md-block">
                <a href="/join-waiting-list">
                  <Button variant="none" className="btn-purple btn-shadow">
                    Start Investing Now
                  </Button>
                </a>
              </div>
              {/* <Row
                style={{ opacity: 0, pointerEvents: 'none' }}
                className="g-4 space-top d-md-flex d-none"
              >
                <Col>
                  <span className="h2 big color-purple font-urbanist fw-800 mb-1">
                    658+
                  </span>
                  <p className="font-urbanist">Properties Registered</p>
                </Col>
                <Col>
                  <span className="h2 big color-purple font-urbanist fw-800 mb-1">
                    658+
                  </span>
                  <p className="font-urbanist">Deals Cracked</p>
                </Col>
                <Col>
                  <span className="h2 big color-purple font-urbanist fw-800 mb-1">
                    255+
                  </span>
                  <p className="font-urbanist">Investors</p>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
        <div className="hero-img-container">
          <div className="position-relative me-5 hero-img-container-inner">
            <Image
              fluid
              src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/hero1.png"
              className="hero-inner-img scale-img scale-transition"
            />
            <Image
              fluid
              src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/hero2.png"
              className="scale-img scale-transition hero-main-img"
            />
          </div>
          <div className="position-relative h-100">
            <Image
              fluid
              src="https://lilypads.s3.us-east-2.amazonaws.com/images/home/hero3.png"
              className="scale-img scale-transition hero-main-img"
            />
          </div>
        </div>
        <div className="text-center d-block d-md-none pt-5">
          <a href="/join-waiting-list">
            <Button variant="none" className="btn-purple btn-shadow">
              Start Investing Now
            </Button>
          </a>
        </div>
      </section>
    </>
  )
}

import { Button, Col, Container, Row } from 'react-bootstrap'
import './LPNetworkCommunity.scss'
import React, { useState, useEffect, useRef } from 'react'
import {
  useScrollPosition,
  useScrollXPosition,
  useScrollYPosition,
} from 'react-use-scroll-position'
import lockScroll from 'react-lock-scroll'
import { useInView } from 'react-intersection-observer'
import { RemoveScroll } from 'react-remove-scroll'
export default function LPNetworkCommunity() {
  const [index, setIndex] = useState(1)
  const [sLock, setSLock] = useState(false)
  const [scrollDir, setScrollDir] = useState('')
  const scrollY = useScrollYPosition()
  const [oldScroll, setOldScroll] = useState(0)

  const [isInPage, setIsInPage] = useState(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const [ref2, inView2] = useInView({
    /* Optional options */
    threshold: 0,
  })

  //check if its in view
  useEffect(() => {
    if (inView === false || inView2 === false) {
      setSLock(false)
      setIsInPage(false)
      console.log('not in view')
      return
    }
    // if (inView === true && inView2 === true) {
    //   setSLock(true)
    //   setIsInPage(true)
    //   console.log(' in view')
    //   return
    // }
  }, [inView, inView2])

  const handleTV = () => {
    console.log('handle TV')
    setSLock(true)
    setIsInPage(true)
  }
  //to set lock on scroll
  useEffect(() => {
    if (inView === true && inView2 === true) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    if (sLock === false) {
      document.body.style.overflow = 'auto'
    }
  }, [inView, inView2, sLock])
  //check if both views are in view
  useEffect(() => {
    if (inView === true && inView2 === true) {
      const data = true
      handleTV()
      console.log(' in view')
      return
    }
  }, [inView, inView2])

  const handleAdd = () => {
    // e.preventDefault()
    if (index >= 4) {
      setSLock(false)
      // setIndex(1)
      return
    }
    // setSLock(true)
    setIndex(index + 1)
  }
  const handleSub = (e) => {
    e.preventDefault()
    if (index <= 1) {
      // setIndex(4)
      setSLock(false)
      return
    }
    // setSLock(true)
    setIndex(index - 1)
  }

  //function if inview is true then add index
  const inVSub = () => {
    if (inView === true && inView2 === true) {
      console.log('INV u')
      if (index <= 1) {
        setSLock(false)
        return
      }
      setSLock(true)
      setIndex(index - 1)
    }
  }

  const inVADD = () => {
    if (inView === true && inView2 === true) {
      console.log('INV d')
      if (index >= 4) {
        setSLock(false)
        return
      }
      setSLock(true)
      setIndex(index + 1)
    }
  }

  const [acceptInput, setAcceptInput] = useState(true)
  //to ignore multiple events
  // useEffect(() => {

  // },[])

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setAcceptInput(!acceptInput)
    setTimeout(() => {
      setAcceptInput(true)
    }, 1200)
  }

  const [scChange, setScChange] = useState(false)

  const handleSchange = (e) => {
    setScChange(!scChange)
    if (e.deltaY > 0) {
      console.log('down')
      console.log('=================sLock===================')
      console.log(isInPage)
      console.log('====================================')
      inVADD()
    } else {
      console.log('up')
      inVSub()
    }
  }
  useEffect(() => {
    window.addEventListener('wheel', handleSchange)

    return () => {
      window.removeEventListener('wheel', handleSchange)
    }

    // window.addEventListener('wheel', (e) => {
    //   handleSchange(e)
    // })
    // //Clear the Listener
    // return function cleanupListener() {
    //   window.removeEventListener('wheel', (e) => {
    //     handleSchange(e)
    //   })
    // }
  })

  return (
    <>
      <section className="space-bottom LPNetworkCommunity-main ">
        <div className="LPNetworkCommunity-Container">
          <div ref={ref} className="intersectionCheck"></div>
          <div ref={ref2} className="intersectionCheckT"></div>
          <div className="LPNetworkCommunity-L">
            <p className="subtitle">Join the Community</p>

            {index === 1 ? (
              <>
                <h2>Build campaigns with Ads Studio</h2>
                <p className="font-lexend mb-5 fw-400 pM">
                  An advanced all-in-one tool for creating ads across platform
                </p>
                <a href="/join-waiting-list">
                  <Button variant="none" className="btn-bordered-purple">
                    Join the Community
                  </Button>
                </a>
              </>
            ) : index === 2 ? (
              <>
                <h2>Inspire new audiences</h2>
                <p className="font-lexend mb-5 fw-400 pM">
                  Build influence, and create compelling content that suppports
                  your brand
                </p>
                <a href="/join-waiting-list">
                  <Button variant="none" className="btn-bordered-purple">
                    Join the Community
                  </Button>
                </a>
              </>
            ) : index === 3 ? (
              <>
                <h2>Set a budget that works</h2>
                <p className="font-lexend mb-5 fw-400 pM">
                  The cost to advertise is up to you. Select a budget that fits
                  your business goals and update it at any time.
                </p>
                <a href="/join-waiting-list">
                  <Button variant="none" className="btn-bordered-purple">
                    Join the Community
                  </Button>
                </a>
              </>
            ) : (
              <>
                <h2>Promote your posts with a tap</h2>
                <p className="font-lexend mb-5 fw-400 pM">
                  Turn any post into an ad. Just decide where to send users, who
                  should see it and how much to spend.
                </p>
                <a href="/join-waiting-list">
                  <Button variant="none" className="btn-bordered-purple">
                    Join the Community
                  </Button>
                </a>
              </>
            )}
          </div>
          <div className="LPNetworkCommunity-R">
            <div className="networkSliderMain">
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiOne ') +
                  (index === 2 && ' posiOUT ') +
                  (index === 3 && ' posiOUT ') +
                  (index === 4 && ' posiOUT ')
                }
              >
                <img src="https://lilypads.s3.us-east-2.amazonaws.com/images/network/sliderOne.png" alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiTwo ') +
                  (index === 2 && ' posiThree ') +
                  (index === 3 && ' posiFour ') +
                  (index === 4 && ' posiOne ')
                }
              >
                <img src="https://lilypads.s3.us-east-2.amazonaws.com/images/network/sliderTwo.png" alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiThree ') +
                  (index === 2 && ' posiFour ') +
                  (index === 3 && ' posiOne ') +
                  (index === 4 && ' posiOUT ')
                }
              >
                <img src="https://lilypads.s3.us-east-2.amazonaws.com/images/network/sliderThree.png" alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiFour ') +
                  (index === 2 && ' posiOne ') +
                  (index === 3 && ' posiOUT ') +
                  (index === 4 && ' posiOUT ')
                }
              >
                <img src='https://lilypads.s3.us-east-2.amazonaws.com/images/network/sliderFour.png' alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import LPSVGIcons from "../../../../Components/LPSVGIcons/LPSVGIcons";

export default function LPIInvestorHero() {
  return (
    <>
      <section className="inner-hero space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center space-bottom">
                <Col lg={6}>
                  <h1 className="small">
                    Lilypads for
                    <br />{" "}
                    <span className="color-maroon">
                      Institutional Investors
                    </span>
                  </h1>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend">
                    Unlock property intelligence by transacting in a deal flow
                    network and uncover commercial properties anywhere in the
                    U.S. and identify that match your strategy of interest in a
                    fraction of the time.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-5 space-small-top institutional-investors-hero-cols">
            <Image
              fluid
              className="institutional-investors-hero-img drop-shadow-light d-lg-block d-none"
              src="https://lilypads.s3.us-east-2.amazonaws.com/images/institutional-investor/hero-arrow.svg"
            />
            <Col lg={4} md={6}>
              <div className="i-investor-hero">
                <span className="bordered-text maroon font-dm-serif">01</span>
                <h2>Discover</h2>
                <p className="font-lexend-fw-400 mb-3">
                  Identify and discover profitable deals and Commercial Real
                  Estate assets among our database with our AI asset matching
                  algorithms
                </p>
                <Link
                  to="/Discover"
                  className="color-purple font-lexend fw-600"
                >
                  Read More {LPSVGIcons.LongArrow}{" "}
                </Link>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="i-investor-hero mt-4">
                <span className="bordered-text maroon font-dm-serif">02</span>
                <h2>Invest</h2>
                <p className="font-lexend-fw-400 mb-3">
                  Start investing in promising investment deals and
                  infrastructure projects in just 4 easy steps
                </p>
                <Link to="/Invest" className="color-purple font-lexend fw-600">
                  Read More {LPSVGIcons.LongArrow}{" "}
                </Link>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="i-investor-hero mt-5">
                <span className="bordered-text maroon font-dm-serif">03</span>
                <h2>Manage</h2>
                <p className="font-lexend-fw-400 mb-3">
                  Lilypads facilitates comprehensive SPV administration and deal
                  management from identifying leads to soliciting capital
                </p>
                <Link to="/Manage" className="color-purple font-lexend fw-600">
                  Read More {LPSVGIcons.LongArrow}{" "}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

// import { Container, Row, Col, Image, Tab} from 'react-bootstrap'
// import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
// import React, { useState } from "react"; 
// 



// export default function LPInvestSection1(){
//     const data = [
//         {
//           id: "1",
//           key: "1",
//           title: "Create an Account",
//           text: "Complete your account and create your profile online, share your investment goals and preferences and connect with the global network of investors",
//           img:   <img src={CreateanAccount} className="photo" />
//         },
//         {
//           id: "2",
//           key: "2",
//           title: "Select your Investments",
//           text: "Complete your account and create your profile online, share your investment goals and preferences and connect with the global network of investors..",
//           img: "2.jpg"
//         },
//         {
//           id: "3",
//           key: "3",
//           title: "Invest in Minutes",
//           text: "Complete your account and create your profile online, share your investment goals and preferences and connect with the global network of investors.",
//           img: "3.jpg"
//         },
//         {
//           id: "4",
//           key: "4",
//           title: "Track your investments",
//           text: "Complete your account and create your profile online, share your investment goals and preferences and connect with the global network of investors.",
//           img: "4.jpg"
//         }
//       ];
//    
import { Button, Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import Slider from 'react-slick'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../Components/Slider/Slider'
import CreateanAccount from '../../../assets/images/invest/Group 21694.png'
import SelectyourInvestments from '../../../assets/images/invest/Group 21695.png'
import InvestinMinutes from '../../../assets/images/invest/Invitations 3 (4).png'
import Trackyourinvestments from '../../../assets/images/invest/Invitations 3 (5).png'


const HowToInvest = [
  {
    title: 'Create an Account',
    text:
      'Create your account and share your investment goals and preferences and connect with a global network of investors',
    Img: <Image fluid className="" src={CreateanAccount} />,
  },
  {
    title: 'Select your Investments',
    text:
      'Select suitable institutional grade commercial real estate assets teeming with resilience and lucrative opportunities in the best locations across the country',
    Img: <Image fluid className="" src={SelectyourInvestments} />,
  },
  {
    title: 'Invest in Minutes',
    text:
      'Start investing in minutes with a streamlined real estate investment platform that digitalizes the investment process and accelerates the processing time',
    Img:<Image fluid className="" src={InvestinMinutes} />,
  },
  {
    title: 'Track your investments',
    text:
      'Gain real-time visibility into your investments and track and monitor your investor holdings with continuous reporting',
    Img:<Image fluid className="" src={Trackyourinvestments} />,
  },
]

export default function LPRInvestmentHow() {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <>
      <section className="how-to-invest investspace-top space-bottom">
        <Container>
          <div className="d-none d-md-block">
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row className="justify-content-between align-items-center g-5">
              
                <Col lg={5} md={6}>
                  <Row className="justify-content-end">
                    <Col xl={11}>
                      <h2 className="mb-3">
                        How to <span className="color-maroon">Invest?</span>
                      </h2>
                      <p className="font-lexend fw-400 mb-5">
                        Modern ways of investing in Commercial Real Estate. 4
                        easy steps for Investments
                      </p>
                      <Nav className="flex-column custom-v-tabs mb-5">
                        {HowToInvest.map((item, i) => {
                          return (
                            <Nav.Link
                              key={i}
                              as="div"
                              className="custom-tab-link font-lexend small fw-400 mb-0"
                              eventKey={i}
                            >
                              <p className="big fw-600">{item.title}</p>
                              <p className="small mb-0">{item.text}</p>
                            </Nav.Link>
                          )
                        })}
                      </Nav>
                      <a href="/join-waiting-list">
                        <Button
                          variant="none"
                          className="btn-purple btn-shadow"
                        >
                          Start Investing Now
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Tab.Content>
                    {HowToInvest.map((item, i) => {
                      return (
                        <Tab.Pane key={i} eventKey={i} style={{textAlign: 'center'}}>
                         {item.Img}
                          
                        </Tab.Pane>
                      )
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div className="d-block d-md-none">
            <h2 className="mb-3">
              How to <span className="color-maroon">Invest?</span>
            </h2>
            <p className="font-lexend fw-400 mb-5">
              Modern ways of investing in Commercial Real Estate. 4 easy steps
              for Investments
            </p>
            <Slider {...settings} className="custom-slider arrows-center">
              {HowToInvest.map((item, i) => {
                return (
                  <div className="" key={i}>
                   
                    <div className="text-center">
                      <p className="big fw-600">{item.title}</p>
                      <p className="small mb-0">{item.text}</p>
                    </div>
                    <>
                    {item.Img}
                    </>
                  </div>
                )
              })}
            </Slider>
            <div className="text-center text-md-start mt-5">
            <a href="/join-waiting-list">
              <Button variant="none" className="btn-purple btn-shadow">
                Start Investing Now
              </Button>
              </a>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}



import { Button, Col, Container, Row } from 'react-bootstrap'
// import './LPNetworkCommunity.scss'
import React, { useState, useEffect, useRef } from 'react'
import {
  useScrollPosition,
  useScrollXPosition,
  useScrollYPosition,
} from 'react-use-scroll-position'
import lockScroll from 'react-lock-scroll'
import { useInView } from 'react-intersection-observer'
import { RemoveScroll } from 'react-remove-scroll'
import onePng from '../../assets/images/newLanding/scroll/one.png'
import twoPng from '../../assets/images/newLanding/scroll/two.png'
import threePng from '../../assets/images/newLanding/scroll/three.png'
import fourPng from '../../assets/images/newLanding/scroll/four.png'
import fivePng from '../../assets/images/newLanding/scroll/five.png'

export default function LPNSecFour() {
  const [index, setIndex] = useState(1)
  const [sLock, setSLock] = useState(false)
  const [scrollDir, setScrollDir] = useState('')
  const scrollY = useScrollYPosition()
  const [oldScroll, setOldScroll] = useState(0)
  const [fixPosiActive, setFixPosiActive] = useState()
  const [isInPage, setIsInPage] = useState(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const [ref2, inView2] = useInView({
    /* Optional options */
    threshold: 0,
  })

  //check if its in view
  useEffect(() => {
    if (inView === false || inView2 === false) {
      setSLock(false)
      setIsInPage(false)
      console.log('not in view')
      return
    }
    // if (inView === true && inView2 === true) {
    //   setSLock(true)
    //   setIsInPage(true)
    //   console.log(' in view')
    //   return
    // }
  }, [inView, inView2])

  const handleTV = () => {
    console.log('handle TV')
    setSLock(true)
    setIsInPage(true)
  }
  //to set lock on scroll
  useEffect(() => {
    if (inView === true && inView2 === true) {
      document.body.style.overflow = 'hidden'
      setFixPosiActive(true)
    } else {
      document.body.style.overflow = 'auto'
      setFixPosiActive(false)
    }

    if (sLock === false) {
      document.body.style.overflow = 'auto'
      setFixPosiActive(false)
    }
  }, [inView, inView2, sLock])
  //check if both views are in view
  useEffect(() => {
    if (inView === true && inView2 === true) {
      const data = true
      handleTV()
      console.log(' in view')
      return
    }
  }, [inView, inView2])

  const [widthSize, setWidthSize] = useState()

  useEffect(() => {
    if (index === 1) setWidthSize(0)
    if (index === 2) setWidthSize(25)
    if (index === 3) setWidthSize(50)
    if (index === 4) setWidthSize(75)
    if (index === 5) setWidthSize(100)
  }, [index])

  //function if inview is true then add index
  const inVSub = () => {
    if (inView === true && inView2 === true) {
      console.log('INV u')
      if (index <= 1) {
        setSLock(false)
        return
      }
      setSLock(true)
      setIndex(index - 1)
    }
  }

  const inVADD = () => {
    if (inView === true && inView2 === true) {
      console.log('INV d')
      if (index >= 5) {
        setSLock(false)
        return
      }
      setSLock(true)
      setIndex(index + 1)
    }
  }

  const [acceptInput, setAcceptInput] = useState(true)
  //to ignore multiple events
  // useEffect(() => {

  // },[])

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setAcceptInput(!acceptInput)
    setTimeout(() => {
      setAcceptInput(true)
    }, 1200)
  }

  const [scChange, setScChange] = useState(false)

  const handleSchange = (e) => {
    setScChange(!scChange)
    if (e.deltaY > 0) {
      console.log('down')
      console.log('=================sLock===================')
      console.log(isInPage)
      console.log('====================================')
      inVADD()
    } else {
      console.log('up')
      inVSub()
    }
  }
  useEffect(() => {
    window.addEventListener('wheel', handleSchange)

    return () => {
      window.removeEventListener('wheel', handleSchange)
    }

    // window.addEventListener('wheel', (e) => {
    //   handleSchange(e)
    // })
    // //Clear the Listener
    // return function cleanupListener() {
    //   window.removeEventListener('wheel', (e) => {
    //     handleSchange(e)
    //   })
    // }
  })

  return (
    <>
      <section className="space-bottom LPNetworkCommunity-main ">
        <div className="LPNetworkCommunity-Container lpnScroolCon">
          <div ref={ref} className="intersectionCheck"></div>
          <div ref={ref2} className="intersectionCheckT"></div>
          <div className="LPNetworkCommunity-NEW-L">
            <div className="LPNetworkCommunity-NEW-L-Item">
              {' '}
              <p className="headNav">/ Platform</p>
            </div>
            <div className="LPNetworkCommunity-NEW-L-Item">
              {' '}
              <div className="lpNewVLine">
                <svg
                  width="3"
                  height="438"
                  viewBox="0 0 3 438"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1.5"
                    y1="1.5"
                    x2="1.5"
                    y2="436.5"
                    stroke="url(#paint0_linear_2190_3554)"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2190_3554"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="438"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#9E2349" />
                      <stop
                        offset="1"
                        stop-color="#9E2349"
                        stop-opacity="0.3"
                      />
                    </linearGradient>
                  </defs>
                </svg>
                <p>scroll</p>
                <svg
                  width="16"
                  height="51"
                  viewBox="0 0 16 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.2929 50.7071C7.68342 51.0976 8.31658 51.0976 8.70711 50.7071L15.0711 44.3431C15.4616 43.9526 15.4616 43.3195 15.0711 42.9289C14.6805 42.5384 14.0474 42.5384 13.6569 42.9289L8 48.5858L2.34315 42.9289C1.95262 42.5384 1.31946 42.5384 0.928934 42.9289C0.53841 43.3195 0.53841 43.9526 0.928934 44.3431L7.2929 50.7071ZM7 4.37114e-08L7 50L9 50L9 -4.37114e-08L7 4.37114e-08Z"
                    fill="url(#paint0_linear_2190_3558)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2190_3558"
                      x1="8"
                      y1="50"
                      x2="8"
                      y2="0"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#9E2349" />
                      <stop
                        offset="1"
                        stop-color="#9E2349"
                        stop-opacity="0.3"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              {index === 1 && (
                <>
                  {' '}
                  <div className="dynamicText">
                    <div className="dtH">CRM Network</div>
                    <div className="dtP">
                      Our integrated CRM platform effectively facilitates
                      collaboration and partnerships between fund managers, and
                      owner-operators through simplified investor communication
                      portals with centralized communication features, team
                      activity trackers, and contact and lead management tools.
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  {' '}
                  <div className="dynamicText">
                    <div className="dtH">Fundraising</div>
                    <div className="dtP">
                      Our leading-edge fundraising tool with custom-built
                      fundraising dashboards and data rooms allows investors and
                      owner-operators to raise more capital and close deals
                      faster with seamless KYC and NDA automation
                    </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>
                  {' '}
                  <div className="dynamicText">
                    <div className="dtH">Investor Portal</div>
                    <div className="dtP">
                      We maximize investor engagement and satisfaction through
                      our robust and interactive investor portal that allows
                      investor activity tracking, secures document sharing,
                      provides detailed asset reporting and performance-metric
                      analysis, and enterprise-level data encryption
                    </div>
                  </div>
                </>
              )}
              {index === 4 && (
                <>
                  {' '}
                  <div className="dynamicText">
                    <div className="dtH">Investor Reporting</div>
                    <div className="dtP">
                      Institutional managers, Deal Managers, and Owner Operators
                      can now generate automated investor and asset reports,
                      statements, notices capital calls, and distributions using
                      personalized templates
                    </div>
                  </div>
                </>
              )}
              {index === 5 && (
                <>
                  {' '}
                  <div className="dynamicText">
                    <div className="dtH">Insights</div>
                    <div className="dtP">
                      Using our intelligent Insights tool, Owner-operators can
                      access a personal dashboard that aggregates all their data
                      gathered from multiple platform tools and generates
                      data-driven actionable insights to boost their business
                      performance.
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="LPNetworkCommunity-R">
            <div className="networkSliderMain">
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiOne ') +
                  (index === 2 && ' posiOUT ') +
                  (index === 3 && ' posiOUT ') +
                  (index === 4 && ' posiOUT ') +
                  (index === 5 && ' posiOUT ')
                }
              >
                <img src={onePng} alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiTwo ') +
                  (index === 2 && ' posiThree ') +
                  (index === 3 && ' posiFour ') +
                  (index === 4 && ' posiOne ') +
                  (index === 5 && ' posiOUT ')
                }
              >
                <img src={twoPng} alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiThree ') +
                  (index === 2 && ' posiFour ') +
                  (index === 3 && ' posiOne ') +
                  (index === 4 && ' posiOUT ') +
                  (index === 5 && ' posiOUT ')
                }
              >
                <img src={threePng} alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posiFour ') +
                  (index === 2 && ' posiOne ') +
                  (index === 3 && ' posiOUT ') +
                  (index === 4 && ' posiOUT ') +
                  (index === 5 && ' posiOUT ')
                }
              >
                <img src={fourPng} alt="" />
              </div>
              <div
                className={
                  'networkSliderMain-Item ' +
                  (index === 1 && ' posisemiOUT ') +
                  (index === 2 && ' posiTwo ') +
                  (index === 3 && ' posiThree ') +
                  (index === 4 && ' posiFour ') +
                  (index === 5 && ' posiOne ')
                }
              >
                <img src={fivePng} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ width: `${widthSize}%` }}
          className={'ProgressLine ' + (fixPosiActive && ' active ')}
        ></div>
      </section>
    </>
  )
}

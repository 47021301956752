import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../LPSVGIcons/LPSVGIcons'
import './LPFooter.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
const Socials = [
  {
    link: 'https://www.facebook.com/LilypadsFundFormation',
    icon: LPSVGIcons.Facebook,
  },
  {
    link: 'https://twitter.com/lilypads_io',
    icon: LPSVGIcons.Twitter,
  },
  {
    link: 'https://www.linkedin.com/company/lilypadsai/',
    icon: LPSVGIcons.LinkedIn,
  },
  {
    link: 'https://www.youtube.com',
    icon: LPSVGIcons.Youtube,
  },
  {
    link: 'https://medium.com/@Lilypads',
    icon: LPSVGIcons.Medium,
  },
  {
    link: 'https://www.pinterest.com/lilypads_io/',
    icon: LPSVGIcons.Pintrest,
  },
]

export default function LPFooter() {
  const [email, setEmail] = useState('')
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [emailUnsuccess, setEmailUnsuccess] = useState(false)

  const handleEmailSubmit = (e) => {
    e.preventDefault()
    if (email === '') {
      setEmailUnsuccess(true)
      return
    }
    const config = {
      headers: {
        'X-Api-Key': 'FWwKVWXl.9wYfvH87RqYpfZCCp32IiraFjfQ1uVUf',
      },
    }
    console.log('====================================')
    console.log('email', email)
    console.log('====================================')
    const body = new FormData()
    body.append('Email', email)
    let data = {
      email: email,
    }
    const url = `https://api.cogniertechnology.com/api/newsletter/`

    axios
      .post(url, data, config)
      .then((res) => {
        console.log(res)
        setEmailSuccess(true)
        setEmailUnsuccess(false)
        alert('Thank you for subscribing to our newsletter')
        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error)
        setEmailSuccess(false)
        setEmailUnsuccess(true)
        alert('Something went wrong')
        // alert('Failed toget Details')
      })
  }

  const handleChange = (e) => {
    e.preventDefault() // prevent the default action
    setEmail(e.target.value) // set name to e.target.value (event)
  }

  return (
    <>
      <footer>
        <Container>
          <Row className="g-5">
            <Col lg="6" className="footer-border">
              <div className="footer-form">
                <div className="footer-subscribe-wrapper">
                  <div className="footer-subscribe  mb-3">
                    <Form.Control
                      type="email"
                      onChange={handleChange}
                      value={email}
                      required
                      placeholder="Enter Your Email Here"
                    />
                    <Button
                      onClick={handleEmailSubmit}
                      variant="none"
                      className="btn-dark"
                    >
                      Subscribe To Resider
                    </Button>
                  </div>
                  {emailSuccess && (
                    <p className="color-white mb-0">
                      Thank you for subscribing to our Resider
                    </p>
                  )}
                  {emailUnsuccess && (
                    <p className="color-maroon mb-0">Something went wrong</p>
                  )}
                </div>
                <h5 className="fw-600 color-white font-lexend mb-5">
                  Sign up and start enjoying the benefits today!
                </h5>
                <a href="/contact">
                  {/* <Button variant="none" className="btn-purple btn-shadow">
                    Sign Up To Lilypads
                  </Button> */}
                </a>
                <div className="social-list">
                  {Socials.map((social, i) => {
                    return (
                      <a
                        key={i}
                        href={social.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {social.icon}
                      </a>
                    )
                  })}
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="footer-space">
                <Row className="align-items-center mb-5">
                  <Col md={4} className="mb-md-0 mb-5">
                    <Image
                      fluid
                      src="https://lilypads.s3.us-east-2.amazonaws.com/images/lilypads-logo-gif.gif"
                    />
                  </Col>
                  <Col md={8} className="">
                    <p className="x-small footer-head">Reach Us At</p>
                    <p className="footer-text x-small">
                      6751 Columbia Gateway Dr.,
                      <br />
                      3rd floor, Columbia,
                      <br /> MD 21046
                    </p>
                  </Col>
                </Row>
                <Row className="g-5">
                  <Col md={4} xs={6}>
                    <p className="x-small footer-head">Workspace</p>
                    <div className="footer-links">
                      <Link to={'/retails-investors'} className="footer-text">
                        Retail Investors
                      </Link>
                      <Link
                        to={'/institutional-investors'}
                        className="footer-text"
                      >
                        Institutional Investors
                      </Link>
                      <Link to={'/brokers'} className="footer-text">
                        Brokers
                      </Link>
                    </div>
                  </Col>
                  <Col md={4} xs={6}>
                    <p className="x-small footer-head">Product</p>
                    <div className="footer-links">
                      <Link to={'/Discover'} className="footer-text">
                        Discover
                      </Link>
                      <Link to={'/Invest'} className="footer-text">
                        Invest
                      </Link>
                      <Link to={'/Manage'} className="footer-text">
                        Manage
                      </Link>
                    </div>
                  </Col>
                  <Col md={4} xs={6}>
                    <p className="x-small footer-head">Story</p>
                    <div className="footer-links">
                      <Link to={'/network'} className="footer-text">
                        Approach
                      </Link>
                      <Link to={'/story'} className="footer-text">
                        About Us
                      </Link>
                    </div>
                  </Col>
                  <Col md={4} xs={6}>
                    <p className="x-small footer-head">Technology</p>
                    <div className="footer-links">
                      <Link to={'/ai'} className="footer-text">
                        Artificial Intelligence
                      </Link>
                      <Link to={'/blockchain'} className="footer-text">
                        Blockchain
                      </Link>
                    </div>
                  </Col>
                  <Col md={4} xs={6}>
                    <p className="x-small footer-head">Help</p>
                    <div className="footer-links">
                      <Link to={'/blog'} className="footer-text">
                        Blog
                      </Link>
                      <Link to={'/knowledgeCenter'} className="footer-text">
                        Knowledge Center
                      </Link>{' '}
                      <Link to={'/career'} className="footer-text">
                        Career
                      </Link>
                    </div>
                  </Col>
                  {/* <Col md={4} xs={6} className="d-md-block d-none" >
                    <p className="x-small footer-head">Business</p>
                    <div className="footer-links">
                      <Link to={'/Sponsored'} className="footer-text">Sponsored</Link>
                      <Link to={'/Ad-Manager'} className="footer-text">Ad Manager</Link>
                      <Link to={'/Boosting'} className="footer-text">Boosting</Link>
                      <Link to={'/Ad-Monitoring'} className="footer-text">Ad-Monitoring</Link>
                      <Link to={'/Leads'} className="footer-text">Leads</Link>
                      <Link to={'/Marketing'} className="footer-text">Marketing</Link>
                      <Link to={'/Insight'} className="footer-text">Insight</Link>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

import React from 'react'
import './LPRetailInvestor.scss'
import oneImg from './1.png'
import twoImg from './2.png'
import threeImg from './3.png'
import fourImg from './4.png'
import fiveImg from './5.png'
import sixImg from './6.png'
import gone from './g1.png'
import gtwo from './g2.png'

const LPRetailInvestor = () => {
  return (
    <div className="LPRetailInvestor-mainCon">
      <div className="LPRetailInvestor-main">
        <div className="lineConRI">
          <h1>How it works</h1>
          <h2>
            Transforming your real assets with our compliance infrastructure for
            digitalization. Utilizing our sophisticated tech-driven platform and
            products, we make commercial real estate investments seamless,
            efficient, and more achievable.
          </h2>
        </div>
        <div className="leftRightCOn one">
          <img className="gone" src={gone} alt="" />
          <div style={{ marginTop: '-100px' }} className="lrc-l">
            <div className="tcon">
              <h1>Compliance</h1>
              <h2>
                Invest in crypto-backed real estate properties and trade
                securities and tokens with maximum security. Since trading real
                estate tokens must be in compliance with laws and regulations as
                they are securities, before executing deals we ensure that our
                users are KYC verified and comply with AML regulations to
                prevent fraudulent activities during the issuance of tokens.
              </h2>
              <div className="listitemCon">
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Investor/Broker Whitelisting

                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Automated KYC/AML compliance
                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Investor/Broker Onboarding

                </div>
              </div>
            </div>
          </div>
          <div style={{ marginRight: '-100px' }} className="lrc-l">
            <img src={oneImg} alt="" />
          </div>
        </div>
        <div className="leftRightCOn two">
          <img className="gtwo" src={gtwo} alt="" />
          <div style={{ marginLeft: '-100px' }} className="lrc-l">
            <img src={twoImg} alt="" />
          </div>
          <div style={{ marginTop: '-100px' }} className="lrc-l">
            <div className="tcon">
              <h1>Issuance</h1>
              <h2>
              Our hyperledger fabric-based platform facilitate minting, burning, and trading of Trustokens on our permissioned blockchain network. Tokens generated by our token engine enable owners to digitize their selected assets and list them on our network to trade. The asset owner decides how many tokens to issue and the price of each token.
              </h2>
              <div className="listitemCon">
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Property onboarding

                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Property verification

                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Smart Contract formation and Token generation

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="leftRightCOn three">
          <div style={{ marginTop: '-100px' }} className="lrc-l">
            <div className="tcon">
              <h1>Onchain Custody</h1>

              <div className="listitemCon">
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Built-in regulated custody
                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Regulate supply and price of tokens

                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Shares listed on the platform

                </div>
              </div>
            </div>
          </div>
          <div style={{ marginRight: '-100px' }} className="lrc-l cen">
            <h2>
            Tokens are generated based on the prices set by the asset owner. With the help of smart contracts or chaincodes owners are able to control the supply of their asset-backed tokens and regulate the prices of their tokens based on the value of the asset.

            </h2>
            <img src={threeImg} alt="" />
          </div>
        </div>
        <div className="topBtmCon black">
          <div className="gc lt">
            <div className="icrcl"></div>
          </div>
          <div className="gc rt">
            {' '}
            <div className="icrcl"></div>
          </div>
          <div className="gc lb">
            {' '}
            <div className="icrcl"></div>
          </div>
          <div className="gc rb">
            {' '}
            <div className="icrcl"></div>
          </div>
          <div className="blackBG"></div>
          <div className="topBtmCon-top">
            <div className="tcon">
              <h1>Distribution</h1>
              <h2>
              Tokens generated are shared on our fabric platform which allows asset owners to access a global network of verified and authenticated investors. Our SPV engine allows investors to directly participate in the trading of tokens and securities and increases the liquidity of assets while eliminating the need for intermediaries.
              </h2>
            </div>
            <div className="listitemCon">
              <div className="listitem">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1480_16677)">
                    <path
                      d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                      fill="#7C5AC7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1480_16677">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Built-in regulated custody
              </div>{' '}
              <div className="listitem">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1480_16677)">
                    <path
                      d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                      fill="#7C5AC7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1480_16677">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Securities recovery process
              </div>{' '}
              <div className="listitem">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1480_16677)">
                    <path
                      d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                      fill="#7C5AC7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1480_16677">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Digital asset custodian partners
              </div>
            </div>
          </div>
          <img src={fourImg} alt="" />
        </div>
        <div className="leftRightCOn four">
          <div style={{ marginTop: '-100px' }} className="lrc-l">
            <div className="tcon">
              <h1>Servicing for issuers</h1>
              <h2>
              Issuers/Owners can easily track, manage, and monitor the activities of their assets. Besides benefiting from increased transparency, liquidity, and operational efficiency, the Digitrust wallet ensures that tokens/shares purchased by investors are directly credited to their Digitrust wallet account.

              </h2>
              <div className="listitemCon">
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Directly communicate with investors
                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Automatic reporting
                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Automated account credition

                </div>
              </div>
            </div>
          </div>
          <div style={{ marginRight: '-100px' }} className="lrc-l">
            <img src={fiveImg} alt="" />
          </div>
        </div>
        <div className="topBtmCon">
          <div className="topBtmCon-top">
            <div className="tcon">
              <h1>Servicing for Investor</h1>

              <div className="listitemCon">
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Rapid transfer of ownership
                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Quick aquisitions

                </div>{' '}
                <div className="listitem">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1480_16677)">
                      <path
                        d="M11.602 13.7595L13.014 15.1715L21.48 6.70552L22.894 8.11952L13.014 17.9995L6.65 11.6355L8.064 10.2215L10.189 12.3465L11.602 13.7585V13.7595ZM11.604 10.9315L16.556 5.97852L17.966 7.38852L13.014 12.3415L11.604 10.9315ZM8.777 16.5865L7.364 17.9995L1 11.6355L2.414 10.2215L3.827 11.6345L3.826 11.6355L8.777 16.5865Z"
                        fill="#7C5AC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_16677">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Invest without intermediaries

                </div>
              </div>
            </div>
            <h2>
            Our permissioned decentralized platform ensures the security and confidentiality of business investments and transactions. With an automated platform integrated with the latest technological tools, we provide investors with a seamless and efficient way to close deals and manage portfolios.

            </h2>
          </div>
          <img src={sixImg} alt="" />
        </div>
      </div>
    </div>
  )
}

export default LPRetailInvestor

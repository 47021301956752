import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
import oneM from './../../../assets/images/Home/oneM.png';

export default function LPStoryHero() {
  return (
    <>
      <section className="inner-hero space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center space-bottom">
                <Col lg={5}>
                  <p className="subtitle">
                    Our story {LPSVGIcons.AngleArrow} About us
                  </p>
                  <h1 className="small mb-3">Investing in a better tomorrow</h1>
                  <p className="font-urbanist mb-5">
                    Lilypads helps you discover and invest in people and
                    companies shaping the future of the real estate. The world’s
                    largest real estate brands trust Lilypads to navigate and
                    discover opportunities
                  </p>
                  <a href="/join-waiting-list">
                    <Button variant="none" className="btn-purple btn-shadow">
                      Join Our Community
                    </Button>
                  </a>
                </Col>
                <Col lg={7} className="text-lg-end text-center">
                  <Image
                    fluid
                    src="https://lilypads.s3.us-east-2.amazonaws.com/images/story/hero.png"
                    className="maroon-shadow mt-5 mt-lg-0"
                  />
                </Col>
              </Row>
              <Row className="align-items-center g-5">
                <Col lg={6}>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <Image src="https://lilypads.s3.us-east-2.amazonaws.com/images/icons/mission.svg" />
                    </div>
                    <div className="flex-grow-1 ms-5">
                      <p className="fw-600 mb-3">Our Mission</p>
                      <p className="mb-0 fw-400">
                        Lilypads network empowers real estate investment
                        liquidity and capital markets with data and
                        collaboration through purpose-built software
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <Image src="https://lilypads.s3.us-east-2.amazonaws.com/images/icons/vision.svg" />
                    </div>
                    <div className="flex-grow-1 ms-5">
                      <p className="fw-600 mb-3">Our Vision</p>
                      <p className="mb-0 fw-400">
                        Lilypads is committed to connecting the world’s real
                        estate professionals and creating a global community
                        impact
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import Sourceadeal from '../../../assets/images/Discover/Group 21569.png'
import FundraiseandcloseyourSPV from '../../../assets/images/Discover/Group 21571.png'
import StructureyourSPV from '../../../assets/images/Discover/Group 21570.png'
import Closeyourdeal from '../../../assets/images/Discover/Group 21572.png'

export default function LPDisplaySection5(){
   return(
    <> 
    <section>
    <Container style={{marginTop:80}}>
      <Row className="justify-content-center">
        <Col lg={10}>
          <Row className="align-items-center">
            <Col lg={8}>
              <p className="subtitle">
              Launch a property
              </p>
              <h1 className="small">The easiest way to launch a property syndicate</h1>
            </Col>
            <Col lg={4}>
              {/* <p className="font-lexend">
              Lilypads utilizes data-driven insights and Machine Learning
               algorithms to streamline your property search and investment opportunities
              </p> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    </section>
    <section>
    <Container>
       <div className='LaunchaProperty'>
                  <div className='LaunchaPropertyBlock'>
                      <Col lg={3}>
                          <img src={Sourceadeal}/>
                      </Col>
                      <Col lg={9}>
                          <div>
                             <Discoverperpectivecard
                               CardHeading='Source a deal'
                              Cardparagraph='This is your part of the job. Identify a promising investment deals, and infrastructure project'   
                             />
                          </div>
                      </Col>
                  </div>
                  <div className='LaunchaPropertyBlock'>
                      <Col lg={3}>
                      <img src={FundraiseandcloseyourSPV}/>
                      </Col>
                      <Col lg={9}>
                          <div>
                       <Discoverperpectivecard
                           CardHeading='Fundraise and close your SPV'
                           Cardparagraph='Give your investors the streamlined experience they deserve. KYC/AML, investor certification, execution – all streamlined and digital. Pay only after you have successfully raised!'   
                          />
                          </div>
                      </Col>
                  </div>
       </div>
   
   
    <div className='LaunchaProperty'>
                  <div className='LaunchaPropertyBlock'>
                      <Col lg={3}>
                      <img src={StructureyourSPV}/>
                      </Col>
                      <Col lg={9}>
                          <div>
                      <Discoverperpectivecard
                           CardHeading='Structure your SPV / co-Investment vehicle'
                           Cardparagraph='Tailor your Wealth Container to your needs. Target asset or company, liquidity mechanics, waterfall, carried interest etc'   
                          />
                          </div>
                      </Col>
                  </div>
                  <div className='LaunchaPropertyBlock'>
                      <Col lg={3}>
                      <img src={Closeyourdeal}/>
                      </Col>
                      <Col lg={9}>
                          <div>
                      <Discoverperpectivecard
                           CardHeading='Close your deal — and relax'
                           Cardparagraph='Sign the Purchase Agreement, wire the money to the underlying issuer or transferor, you are done. We will deal with your administrative and accounting matters for the lifetime of the SPV'   
                          />
                          </div>
                      </Col>
                  </div>
       </div>
       </Container>
    </section>
    </> 
   );
}
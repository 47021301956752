import React from "react";
import { Helmet } from "react-helmet";
import SecOneApplication from "./SecOneApplication.jsx";
import SecTwoCl from "./SecTwoCl";

export default function LPCareers() {
  return (
    <>
      <Helmet>
        <title>Lilypads | Career</title>
      </Helmet>

      <SecTwoCl />

      {/* <SecOneApplication /> */}

      {/* <LPHomeHero />
            <LPHomeJoinLilypads />
            <LPHomeAboutUs />
            <LPHomeProductImages />
            <LPHomeManageDeadflow />
            <LPWaitingList /> */}
    </>
  );
}

import React, { useRef, useEffect } from 'react'

import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import onePng from '../../assets/images/newLanding/bottomScroll/one.png'
import twoPng from '../../assets/images/newLanding/bottomScroll/two.png'
import threePng from '../../assets/images/newLanding/bottomScroll/three.png'

const LPFullPageScrollMob = () => {
  return (
    <>
      <div className="lP">
        <div className="fwP">
          <section class="panellWA one">
            <div className="pannelmcCon">
              {/* asasd */}
              <div className="panel-L">
                <p>/ Administration</p>

                <img src={onePng} alt="" />
              </div>
              <div className="panel-R">
                <div className="tcon">
                  <h2>Treasury Services</h2>
                  <h3>
                    Our Treasury Services facilitates the management of capital
                    activity for Owner Operators by providing a centralized
                    platform for all of their financial needs.
                  </h3>

                  <ul>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Efficient Capital Call calculation and prompt notification
                    </li>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Increased credibility by effective call and notice
                      distribution
                    </li>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Seamless and secure distribution payments
                    </li>
                  </ul>
                  <a className="mt10Self" href="/join-waiting-list">
                    <Button variant="none" className="btn-purple btn-shadow ">
                      Explore Service{' '}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.999 9.99956H2.00098M17.999 9.99956L15.0445 13.545M17.999 9.99956L15.0445 6.4541"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="lP">
        <div className="fwP">
          <section class="panellWA two">
            <div className="pannelmcCon">
              {/* asasd */}
              <div className="panel-L">
                <p>/ Administration</p>

                <img src={twoPng} alt="" />
              </div>
              <div className="panel-R">
                <div className="tcon">
                  <h2>Investor Services</h2>
                  <h3>
                    From initial onboarding to ongoing data management, our
                    comprehensive platform addresses every facet of managing
                    investors and their assets.
                  </h3>

                  <ul>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      One-stop shop for all areas of investor management
                    </li>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Customisable platform best suited to your needs
                    </li>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Innovative ledger and asset data management
                    </li>
                  </ul>
                  <a className="mt10Self" href="/join-waiting-list">
                    <Button variant="none" className="btn-purple btn-shadow ">
                      Explore Service{' '}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.999 9.99956H2.00098M17.999 9.99956L15.0445 13.545M17.999 9.99956L15.0445 6.4541"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="lP">
        <div className="fwP">
          <section class="panellWA three">
            <div className="pannelmcCon">
              {/* asasd */}
              <div className="panel-L">
                <p>/ Administration</p>

                <img src={threePng} alt="" />
              </div>
              <div className="panel-R">
                <div className="tcon">
                  <h2>Fund Accounting</h2>
                  <h3>
                    Our cutting-edge Fund Accounting system enables Fund
                    Managers to manage critical fund information, fund
                    financials, and vendor payments more effectively through
                    optimized automated processes.
                  </h3>

                  <ul>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Streamlined tax and audit season reporting{' '}
                    </li>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Centralised platforms managed vendor payments efficiently{' '}
                    </li>
                    <li>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="32"
                          height="32"
                          rx="16"
                          fill="#9E2349"
                          fill-opacity="0.08"
                        />
                        <path
                          d="M15.7164 17.4557L16.8772 18.6166L23.8375 11.6563L25 12.8188L16.8772 20.9416L11.6451 15.7095L12.8076 14.5469L14.5547 16.294L15.7164 17.4549V17.4557ZM15.718 15.1307L19.7893 11.0586L20.9485 12.2178L16.8772 16.2899L15.718 15.1307ZM13.3938 19.7799L12.2321 20.9416L7 15.7095L8.16251 14.5469L9.3242 15.7086L9.32338 15.7095L13.3938 19.7799Z"
                          fill="#9E2349"
                        />
                      </svg>
                      Automated monitoring and assessing of fund performance{' '}
                    </li>
                  </ul>
                  <a className="mt10Self" href="/join-waiting-list">
                    <Button variant="none" className="btn-purple btn-shadow ">
                      Explore Service{' '}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.999 9.99956H2.00098M17.999 9.99956L15.0445 13.545M17.999 9.99956L15.0445 6.4541"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default LPFullPageScrollMob

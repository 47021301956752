import { Container, Row, Col, Image, Accordion } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPLendersFDT() {
  const FDTList = [
    {
      title:
        'At sed amet tortor egestas orci, at. Netus ultrices faucibus condimentum.',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
    {
      title: 'Tempor condimentum vitae et vitae adipiscing neque tempor augue.',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
    {
      title:
        'Sed cursus sagittis massa donec viverra maecenas viverra. Nulla .',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
    {
      title:
        'Bibendum nunc id tincidunt vitae sed. Velit aliquet semper quisque.',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
    {
      title:
        'Elit pulvinar dolor amet imperdiet accumsan. Eleifend aliquam vitae ',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
    {
      title: 'Tempor condimentum vitae et vitae adipiscing neque tempor augue.',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
    {
      title: 'Non adipiscing enim purus bibendum massa quis semper pretium.',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo, egestas pellentesque tempor, massa tellus diam egestas. Et ut tempor netus tempor purus. Fusce libero lacus viverra ac lacus, quam. Justo facilisis adipiscing commodo consequat. Morbi consequat non, nunc elementum quis tellus ',
    },
  ]

  return (
    <>
      <section className="bg-dark space-top space-bottom fdt">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center space-small-bottom">
                <Col lg={6}>
                  <h2 className="color-white">Fast, digital and transparent</h2>
                </Col>
                <Col lg={6}>
                  <p className="fw-400 color-white">
                    Leveraging the cutting edge technology of the future through a
                    data-centric approach that you can trust.{' '}
                  </p>
                </Col>
              </Row>
              <Row className="g-5">
                <Col lg={4}>
                  <div className="bg-dark-gray p-5 text-center fdt-left">
                    <Image className="crown-img" src="https://lilypads.s3.us-east-2.amazonaws.com/images/icons/crown.svg" />
                    <p className="big text-uppercase color-white mb-2">
                      for Lenders
                    </p>
                    <div className="mb-4">
                      <p className="big text-uppercase color-white mb-1 d-inline-flex align-items-center">
                        <span className="me-2">$1,500</span>
                      </p>
                      <p className="small color-white opacity-50 fw-400">
                        Onboarding Fees
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="big text-uppercase color-white mb-1">
                        Included
                      </p>
                      <p className="small color-white fw-400">
                        <span className="opacity-50"> Lender Specific Workspace</span>
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="big text-uppercase color-white mb-1">
                        Lender Firm
                      </p>
                      <p className="small color-white fw-400">
                        $599 <span className="opacity-50"> for 4 Users</span>
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="mb-1">{LPSVGIcons.RoundCheck}</p>
                      <p className="small color-white opacity-50 fw-400">
                        Disbursements
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="mb-1">{LPSVGIcons.RoundCheck}</p>
                      <p className="small color-white opacity-50 fw-400">
                        Loan-waiver Management
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="mb-1">{LPSVGIcons.RoundCheck}</p>
                      <p className="small color-white opacity-50 fw-400 mb-0">
                        Risk Monitoring
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="bg-dark-gray p-5">
                    <ul>
                      {/* <li>CRE Financing</li> */}
                      <li>Mezzanine &amp; Preferred Equity</li>
                      {/* <li>Co-GP, LP &amp; Joint Venture</li> */}
                      <li>Net Lease Properties</li>
                      {/* <li>Industrial Real Estate</li> */}
                      <li>Bridge Financing</li>
                      <li>Construction Financing</li>
                      <li>Loan Sales &amp; Lines of Credit</li>
                      <li>
                        Performing, Sub-Performing &amp; Non-Performing Whole
                        Loan Sales
                      </li>
                      <li>Bridge Lender &amp; Note Buyer LOC</li>
                      <li>Loan-on-Loan Financing</li>
                      <li>Acquisition Facilities</li>
                      <li>A-Note Bifurcation</li>
                      <li>Advisory &amp; Transaction Services</li>
                      <li>Capital Restructuring</li>
                      <li>Capital Stack Optimization</li>
                      <li>Risk Scenario Analysis</li>
                      <li>Loan Servicing</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import LPDiscoverSection1 from "./LPDiscoverSection1/LPDiscoverSection1"
import LPDiscoverSection2 from "./LPDiscoverSection2/LPDiscoverSection2"
import LPDiscoverSection3 from "./LPDiscoverSection3/LPDiscoverSection3"
import LPDiscoverSection4 from "./LPDiscoverSection4/LPDiscoverSection4"
import LPDisplaySection5 from "./LPDiscoverSection5/LPDiscoverSection5"
import LPDiscoverSection6 from "./LPDiscoverSection6/LPDiscoverSection6"
import LPDiscoverSection7 from "./LPDiscoverSection7/LPDiscoverSection7"
import './Discover.css'

export default function LPDiscover(){
    return(
        <>
       <LPDiscoverSection1/>
       <LPDiscoverSection2/>
       <LPDiscoverSection3/>
       <LPDiscoverSection4/>
       <LPDisplaySection5/>
       <LPDiscoverSection6/>
       <LPDiscoverSection7/>
        </>
    );
}
import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// import scroolImg from '../../assets/images/newLanding/scroolIMG.png'
import scroolImg from '../../assets/images/newLanding/FPS/one.gif'
import scroolImgtwo from '../../assets/images/newLanding/FPS/two.gif'
import scroolImgthree from '../../assets/images/newLanding/FPS/three.gif'
import scroolImgfour from '../../assets/images/newLanding/FPS/four.gif'
import scroolImgfive from '../../assets/images/newLanding/FPS/five.gif'
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

function LPNSecThree() {
  const sectionRef = useRef(null)
  const triggerRef = useRef(null)
  const [width, height] = useWindowSize()
  const [gsapTransform, setGsapTransform] = useState('-1280px')
  useEffect(() => {
    console.log('====================================')
    console.log(width)
    console.log('====================================')

    if (width < 1228) {
      // alert("active")
      setGsapTransform('-1880px')
    }

    if (width < 608) {
      // alert("active")
      setGsapTransform('-2180px')
    }
  }, [width])

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    const pin = gsap.fromTo(
      sectionRef.current,
      {
        translateX: 0,
      },
      {
        translateX: gsapTransform,
        ease: 'none',
        duration: 1,
        scrollTrigger: {
          trigger: triggerRef.current,
          start: 'top top',
          end: '2000 top',
          scrub: 0.6,
          pin: true,
        },
      },
    )
    return () => {
      {
        /* A return function for killing the animation on component unmount */
      }
      pin.kill()
    }
  }, [ gsapTransform])

  return (
    <section className="scroll-section-outer bgBlack">
      {/* The section up act just as a wrapper. If the trigger (below) is the
      first jsx element in the component, you get an error on route change */}

      {/* The div below act just as a trigger. As the doc suggests, the trigger and 
      the animation should alway be two separated refs */}
      <div className="scroolHOLDER" ref={triggerRef}>
        <div className="newITEM">
          <p>/ Rising to your Investment challenges</p>
          <h2>
            scroll right{' '}
            <svg
              width="91"
              height="12"
              viewBox="0 0 91 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M90.5303 6.53033C90.8232 6.23744 90.8232 5.76256 90.5303 5.46967L85.7574 0.696699C85.4645 0.403806 84.9896 0.403806 84.6967 0.696699C84.4038 0.989592 84.4038 1.46447 84.6967 1.75736L88.9393 6L84.6967 10.2426C84.4038 10.5355 84.4038 11.0104 84.6967 11.3033C84.9896 11.5962 85.4645 11.5962 85.7574 11.3033L90.5303 6.53033ZM0 6.75H90V5.25H0V6.75Z"
                fill="white"
              />
            </svg>
          </h2>
        </div>
        <div ref={sectionRef} className="scroll-section-inner">
          <div className="scroll-section">
            <div className="FPSCON">
              <div className="FPS-ITEM">
                <img src={scroolImg} alt="" />
                <div className="fpsLine">
                  <div className="fpsLineCircle"></div>
                </div>
                <h2>Data that is always in action</h2>
                <p>
                  Our cloud-based real estate investment platform helps you make
                  data-driven investment decisions and assists with reaching
                  your long-term investment objectives
                </p>
              </div>
              <div className="FPS-ITEM">
                <img src={scroolImgtwo} alt="" />
                <div className="fpsLine">
                  <div className="fpsLineCircle"></div>
                </div>
                <h2>Diversify and aim higher with Lilypads</h2>
                <p>
                  We leverage proprietary data science and risk metrics to
                  create your access to top commercial real estate properties
                  across the US that will position your portfolio to deliver
                  stable returns
                </p>
              </div>
              <div className="FPS-ITEM">
                <img src={scroolImgthree} alt="" />
                <div className="fpsLine">
                  <div className="fpsLineCircle"></div>
                </div>
                <h2>Enhancing your digital security</h2>
                <p>
                  Let us secure your data in our decentralized and distributed
                  data network that enforces your transactions more
                  transparently
                </p>
              </div>
              <div className="FPS-ITEM">
                <img src={scroolImgfour} alt="" />
                <div className="fpsLine">
                  <div className="fpsLineCircle"></div>
                </div>
                <h2>One tap to unlock convenience</h2>
                <p>
                  Blockchain-based applications automate your payments and
                  streamline your asset management and data storage all in one
                  integrated platform
                </p>
              </div>
              <div className="FPS-ITEM">
                <img src={scroolImgfive} alt="" />
                <div className="fpsLine">
                  <div className="fpsLineCircle"></div>
                </div>
                <h2>Your personal time manager</h2>
                <p>
                  We offer you a swift and seamless onboarding process with a
                  streamlined KYC/AML and investor certification procedure
                </p>
              </div>
            </div>
            {/* <img src={scroolImg} alt="" /> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LPNSecThree

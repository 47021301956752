import LPInvestSection1 from "./LPInvestsection1/LPInvestSection1"
import LPInvestSection2 from "./LPInvestSection2/LPInvestSection2";
import LPInvestSection3 from "./LPInvestSection3/LPInvestSection3";
import LPInvestSection4 from "./LPInvestSection4/LPInvestSection4";
import './Invest.css'

export default function LPInvest(){
    return(
        <>
        <LPInvestSection1/>
        <LPInvestSection2/>
        <LPInvestSection3/>
        <LPInvestSection4/>
        </>
   );
}
import { Container, Row, Col } from 'react-bootstrap'
import LPSVGIcons from '../../../../Components/LPSVGIcons/LPSVGIcons'
const LPRInvestorList = [
  {
    icon: LPSVGIcons.Investment,
    title: 'Diversify with one investment',
    text:
      'Invest in a growing portfolio of diverse commercial real estate properties through our integrated private placement marketplace which has been designed for income and growth while offering retirement investing choices.',
  },
  {
    icon: LPSVGIcons.Home,
    title: 'Individual property investments',
    text:
      'Qualified investors may invest in single properties or funds which are designed to meet specific strategic objectives.',
  },
  {
    icon: LPSVGIcons.Shuffle,
    title: '1031 exchanges',
    text:
      'For investors seeking capital gains deferment from the sale of an existing property, we can offer 1031 eligible Investment Vehicles.',
  },
]

export default function LPRetailCustomPortfolio() {
  return (
    <>
      <section className="space-top space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Row className="align-items-center g-5 space-bottom">
                <Col xl={6}>
                  <h2>Custom built portfolios using a data-centric approach</h2>
                </Col>
                <Col xl={6}>
                  <p className="font-lexend fw-400">
                    Real Estate Retail Investors can utilize predictive
                    recommendations and AI algorithms to access suitable deals
                    and projects that constitute their data backed balanced
                    investment portfolio
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="space-top">
            <Row className="g-5">
              {LPRInvestorList.map((item, i) => {
                return (
                  <>
                    <Col
                      key={i}
                      lg={4}
                      md={6}
                      className="mt-5 custom-portfolio-list-wrap"
                    >
                      <div className="p-5 custom-card h-100 custom-portfolio-list">
                        <span className="bordered-text">0{i + 1}</span>
                        <div className="circle-icon w-60p mb-2 mb-2">
                          {item.icon}
                        </div>
                        <p className="fw-600 big font-lexend">{item.title}</p>
                        <p className="font-lexend fw-400 mb-0 small">
                          {item.text}
                        </p>
                      </div>
                    </Col>
                  </>
                )
              })}
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

import { Container, Row, Col, Tab, Nav, Image } from "react-bootstrap";
import LPSVGIcons from "../../../Components/LPSVGIcons/LPSVGIcons";

export default function LPBrokersHero() {
    const BrokersHeroList = [
        {
            icon: LPSVGIcons.Brain,
            title: "boost efficiency",
            text: "Tools and services streamline the listing, sale, and closing of properties, fast-tracking sales for brokers and sellers",
            img: "https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/broker-hero1.png",
        },
        {
            icon: LPSVGIcons.Investment,
            title: "increase performance",
            text: "Listings on Lilypads perform better, with expert marketing support and a global buyer network at your fingertips",
            img: "https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/broker-hero2.png",
        },
        {
            icon: LPSVGIcons.LineChart,
            title: "extend reach",
            text: "Organize all your transaction tasks with the Lilypads platform. Transactions happen 3x faster in the engaged Lilypads environment",
            img: "https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/broker-hero3.png",
        },
        {
            icon: LPSVGIcons.Investment,
            title: "gain certainty",
            text: "Sell any property with more certainty thanks to the high-touch, full service partnership of the Lilypads team",
            img: "https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/broker-hero4.png",
        },
    ]
    return (
        <>
            <section className="inner-hero">
                <Container >
                    <Row className="justify-content-center space-small-bottom">
                        <Col lg={10}>
                            <Row className="align-items-center">
                                <Col lg={6}>
                                    <h1 className="small text-capitalize">
                                        Full-service <span className="color-maroon">Broker</span> empowerment for CRE
                                    </h1>
                                </Col>
                                <Col lg={6}>
                                    <p className="font-lexend">Lilypads provides a centralized platform where you can gain a real-time holistic view of your listings, acqusitions and transactions that transform your operations and innovates your business</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="d-md-block d-none">
                        <Tab.Container defaultActiveKey="LPBrokersHero0" >
                            <Nav>
                                <Row className="g-5 text-center space-small-bottom g-5">
                                    {BrokersHeroList.map((item, i) => {
                                        return (
                                            <>
                                                <Col sm={6} lg={3} >
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={`LPBrokersHero${i}`} className="broker-hero-nav">
                                                            <div className="circle-icon w-80p mb-3 light-maroon mx-auto">
                                                                {item.icon}
                                                            </div>
                                                            <p className="font-lexend big fw-600 text-capitalize">{item.title}</p>
                                                            <p className="font-lexend fw-400 small mb-0">{item.text}</p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Row>
                            </Nav>
                            <Tab.Content>
                                {BrokersHeroList.map((item, i) => {
                                    return (
                                        <>
                                            <Tab.Pane eventKey={`LPBrokersHero${i}`}>
                                                <div className="text-center broker-hero-img">
                                                    <Image className="maroon-shadow" fluid src={item.img} />
                                                </div>
                                            </Tab.Pane>

                                        </>
                                    )
                                })}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    <div className="d-md-none d-block">
                        {BrokersHeroList.map((item, i) => {
                            return (
                                <>
                                    <div className="mb-5 pb-5">
                                        <div className="circle-icon w-80p mb-3 light-maroon">
                                            {item.icon}
                                        </div>
                                        <p className="font-lexend big fw-600 text-capitalize">{item.title}</p>
                                        <p className="font-lexend fw-400 small mb-0">{item.text}</p>
                                        <div className="text-center mt-5">
                                            <Image className="maroon-shadow" fluid src={item.img} />
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </Container>
            </section>
        </>
    )
}
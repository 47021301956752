import { Container, Button, Row, Col, Image ,OverlayTrigger,Tooltip,Accordion} from 'react-bootstrap'
// import LPSVGIcons from '../../../../Components/LPSVGIcons/LPSVGIcons'

// export default function LPIInvestorFDT() {
   
// }

export default function LPDiscoverSection7(){

    const FDTList = [
        {
          title: 'How does Lilypads outperforms other real estate investing platform?',
          text: (
            <ul>
             Lilypads outpaces other real estate investing platforms with its diverse 
             offerings ranging from equity, preferred equity, senior debt investments, 
             and so on. We also offer investors the opportunity to select any particular
            commercial real estate asset type in mid-markets, that fulfills their investment 
            goals. Investments on our centralized platform guarantee robust cash flow and 
            efficient management of all payments and updates.<br/><br/>
            We are proudly equipped with a dynamic team of employees with extensive industry
            experience and knowledge. Our team ensures that our commitment to incorporating 
            transparency, diligent customer service and comprehensive asset management updates 
            is retained throughout the life cycle of each investment.
            </ul>
          ),
        },
        {
          title: 'How is investing through Lilypads different from investing in a Public REIT?',
          text: (
            <ul>
             As public REITs are traded on public platforms they offer some 
             liquidity that Lilypads investments do not. In public investment 
             through a REIT, the value of REIT shares become largely dependent 
             on market fluctuations rather than the underlying real estate values.
             Such correlation with market sentiments restricts the performance 
             of public REITs as an inflationary hedge against other stock-like 
             public market investments.<br/><br/>
             Lilypads is associated with preeminent real estate investment management 
             companies that allow access to non-traded REITs and other real estate 
             funds that enable real estate investors to further diversify their portfolio.<br/><br/>
             Lilypads also offers the opportunity to invest directly in institutional-quality
             properties with an individual investment thesis and return profile. This approach
             is highly beneficial for investors to engage in the diversification of REITs with 
             low minimums and diverse deal flows while enjoying the perks of transparency and 
             control of direct real estate investment.
            </ul>
          ),
        },
        {
          title: 'What does Lilypads offer',
          text: (
            <ul>
            Investors on Lilypads can expect an assorted mix of direct, fund and tax-deferred 
            commercial real estate investments across a wide range of asset types and locations 
            that include a broad variety of risk/return profiles. Lilypads’ in-house professionals 
            are highly proficient in asset and investment management and constantly updates the 
            Lilypads website with the latest opportunities emerging in the commercial real estate 
            market that allows tax deferred investments. Although tax-deferred offerings generally 
            require a higher minimum investment amount that can be daunting for regular 
            non- accredited investors, our Investment team makes investing in tax-deferred opportunities 
            seem like a cakewalk.
            </ul>
          ),
        },
        {
          title: 'How does Lilypads collect its data?',
          text: (
            <ul>
              The Lilypads system utilize advanced Artificial Intelligence and Machine learning 
              (AI/ML) algorithms to uncover complex data patterns from disparate sources of specific
              asset classes and provide property intelligence and smart property insights to 
              commercial real estate investors thereby matching them to suitable assets based on 
              their historical investment patterns and risk tolerance.
            </ul>
          ),
        },
        {
          title: 'Who can I get in touch with in Lilypads regarding investments?',
          text: (
            <ul>
             You can mail us at lvf@lilypads.com.
            </ul>
          ),
        },
      ]

    return(
       <>
         <section>
           <Container style={{marginTop:80}}>  
              <Row className="justify-content-center">
                 <Col lg={10}>
                   <Row className="align-items-center">
                      <Col lg={8}>
                          <p className="subtitle">FAQ’s</p>
                          <h1 className="small">Have any questions?</h1>
                      </Col>
                      <Col lg={4}>
                          {/* <p className="font-lexend">
                          Lilypads utilizes data-driven insights and Machine Learning
                          algorithms to streamline your property search and investment opportunities
                         </p> */}
                      </Col>
                   </Row>
                 </Col>
              </Row>
           </Container>
         </section>
         <Container>
         <Accordion defaultActiveKey="0" className="DiscoverAccordion">
                    {FDTList.map((item, i) => {
                      return (
                        <Accordion.Item key={i} eventKey={`${i}`} className="DiscoverAccordionitems">
                          <Accordion.Header className="DiscoverAccordionheading">
                            {item.title}
                          </Accordion.Header>
                          <Accordion.Body className="">
                            {item.text}
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    })}
                  </Accordion>
                  </Container>
       </>
    );
}
import { Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import Slider from 'react-slick'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../../Components/Slider/Slider'

const JoinCommunity = [
  {
    text:
      "Whether you want to join forces to meet minimum ticket or make sure you don't let that follow-on allocation go to waste, syndicate lets you pool capital from our network ",
    Img: "https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/join-community1.png",
  },
  {
    text:
      'Search and filter the real-time database of the sponsor, broker, and capital profiles constantly updated to reflect investment/deal target',
    Img: "https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/join-community4.png",
  },
  {
    text:
      "Let the right people know about your investments and properties you are interested in. With Lilypads' feed, you can privately tell Investors/owners or publicly share with the Lilypads community investment opportunities/ funds you're starting and many more",
    Img: "https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/join-community2.png",
  },
  // {
  //   text: "Connect with people who can help you invest, advertise your assets, sponsor or even provide you with a credit line",
  //   Img: JoinCommunity4,
  // },
  // {
  //   text: "Join Investors, Lenders, and Owners of millions of real estate properties on Lilypads",
  //   Img: JoinCommunity5,
  // },
]

export default function LPRInvestmentJoinCommunity() {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <>
      <section className="join-community space-top space-bottom">
        <Container>
          <div className="d-none d-md-block">
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row className="justify-content-between align-items-center">
                <Col xl={5} md={6}>
                  <Row className="justify-content-end">
                    <Col xl={11}>
                      <p className="subtitle">Join the Community</p>
                      <h2 className="mb-3">Connect to our Lilypads Network</h2>
                      <Nav className="flex-column custom-v-tabs">
                        {JoinCommunity.map((item, i) => {
                          return (
                            <Nav.Link
                              key={i}
                              as="div"
                              className="custom-tab-link font-lexend small fw-700 mb-0"
                              eventKey={i}
                            >
                              {item.text}
                            </Nav.Link>
                          )
                        })}
                      </Nav>
                    </Col>
                  </Row>
                </Col>
                <Col lg={5} md={6}>
                  <div className="custom-tab-img">
                    <Tab.Content>
                      {JoinCommunity.map((item, i) => {
                        return (
                          <Tab.Pane key={i} eventKey={i}>
                            <Image
                              fluid
                              className="drop-shadow-purple"
                              src={item.Img}
                            />
                          </Tab.Pane>
                        )
                      })}
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div className="d-block d-md-none">
            <p className="subtitle">Join the Community</p>
            <h2 className="mb-5">Connect to our Lilypads network</h2>
            <Slider {...settings} className="custom-slider arrows-center">
              {JoinCommunity.map((item, i) => {
                return (
                  <div className="" key={i}>
                    <div className="custom-tab-img mb-5">
                      <Image
                        fluid
                        className="drop-shadow-purple mx-auto"
                        src={item.Img}
                      />
                    </div>
                    <p className="font-lexend small fw-700 mb-0 text-center">
                      {item.text}
                    </p>
                  </div>
                )
              })}
            </Slider>
          </div>
        </Container>
      </section>
    </>
  )
}

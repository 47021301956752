import LPNetworkBecomeAnInvestor from './LPNetworkBecomeAnInvestor/LPNetworkBecomeAnInvestor'
import LPNetworkCommunity from './LPNetworkCommunity/LPNetworkCommunity'
import LPNetworkConnectNetwork from './LPNetworkConnectNetwork/LPNetworkConnectNetwork'
import LPNetworkHero from './LPNetworkHero/LPNetworkHero'
import LPNetworkOurCommunity from './LPNetworkOurCommunity/LPNetworkOurCommunity'
import LPNetworkPlatform from './LPNetworkPlatform/LPNetworkPlatform'
import './LPNetwork.css'
import Helmet from 'react-helmet'
import LPNetworkCommunityMob from './LPNetworkCommunity/LPNetworkCommunityMob'
import LPRInvestmentJoinCommunity from './../LPInvestors/LPRetailInvestors/LPRInvestmentJoinCommunity/LPRInvestmentJoinCommunity'

export default function LPNetwork() {
  return (
    <>
      <Helmet>
        <title>Lilypads | Networks</title>
      </Helmet>
      <LPNetworkHero />
      <LPNetworkPlatform />
      <LPNetworkOurCommunity />

      <div className="d-none d-md-block">
        <LPNetworkCommunity />
      </div>
      <div className="d-block d-md-none">
        <LPNetworkCommunityMob />
      </div>
      {/* <LPNetworkConnectNetwork /> */}
      <LPRInvestmentJoinCommunity />
      <LPNetworkBecomeAnInvestor />
    </>
  )
}

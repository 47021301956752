import { Container, Row, Col, Image } from "react-bootstrap";

export default function LPLendersHero() {
    return (
        <>
            <section className="inner-hero space-bottom">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10}>
                            <Row className="align-items-center space-bottom">
                                <Col lg={6}>
                                    <h1 className="small">
                                        Lilypads for <span className="color-maroon">Lenders</span>
                                    </h1>
                                </Col>
                                <Col lg={6}>
                                    <p className="font-lexend">Whether you are an Investor or a Lender, gaining visibility into your portfolio is easier within the Lilypads ecosystem that streamlines your decision making and automates closing</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={10}>
                                    <div className="text-center">
                                        <div className="lenders-hero">
                                            <Image className="theme-shadow lenders-hero-main scale-img scale-transition" fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/lenders/hero1.png" />
                                            <Image className="theme-shadow lenders-hero-center scale-img scale-transition" fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/lenders/hero2.png" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
import React, { useState, useEffect } from 'react'
import imgO from './../../assets/images/Explore/one.png'
import two from './../../assets/images/Explore/two.png'
import three from './../../assets/images/Explore/three.png'
import four from './../../assets/images/Explore/four.png'
import dangerICON from './../../assets/images/Explore/dangerICON.png'
import './LPExploreOffering.scss'
import ReactTooltip from 'react-tooltip'

const LPExploreOffering = () => {
  const [data, setData] = useState([
    {
      imgO: imgO,
      insideImgText: 'Comming Soon',
      Name: 'Sunrise Apartment',
      Address: '2464 Royal Ln. Mesa, New Jersey 45463',
      yieldD: '10%',
      ltv: '70%',
      HoldPeriod: '12 mo',
      Amount: '$90,000',
      MinimumInvestment: '$1,000',
      RedemptionPeriod: '12 mo',
      Strategy: 'Fixed',
      Area: '1,000',
      AreaType: 'Duplex',
      AppraisalValue: '$700,000',
      ValuePerSF: '$126',
    },
    {
      imgO: two,
      insideImgText: 'Comming Soon',
      Name: 'Bauhaus Archive',
      Address: '7246 woodland road,Wakuesha,WI 53186',
      yieldD: '11%',
      ltv: '70%',
      HoldPeriod: '12 mo',
      Amount: '$122,000',
      MinimumInvestment: '$5,000',
      RedemptionPeriod: '9 mo',
      Strategy: 'Fixed',
      Area: '1200',
      AreaType: 'Duplex',
      AppraisalValue: '$500,000',
      ValuePerSF: '$156',
    },
    {
      imgO: three,
      insideImgText: 'Comming Soon',
      Name: 'Chrysler Building',
      Address: '7202 Snake Hill Court, Bethel Park,PA 15102',
      yieldD: '9%',
      ltv: '70%',
      HoldPeriod: '12 mo',
      Amount: '$402,000',
      MinimumInvestment: '$5,000',
      RedemptionPeriod: '12 mo',
      Strategy: 'Fixed',
      Area: '1500',
      AreaType: 'Duplex',
      AppraisalValue: '$670,000',
      ValuePerSF: '$196',
    },
    {
      imgO: four,
      insideImgText: 'Comming Soon',
      Name: 'Falling Water',
      Address: '329 Squak creek Road,Greensburg, PA 15601',
      yieldD: '11%',
      ltv: '70%',
      HoldPeriod: '12 mo',
      Amount: '$756,780',
      MinimumInvestment: '$4,000',
      RedemptionPeriod: '9 mo',
      Strategy: 'Fixed',
      Area: '4800',
      AreaType: 'Duplex',
      AppraisalValue: '$500,000',
      ValuePerSF: '$159',
    },
  ])
  const [data2, setData2] = useState([{ name: 'a' }, { name: 'b' }])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [tootlTipHolder, setTootlTipHolder] = useState('')
  //filter data on change of search text
  useEffect(() => {
    const result = data.filter((item) => {
      return item.Name.toLowerCase().includes(searchText.toLowerCase())
    })
    setFilteredData(result)
    setTootlTipHolder(
      data2.map((item) => {
        return <ReactTooltip />
      }),
    )
  }, [searchText])
  return (
    <div className="LPExploreOfferingMain">
      {/* <ReactTooltip /> */}
      {tootlTipHolder}
      <div className="searchBox">
        <input
          type="text"
          placeholder="Search for properties..."
          onChange={(e) => {
            setSearchText(e.target.value)
          }}
        />
        <div className="svgCon">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6881 20.6159C12.9653 20.6155 15.1769 19.8531 16.9707 18.4503L22.6105 24.0901L24.4246 22.2761L18.7847 16.6363C20.1883 14.8423 20.9511 12.6302 20.9516 10.3524C20.9516 4.69335 16.3472 0.0888672 10.6881 0.0888672C5.02904 0.0888672 0.424561 4.69335 0.424561 10.3524C0.424561 16.0115 5.02904 20.6159 10.6881 20.6159ZM10.6881 2.65475C14.9334 2.65475 18.3858 6.10715 18.3858 10.3524C18.3858 14.5977 14.9334 18.0501 10.6881 18.0501C6.44284 18.0501 2.99044 14.5977 2.99044 10.3524C2.99044 6.10715 6.44284 2.65475 10.6881 2.65475Z"
              fill="#7C5AC7"
            />
            <path
              d="M12.4994 8.53847C12.9856 9.02599 13.2537 9.67002 13.2537 10.3525H15.8196C15.8208 9.67827 15.6882 9.01045 15.4297 8.3877C15.1712 7.76495 14.7918 7.19962 14.3134 6.72439C12.3711 4.78458 9.00335 4.78458 7.06226 6.72439L8.87377 8.54103C9.84881 7.56856 11.5295 7.57113 12.4994 8.53847Z"
              fill="#7C5AC7"
            />
          </svg>
        </div>
      </div>
      <div className="LPOfferringCardHolder">
        {filteredData.map((item, index) => {
          return (
            <LPOffereingComponent
              key={index}
              imgO={item.imgO}
              insideImgText={item.insideImgText}
              Name={item.Name}
              Address={item.Address}
              yieldD={item.yieldD}
              ltv={item.ltv}
              HoldPeriod={item.HoldPeriod}
              Amount={item.Amount}
              MinimumInvestment={item.MinimumInvestment}
              RedemptionPeriod={item.RedemptionPeriod}
              Strategy={item.Strategy}
              Area={item.Area}
              AreaType={item.AreaType}
              AppraisalValue={item.AppraisalValue}
              ValuePerSF={item.ValuePerSF}
            />
          )
        })}
      </div>
    </div>
  )
}

export default LPExploreOffering

function LPOffereingComponent({
  imgO,
  insideImgText,
  Name,
  Address,
  yieldD,
  ltv,
  HoldPeriod,
  Amount,
  MinimumInvestment,
  RedemptionPeriod,
  Strategy,
  Area,
  AreaType,
  AppraisalValue,
  ValuePerSF,
}) {
  return (
    <div className="LPOfferringCard">
      <div data-tip="data only for representation" className="dangerIcon">
        <div className="topHover">
          <p>data only for representation</p>
        </div>
        <img src={dangerICON} alt="" />
      </div>
      <div className="exploreICon">
        <div className="hoverCard">{insideImgText}</div>
        <img src={imgO} alt="" />
      </div>
      <div className="headText">
        <h1>{Name}</h1>
        <div className="locationCon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00016 13.9995C7.15812 13.2813 6.37762 12.4939 5.66683 11.6455C4.60016 10.3715 3.3335 8.47421 3.3335 6.66621C3.33255 4.77791 4.46966 3.07514 6.21421 2.35248C7.95876 1.62981 9.96687 2.02971 11.3015 3.36555C12.1791 4.23928 12.6708 5.4278 12.6669 6.66621C12.6669 8.47421 11.4002 10.3715 10.3335 11.6455C9.62271 12.4939 8.84221 13.2813 8.00016 13.9995ZM8.00016 3.33288C6.16013 3.33508 4.66903 4.82618 4.66683 6.66621C4.66683 7.44355 5.01816 8.78955 6.69016 10.7902C7.10226 11.2822 7.53951 11.7527 8.00016 12.1995C8.46086 11.7532 8.89832 11.2835 9.31083 10.7922C10.9822 8.78888 11.3335 7.44288 11.3335 6.66621C11.3313 4.82618 9.8402 3.33508 8.00016 3.33288ZM8.00016 8.66621C6.89559 8.66621 6.00016 7.77078 6.00016 6.66621C6.00016 5.56164 6.89559 4.66621 8.00016 4.66621C9.10473 4.66621 10.0002 5.56164 10.0002 6.66621C10.0002 7.19664 9.78945 7.70535 9.41438 8.08043C9.0393 8.4555 8.5306 8.66621 8.00016 8.66621Z"
              fill="#7C5AC7"
              fill-opacity="0.5"
            />
          </svg>
          <p>{Address}</p>
        </div>
      </div>
      <div className="lineExp">
        <div className="expBox">
          <h3>Yield</h3>
          <h4>{yieldD}</h4>
        </div>
        <div className="expBox ">
          <h3>LTV</h3>
          <h4>{ltv}</h4>
        </div>
        <div className="expBox">
          <h3>Hold Period</h3>
          <h4>{HoldPeriod}</h4>
        </div>
        <div className="expBox">
          <h3>Amount</h3>
          <h4>{Amount}</h4>
        </div>
      </div>
      <div className="lineExp two">
        <div className="expBox">
          <h3>Minimum Investment</h3>
          <h4>{MinimumInvestment}</h4>
        </div>
        <div className="expBox">
          <h3>Redemption Period</h3>
          <h4>{RedemptionPeriod}</h4>
        </div>
        <div className="expBox">
          <h3>Strategy</h3>
          <h4>{Strategy}</h4>
        </div>
      </div>
      <div className="lineExp three">
        <div className="expBox">
          <h3>Area, SF</h3>
          <h4>{Area}</h4>
          <h3>{AreaType}</h3>
        </div>
        <div className="expBox">
          <h3>Appraisal Value</h3>
          <h4>{AppraisalValue}</h4>
          <h3>from 12/26/2019</h3>
        </div>
        <div className="expBox">
          <h3>Value Per SF</h3>
          <h4>{ValuePerSF}</h4>
          {/* <h3>Yield</h3> */}
        </div>
      </div>
      <div className="expButton">
        <a href='/contact'>INVEST</a>
      </div>
    </div>
  )
}

import { Col, Container, Row, Image, Button } from 'react-bootstrap'

export default function LPIInvestorManageAccount() {
  const ManageAccountList = [
    {
      icon: "https://lilypads.s3.us-east-2.amazonaws.com/images/icons/invest-management.svg",
      title: 'Investment Management',
      text:
        'Investors can access a summary of their investment positions, and their invested and distributed capital 24/7 in real-time',
    },
    {
      icon: "https://lilypads.s3.us-east-2.amazonaws.com/images/icons/streamline.svg",
      title: 'Streamline Your Day-to-Day',
      text:
        'Communicate with prospective investors and track their degree of interest. Manage investor commitments & get notified',
    },
    {
      icon: "https://lilypads.s3.us-east-2.amazonaws.com/images/icons/pie-chart.svg",
      title: 'Unlock Portfolios',
      text:
        'Directly connect to understanding ownership portfolios. Simply click an owner’s name to reveal all associated properties',
    },
    {
      icon: "https://lilypads.s3.us-east-2.amazonaws.com/images/icons/secure.svg",
      title: 'Securely Exchange Documents',
      text:
        'Secure deal rooms, in custom groupings, or en masse. All communications are tracked automatically for easy reference',
    },
  ]
  return (
    <>
      <section className="space-top space-bottom manage-account">
        <Container>
          <Row className="g-5">
            <Col lg={6}>
              <h2 className="mb-2">
                <span className="color-maroon">Manage</span> your account
              </h2>
              <p className="font-lexend fw-400 mb-5">
                Professionally manage relationships and interactions with your
                investors within a robust Deal Management system—from capturing
                leads to gathering interest in new offerings, to collecting
                capital.
              </p>
              <Row className="g-5">
                {ManageAccountList.map((item, i) => {
                  return (
                    <Col md={6} key={i}>
                      <div className="d-flex mb-3 align-items-center">
                        <div className="circle-icon light-maroon me-4">
                          <Image src={item.icon} />
                        </div>
                        <p className="font-lexend fw-600">{item.title}</p>
                      </div>
                      <p className="font-lexend fw-400 mb-0">{item.text}</p>
                    </Col>
                  )
                })}
              </Row>
              <div className="text-center text-md-start">
                <a href="/join-waiting-list">
                  <Button
                    variant="none"
                    className="btn-bordered-purple bold fw-600 mt-5"
                  >
                    Become a Partner
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <Image
          fluid
          src="https://lilypads.s3.us-east-2.amazonaws.com/images/institutional-investor/manage-account-img.png"
          className="manage-account-img img-reset-mob mt-5 mt-lg-0"
        />
      </section>
    </>
  )
}

import React from 'react'
import comOne from '../../assets/images/newLanding/comOne.png'
import comTwo from '../../assets/images/newLanding/comTwo.png'
import comThree from '../../assets/images/newLanding/comThree.png'
import comFour from '../../assets/images/newLanding/comFour.png'
import comFive from '../../assets/images/newLanding/comFive.png'
import comSix from '../../assets/images/newLanding/comSix.png'

const LPNSecSix = () => {
  return (
    <div className="LPNSecSix">
      <h1>Our Partners</h1>
      <div className="LPNSecSixCon">
        <div className="LPNSecSix-Line">
          <div className="compContainer">
            <div className="gradLine left V "></div>
            <div className="gradLine right V "></div>
            <div className="gradLineH top  "></div>
            <div className="gradLineH btm  "></div>
            <img src={comTwo} alt="" />
          </div>
          <div className="compContainer">
            <div className="gradLine left V "></div>
            <div className="gradLine right V "></div>
            <div className="gradLineH top  "></div>
            <div className="gradLineH btm  "></div>
            <img src={comOne} alt="" />
          </div>
          <div className="compContainer">
            <div className="gradLine left V "></div>
            <div className="gradLine right V "></div>
            <div className="gradLineH top  "></div>
            <div className="gradLineH btm  "></div>
            <img src={comThree} alt="" />
          </div>{' '}
          <div className="compContainer">
            <div className="gradLine left V "></div>
            <div className="gradLine right V "></div>
            <div className="gradLineH top  "></div>
            <div className="gradLineH btm  "></div>
            <img src={comFour} alt="" />
          </div>
          <div className="compContainer ">
            <div className="gradLine left V "></div>
            <div className="gradLine right V "></div>
            <div className="gradLineH top  "></div>
            <div className="gradLineH btm  "></div>
            <img src={comFive} alt="" />
          </div>
          <div className="compContainer">
            <div className="gradLine left V "></div>
            <div className="gradLine right V "></div>
            <div className="gradLineH top  "></div>
            <div className="gradLineH btm  "></div>
            <img src={comSix} alt="" />
          </div>
        </div>
        <div className="spacer60mt"></div>
        <div className="LPNSecSix-Line"></div>
      </div>
    </div>
  )
}

export default LPNSecSix

import { useEffect, useLayoutEffect, useRef } from "react";
import Slider from "react-slick";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "./LPTechAL.css";
import LPSVGIcons from "../../Components/LPSVGIcons/LPSVGIcons";
import CityskylinerImg from "./../../assets/images/ai/cityskyline-banner.png";
import CityskylinerImgTab from "./../../assets/images/ai/cityskyline-banner-tab.png";
import CityskylinerImgMob from "./../../assets/images/ai/cityskyline-banner-mob.png";
import WorldWide from "./../../assets/images/ai/worldwide.svg";
import Tax from "./../../assets/images/ai/tax.png";
import House from "./../../assets/images/ai/house.svg";
import SmartCity from "./../../assets/images/ai/smart-city.svg";
import Stats from "./../../assets/images/ai/stats.svg";
import Wallet from "./../../assets/images/ai/wallet.svg";
import Worker from "./../../assets/images/ai/worker.svg";
import AI from "./../../assets/images/ai/ai.svg";
import Investor1 from "./../../assets/images/ai/investor1.png";
import Investor2 from "./../../assets/images/ai/investor2.png";
import Investor3 from "./../../assets/images/ai/investor3.png";
import Investor4 from "./../../assets/images/ai/investor4.png";
import Investor5 from "./../../assets/images/ai/investor5.png";
import Investor6 from "./../../assets/images/ai/investor6.png";
import InvestorBlock1 from "./../../assets/images/ai/invest-block-1.svg";
import InvestorBlock2 from "./../../assets/images/ai/invest-block-2.svg";
import InvestorBlock3 from "./../../assets/images/ai/invest-block-3.svg";
import InvestorBlock4 from "./../../assets/images/ai/invest-block-4.svg";
import InvestorBlock5 from "./../../assets/images/ai/invest-block-5.svg";
import InvestorBlock6 from "./../../assets/images/ai/invest-block-6.svg";

const WhyInvestList = [
  {
    title: "Machine Learning",
    text: "By combining disparate sources of data from across the commercial real asset industry, Lilypads' algorithms provide property intelligence and match investors to specific assets based on your past transactions and risk preferences",
  },
  {
    title: "Proprietary Algorithms",
    text: "Based on your portfolio performance, risk preferences, and specific characteristics, we provide you with intelligent insights so you can find top-notch assets that are best suited to your portfolio.",
  },
  {
    title: "Data Network",
    text: "We go beyond just capturing real asset data to prescribe insights for roof replacement, HVAC system maintenance, budgeting, and a host of other operations necessary to amplify and grow an asset portfolio.",
  },
];
export default function LPTechAI() {
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, ScrollToPlugin);
  const pageRef = useRef(null);
  const tl = useRef(gsap.timeline({ paused: false }));
  const blockTl = useRef(gsap.timeline({ paused: false }));
  const aiCats = useRef([]);

  //clear timeout if page changes
  // return () => {
  //   clearTimeout()
  // }

  useEffect(() => {
    // window.location.reload()
    setTimeout(() => {
      sessionStorage.setItem("page", "elsewhere");
    }, 6000);

    const page = sessionStorage.getItem("page");
    if (page === "ai") {
    } else {
      sessionStorage.setItem("page", "ai");
      window.location.reload();
    }
  }, []);

  useLayoutEffect(() => {
    const el = gsap.utils.selector(pageRef.current);

    // MAIN TIMELINE
    tl.current
      .from(el(".cityskyline-text"), {
        x: "-10%",
        opacity: 0,
        duration: 1,
        ease: "power1.out",
      })
      .from(el(".img-cityskyliner"), { opacity: 0, duration: 1.5 })
      .from(aiCats.current, {
        stagger: 0.3,
        y: "100%",
        opacity: 0,
        duration: 2,
        ease: "power3.out",
      });
    // MAIN TIMELINE END

    // BLOCK TIMELINE
    blockTl.current
      .to(
        el(
          ".ai-block-line, #block2rev, #block3rev, #block4rev, #block5rev,#block6rev, #block8rev"
        ),
        {
          autoAlpha: 0,
        }
      )
      .to(el("#block1"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath1",
          align: "#blockpath1",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block2"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath2",
          align: "#blockpath2",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block3"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath3",
          align: "#blockpath3",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block4"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath4",
          align: "#blockpath4",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block5"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath5",
          align: "#blockpath5",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block6"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath6",
          align: "#blockpath6",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block7"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath7",
          align: "#blockpath7",
          alignOrigin: [0.5, 0.5],
        },
      })
      .to(el("#block8"), {
        transformOrigin: "50% 50%",
        motionPath: {
          path: "#blockpath8",
          align: "#blockpath8",
          alignOrigin: [0.5, 0.5],
        },
      });

    ScrollTrigger.create({
      animation: blockTl.current,
      trigger: el("#block-trigger"),
      start: "center center",
      end: "+=100%",
      pin: true,
      scrub: true,
    });
    // BLOCK TIMELINE END
  }, []);
  const Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 755,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Lilypads | Technology AI</title>
      </Helmet>
      <main className="ai-page" ref={pageRef}>
        <section className="inner-hero overflow-hidden">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row className="align-items-center">
                  <Col lg={6}>
                    <div className="cityskyline-text">
                      <p className="subtitle">
                        Technology {LPSVGIcons.AngleArrow} AI
                      </p>
                      <h1 className="small mb-3">Artifical Intelligence</h1>
                      <p className="font-urbanist mb-5">
                        We are utilizing AI to bring the raw, invisible CRE data
                        to our users that will modernize agents, make brokers
                        more tactical, and empower clients
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="cityskyliner space-top d-none d-lg-block">
            <div
              className="cityskyliner-cat cityskyliner-cat-1"
              ref={(element) => {
                aiCats.current[0] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">
                  DEMOGRAPHIC
                  <br />
                  DATA
                </p>
                <div className="cityskyliner-icon">
                  <Image src={WorldWide} />
                </div>
              </div>
            </div>
            <div
              className="cityskyliner-cat cityskyliner-cat-2"
              ref={(element) => {
                aiCats.current[1] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">TAX DATA</p>
                <div className="cityskyliner-icon">
                  <Image src={Tax} />
                </div>
              </div>
            </div>
            <div
              className="cityskyliner-cat cityskyliner-cat-3"
              ref={(element) => {
                aiCats.current[2] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">
                  US CENSUS <br /> DATA
                </p>
                <div className="cityskyliner-icon">
                  <Image src={House} />
                </div>
              </div>
            </div>
            <div
              className="cityskyliner-cat cityskyliner-cat-4"
              ref={(element) => {
                aiCats.current[3] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">
                  FINANCIAL <br /> DATA
                </p>
                <div className="cityskyliner-icon">
                  <Image src={Stats} />
                </div>
              </div>
            </div>
            <div
              className="cityskyliner-cat cityskyliner-cat-5"
              ref={(element) => {
                aiCats.current[4] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">
                  INCOME & <br /> EMPLOYMENT
                </p>
                <div className="cityskyliner-icon">
                  <Image src={Worker} />
                </div>
              </div>
            </div>
            <div
              className="cityskyliner-cat cityskyliner-cat-6"
              ref={(element) => {
                aiCats.current[5] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">
                  TITLE <br /> COMPANIES
                </p>
                <div className="cityskyliner-icon">
                  <Image src={SmartCity} />
                </div>
              </div>
            </div>
            <div
              className="cityskyliner-cat cityskyliner-cat-7"
              ref={(element) => {
                aiCats.current[6] = element;
              }}
            >
              <div className="cityskyliner-cat-inner">
                <p className="small">
                  Consumer <br /> Spend
                </p>
                <div className="cityskyliner-icon">
                  <Image src={Wallet} />
                </div>
              </div>
            </div>
            <Image
              className="w-100 img-cityskyliner"
              fluid
              src={CityskylinerImg}
            />
          </div>
        </section>
        <section className="d-none d-md-block d-lg-none">
          <Image
            className="w-100 img-cityskyliner "
            fluid
            src={CityskylinerImgTab}
          />
        </section>
        <section className="d-block d-md-none">
          <Image
            className="w-100 img-cityskyliner img-mob-cityskyliner "
            fluid
            src={CityskylinerImgMob}
          />
        </section>
        <section className="ai-block-section">
          <div
            className="ai-block-line ai-block-line-1"
            id="ai-block-line-1"
          ></div>
          <div
            className="ai-block-line ai-block-line-1-0 ai-block-line-1-1"
            id="ai-block-line-1-1"
          ></div>
          <div
            className="ai-block-line ai-block-line-2"
            id="ai-block-line-2"
          ></div>
          <div
            className="ai-block-line ai-block-line-1-0 ai-block-line-2-2"
            id="ai-block-line-2-2"
          ></div>
          <div
            className="ai-block-line ai-block-line-3"
            id="ai-block-line-3"
          ></div>
          <div
            className="ai-block-line ai-block-line-1-0  ai-block-line-3-3"
            id="ai-block-line-3-3"
          ></div>
          <div
            className="ai-block-line ai-block-line-4"
            id="ai-block-line-4"
          ></div>
          <div
            className="ai-block-line ai-block-line-1-0 ai-block-line-4-4"
            id="ai-block-line-4-4"
          ></div>
          <div
            className="ai-block-line ai-block-line-5"
            id="ai-block-line-5"
          ></div>
          <div
            className="ai-block-line ai-block-line-1-0 ai-block-line-5-5"
            id="ai-block-line-5-5"
          ></div>
          <div
            className="ai-block-line ai-block-line-6"
            id="ai-block-line-6"
          ></div>
          <div
            className="ai-block-line ai-block-line-1-0 ai-block-line-6-6"
            id="ai-block-line-6-6"
          ></div>
          <div id="block-trigger">
            <div className="ai-block-wrap">{LPSVGIcons.AIBlocks2}</div>
            <div className="ai-algorithm-content top-to">
              <p className="big">Data Cleaning</p>
              <p className="small mb-0">
                Our AI algorithms collate data from different sources and
                removes noise from the same- this gives us better structured
                blocks of data, which are processed further using our data
                processing systems
              </p>
            </div>
          </div>
          {/* <div className="ai-algorithm-content top-to">
            <p className="big">Data Cleaning</p>
            <p className="small mb-0">
              Vestibulum mi venenatis proin elit. Ac lacus enim egestas augue
              aliquam. A cum accumsan eu aliquet sagittis consequat a faucibus
              massa.
            </p>
          </div> */}
        </section>
        <section className="ai-algorithm text-center">
          <div className="ai-algorithm-content top">
            <p className="big">Lilypads AI Algorithm</p>
            <p className="small mb-0">
              Using our ingenious algorithms, we process the tagged and
              structured data and generate a recommendation engine for every
              real estate asset
            </p>
          </div>
          <div className="d-inline-block position-relative">
            <div>
              <div className="pulse-circle-sm pulse-rate pulse-delay-2"></div>
              <div className="pulse-circle-md pulse-rate pulse-delay-2-25"></div>
              <div className="pulse-circle-lg pulse-rate pulse-delay-2-50"></div>
            </div>
            <Image className="img-ai" fluid src={AI} />
          </div>
          <div className="invest-card-main d-none d-md-block">
            <Container>
              <div className="invest-card-wrapper">
                <div className="invest-card invest-left invest-card-1">
                  <Image
                    className="invest-card-block-img"
                    fluid
                    src={InvestorBlock1}
                  />
                  <Image className="invest-card-img" fluid src={Investor1} />
                  <p className="invest-text">Invest</p>
                </div>
                <div className="invest-card invest-left invest-card-2">
                  <Image
                    className="invest-card-block-img"
                    fluid
                    src={InvestorBlock2}
                  />
                  <Image className="invest-card-img" fluid src={Investor2} />
                  <p className="invest-text">Invest</p>
                </div>
                <div className="invest-card invest-left invest-card-3">
                  <Image
                    className="invest-card-block-img"
                    fluid
                    src={InvestorBlock3}
                  />
                  <Image className="invest-card-img" fluid src={Investor3} />
                  <p className="invest-text">Invest</p>
                </div>
                <div className="invest-card invest-right invest-card-4">
                  <Image
                    className="invest-card-block-img"
                    fluid
                    src={InvestorBlock4}
                  />
                  <Image className="invest-card-img" fluid src={Investor4} />
                  <p className="invest-text">Invest</p>
                </div>
                <div className="invest-card invest-right invest-card-5">
                  <Image
                    className="invest-card-block-img"
                    fluid
                    src={InvestorBlock5}
                  />
                  <Image className="invest-card-img" fluid src={Investor5} />
                  <p className="invest-text">Invest</p>
                </div>
                <div className="invest-card invest-right invest-card-6">
                  <Image
                    className="invest-card-block-img"
                    fluid
                    src={InvestorBlock6}
                  />
                  <Image className="invest-card-img" fluid src={Investor6} />
                  <p className="invest-text">Invest</p>
                </div>
              </div>
            </Container>
          </div>
          <div className="invest-card-main position-static space-small-top space-small-bottom d-block d-md-none">
            <Container>
              <Slider {...Settings} className="invest-card-slider">
                <div className="card-item">
                  <div className="invest-card">
                    <Image
                      className="invest-card-block-img"
                      fluid
                      src={InvestorBlock1}
                    />
                    <Image className="invest-card-img" fluid src={Investor1} />
                    <p className="invest-text">Invest</p>
                  </div>
                </div>
                <div className="card-item">
                  <div className="invest-card invest-right">
                    <Image
                      className="invest-card-block-img"
                      fluid
                      src={InvestorBlock2}
                    />
                    <Image className="invest-card-img" fluid src={Investor2} />
                    <p className="invest-text">Invest</p>
                  </div>
                </div>
                <div className="card-item">
                  <div className="invest-card">
                    <Image
                      className="invest-card-block-img"
                      fluid
                      src={InvestorBlock3}
                    />
                    <Image className="invest-card-img" fluid src={Investor3} />
                    <p className="invest-text">Invest</p>
                  </div>
                </div>
                <div className="card-item">
                  <div className="invest-card invest-right">
                    <Image
                      className="invest-card-block-img"
                      fluid
                      src={InvestorBlock4}
                    />
                    <Image className="invest-card-img" fluid src={Investor4} />
                    <p className="invest-text">Invest</p>
                  </div>
                </div>
                <div className="card-item">
                  <div className="invest-card">
                    <Image
                      className="invest-card-block-img"
                      fluid
                      src={InvestorBlock5}
                    />
                    <Image className="invest-card-img" fluid src={Investor5} />
                    <p className="invest-text">Invest</p>
                  </div>
                </div>
                <div className="card-item">
                  <div className="invest-card invest-right">
                    <Image
                      className="invest-card-block-img"
                      fluid
                      src={InvestorBlock6}
                    />
                    <Image className="invest-card-img" fluid src={Investor6} />
                    <p className="invest-text">Invest</p>
                  </div>
                </div>
              </Slider>
            </Container>
          </div>
          <div className="ai-algorithm-content end">
            <p className="big">Profile Based Opportunities</p>
            <p className="small mb-0">
              Our recommendation engine provides customized profile based assets
              for investments
            </p>
          </div>
        </section>
        <section className="space-top space-bottom bg-light technological-superiority">
          <Container>
            <Row className="justify-content-center space-bottom">
              <Col lg={10}>
                <Row className="align-items-center">
                  <Col lg={6}>
                    <p className="subtitle">Our technological superiority</p>
                    <h2 className="">How Lilypad AI works ?</h2>
                  </Col>
                  <Col lg={6}>
                    <p className="fw-400">
                      Our Lilypads AI is a game-changer that recognizes complex
                      data patterns, the best underwriting practices and
                      activity pattern for a specific asset activity patterns
                      and identifies the owner of records which could impact
                      asset value both before and after the purchase
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="g-5">
              {WhyInvestList.map((item, i) => {
                return (
                  <Col
                    lg={4}
                    md={6}
                    key={i}
                    className="mb-5 mt-5 mb-lg-0 mt-lg-0"
                  >
                    <div className="superiority-card space-top">
                      <div className="bordered-text">0{i + 1}</div>
                      <p className="big mb-3">{item.title}</p>
                      <p className="mb-0 fw-400 small">{item.text}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

import { Col, Container, Image, Row } from 'react-bootstrap'

export default function LPStoryOurPrinciples() {
  const OurPrinciplesList = [
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/business-partnership.svg',
      title: 'Give People a Voice',
      text:
        'People deserve to be heard and have their rights addressed, even when our opinions differ',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/business-partnership.svg',
      title: 'Build Connection and Community',
      text:
        'We help connect people and bring them closer through their investments',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/conference.svg',
      title: 'Serve Everyone',
      text: ' We focus on making real estate investing accessible to anyone and everyone',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/shield.svg',
      title: 'Keep People Safe and Protect Privacy',
      text:
        'We are committed to our responsibility of keeping people safe and protecting their privacy',
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/bow.svg',
      title: 'Promote Economic Opportunity',
      text:
        'Our tools level the playing field so businesses create jobs to strengthen the community and the economy',
    },
  ]
  return (
    <>
      <section className="space-top space-bottom bg-light-maroon">
        <Container>
          <Row className="g-5">
            <Col lg={4} md={6}>
              <p className="subtitle">Our principles</p>
              <h2 className="mb-3">Our principles are what we live for</h2>
              <p className="fw-400">
                At Lilypads, we solve problems and strive continuously to
                connect Investors and assets all over the world
              </p>
            </Col>
            {OurPrinciplesList.map((item, i) => {
              return (
                <>
                  <Col lg={4} md={6} key={i}>
                    <div className="custom-card p-5 h-100 our-principle-card">
                      <span className="bordered-text maroon">0{i + 1}</span>
                      <div className="circle-icon w-80p mb-4 light-maroon">
                        <Image src={item.icon} />
                      </div>
                      <p className="font-lexend big fw-600">{item.title}</p>
                      <p className="font-lexend fw-400 small mb-0">
                        {item.text}
                      </p>
                    </div>
                  </Col>
                </>
              )
            })}
          </Row>
        </Container>
      </section>
    </>
  )
}

import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../../Components/LPSVGIcons/LPSVGIcons'

const BecomeInvestor = [
  {
    text:
      'Everyday new investment-grade properties are added to the marketplace, investors join the network and collective wisdom are shared throughout the platform.',
  },
  {
    text: 'Lilypads helps you turn an opportunity into transaction.',
  },
  {
    text:
      'Lilypads provide you with an ever-growing library of information provided by the best in class investment opportunities, techniques and real estate strategies for beginners as well as experts.',
  },
  {
    text:
      'Connect with the real estate investing world by becoming a part of the Lilypads platform.',
  },
]

export default function LPRInvestorBecome() {
  return (
    <>
      <section className="become-investor space-top space-bottom">
        <Container>
          <Row className="align-items-center g-5">
            <Col lg="6">
              <Row>
                <Col lg="2"></Col>
                <Col>
                  <p className="subtitle">Investing Made Better</p>
                  <h2 className="color-white mb-4">
                    Become an Investor on Lilypads
                  </h2>
                  {BecomeInvestor.map((item, i) => {
                    return (
                      <div className="d-flex">
                        <span className="me-3 d-inline-block">
                          {LPSVGIcons.DoubleCheck}
                        </span>
                        <p
                          key={i}
                          className="font-lexend mb-4 fw-400 color-white small"
                        >
                          {item.text}
                        </p>
                      </div>
                    )
                  })}
                  <a href="/join-waiting-list">
                    <Button
                      variant="none"
                      className="bold btn-white fw-600 mt-4"
                    >
                      Become an Investor
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row>
                <Col lg="1"></Col>
                <Col className="text-center">
                  <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/become-investor.png" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

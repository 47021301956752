import { Container, Row, Col, Image } from 'react-bootstrap'
import './LPStoryOurTeam.scss'
import React, { useState } from 'react'
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7L16 7"
          stroke="#7C5AC7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5 1L16.5 7L11.5 13.5"
          stroke="#7C5AC7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 7L2 7"
          stroke="#7C5AC7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.5 1L1.5 7L6.5 13.5"
          stroke="#7C5AC7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

const members = [
  {
    img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/story/member1.png',
    fName: 'Thomas',
    lName: 'Lah',
    designation: 'Founder, Chairman, & CEO',
    sign: 'Thomas Lah',
    desc:
      'Thomas P. Lah directs investment strategy, and decisions, governance and investor relations. ecological responsibility and diversity and inclusion for the prosperity of all.',
    facebook: 'https://facebook.com',
    linkedin: 'https://linkedin.com',
    twitter: 'https://twitter.com',
  },
  {
    img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/story/member2.png',
    fName: 'Thomas',
    lName: 'Lah',
    designation: 'Founder, Chairman, & CEO',
    sign: 'Thomas Lah',
    desc:
      'Thomas P. Lah directs investment strategy, and decisions, governance and investor relations. ecological responsibility and diversity and inclusion for the prosperity of all.',
    facebook: 'https://facebook.com',
    linkedin: 'https://linkedin.com',
    twitter: 'https://twitter.com',
  },
  {
    img: 'https://lilypads.s3.us-east-2.amazonaws.com/images/story/member3.png',
    fName: 'Thomas',
    lName: 'Lah',
    designation: 'Founder, Chairman, & CEO',
    sign: 'Thomas Lah',
    desc:
      'Thomas P. Lah directs investment strategy, and decisions, governance and investor relations. ecological responsibility and diversity and inclusion for the prosperity of all.',
    facebook: 'https://facebook.com',
    linkedin: 'https://linkedin.com',
    twitter: 'https://twitter.com',
  },
]

export default function LPStoryOurTeam() {
  const settings = {
    customPaging: function (i) {
      return <Image fluid src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member1.png' />
    },
    fade: true,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const [index, setIndex] = useState(1)

  const handleAdd = (e) => {
    e.preventDefault()
    if (index >= 3) {
      if (index === 4 || index === 5) {
        return
      }
      setIndex(4)
      const timer = setTimeout(() => setIndex(5), 500)
      const timer2 = setTimeout(() => setIndex(1), 610)
      return () => clearTimeout(timer, timer2)
    }
    setIndex(index + 1)
  }
  const handleSub = (e) => {
    e.preventDefault()
    if (index <= 1) {
      if (index === 0 || index === 6) {
        return
      }
      setIndex(0)
      const timer = setTimeout(() => setIndex(6), 500)
      const timer2 = setTimeout(() => setIndex(3), 610)
      return () => clearTimeout(timer, timer2)
    }
    setIndex(index - 1)
  }

  return (
    <>
      <section className="space-top space-bottom">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle font-lexend">Our team</p>
                  <Row className="align-items-center">
                    <Col lg={10}>
                      <h2 className="">Team behind the success stories</h2>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <p className="fw-400">
                    We believe in nurturing the spirit of collaboration to
                    achieve relentless innovations and growth in our operations
                  </p>
                </Col>
              </Row>
              <div className="mteamSlider">
                <div className="mTS-L">
                  <div className="mTs-L-Top">
                    {index === 0 || index === 3 || index === 6 ? (
                      <>
                        <div className="tcon">
                          <h1>Karthik Krishnan</h1>
                          <h2>CO FOUNDER & EVP</h2>
                        </div>
                        <img src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/kkS.png' alt="" />
                      </>
                    ) : index === 1 || index === 5 || index === 4 ? (
                      <>
                        <div className="tcon">
                          <h1>Thomas Lah</h1>
                          <h2>Founder, Chairman, & CEO</h2>
                        </div>
                        <img src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/sig1.svg' alt="" />
                      </>
                    ) : (
                      <>
                        <div className="tcon">
                          <h1>Joe Copsey</h1>
                          <h2>CO FOUNDER & COO</h2>
                        </div>
                        <img src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/jcS.png' alt="" />
                      </>
                    )}
                  </div>
                  <div className="mTs-L-BTM">
                    <div className="tcon">
                      {index === 0 || index === 3 || index === 6 ? (
                        <>
                          <h3>
                            Karthik Krishnan is the co-founder and executive
                            vice president of the Lilypads Platform.
                          </h3>
                          <div className="sCon">
                            <a
                              href="https://www.linkedin.com/in/kkrishnan93/"
                              target="_blank"
                            >
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.22917 5.2084C7.22889 5.76093 7.00913 6.29073 6.61824 6.68123C6.22734 7.07174 5.69733 7.29097 5.14479 7.29069C4.59226 7.29041 4.06246 7.07066 3.67196 6.67976C3.28145 6.28886 3.06222 5.75885 3.0625 5.20632C3.06278 4.65378 3.28253 4.12399 3.67343 3.73348C4.06433 3.34298 4.59434 3.12375 5.14688 3.12402C5.69941 3.1243 6.2292 3.34406 6.61971 3.73495C7.01022 4.12585 7.22944 4.65586 7.22917 5.2084ZM7.29167 8.8334H3.125L3.125 21.8751H7.29167L7.29167 8.8334ZM13.875 8.8334L9.72917 8.8334L9.72917 21.8751H13.8333L13.8333 15.0313C13.8333 11.2188 18.8021 10.8646 18.8021 15.0313L18.8021 21.8751H22.9167L22.9167 13.6146C22.9167 7.18756 15.5625 7.42715 13.8333 10.5834L13.875 8.8334Z"
                                  fill="#7C5AC7"
                                />
                              </svg>
                            </a>
                          </div>
                        </>
                      ) : index === 1 || index === 5 || index === 4 ? (
                        <>
                          <h3>
                            Thomas P. Lah directs investment strategy, and
                            decisions, governance and investor relations.
                            Ecological responsibility and diversity and
                            inclusion for the prosperity of all
                          </h3>
                          <div className="sCon">
                            <a
                              href="https://www.linkedin.com/in/thomas-p-lah/"
                              target="_blank"
                            >
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.22917 5.2084C7.22889 5.76093 7.00913 6.29073 6.61824 6.68123C6.22734 7.07174 5.69733 7.29097 5.14479 7.29069C4.59226 7.29041 4.06246 7.07066 3.67196 6.67976C3.28145 6.28886 3.06222 5.75885 3.0625 5.20632C3.06278 4.65378 3.28253 4.12399 3.67343 3.73348C4.06433 3.34298 4.59434 3.12375 5.14688 3.12402C5.69941 3.1243 6.2292 3.34406 6.61971 3.73495C7.01022 4.12585 7.22944 4.65586 7.22917 5.2084ZM7.29167 8.8334H3.125L3.125 21.8751H7.29167L7.29167 8.8334ZM13.875 8.8334L9.72917 8.8334L9.72917 21.8751H13.8333L13.8333 15.0313C13.8333 11.2188 18.8021 10.8646 18.8021 15.0313L18.8021 21.8751H22.9167L22.9167 13.6146C22.9167 7.18756 15.5625 7.42715 13.8333 10.5834L13.875 8.8334Z"
                                  fill="#7C5AC7"
                                />
                              </svg>
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <h3>
                            Meet our culture agent and steward obsessed with
                            creating efficiencies and great experiences for our
                            clients and business partners
                          </h3>
                          <div className="sCon">
                            <a
                              href="https://www.linkedin.com/in/joecopsey/"
                              target="_blank"
                            >
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.22917 5.2084C7.22889 5.76093 7.00913 6.29073 6.61824 6.68123C6.22734 7.07174 5.69733 7.29097 5.14479 7.29069C4.59226 7.29041 4.06246 7.07066 3.67196 6.67976C3.28145 6.28886 3.06222 5.75885 3.0625 5.20632C3.06278 4.65378 3.28253 4.12399 3.67343 3.73348C4.06433 3.34298 4.59434 3.12375 5.14688 3.12402C5.69941 3.1243 6.2292 3.34406 6.61971 3.73495C7.01022 4.12585 7.22944 4.65586 7.22917 5.2084ZM7.29167 8.8334H3.125L3.125 21.8751H7.29167L7.29167 8.8334ZM13.875 8.8334L9.72917 8.8334L9.72917 21.8751H13.8333L13.8333 15.0313C13.8333 11.2188 18.8021 10.8646 18.8021 15.0313L18.8021 21.8751H22.9167L22.9167 13.6146C22.9167 7.18756 15.5625 7.42715 13.8333 10.5834L13.875 8.8334Z"
                                  fill="#7C5AC7"
                                />
                              </svg>
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mTS-R">
                  <div className="tMainSlideCon">
                    <div className="tconD">
                      {/* <h4>Team Members {index}</h4> */}
                      <h4>Team Members </h4>
                      <div className="btnCOn">
                        <button onClick={handleSub}>
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17 7L2 7"
                              stroke="#7C5AC7"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.5 1L1.5 7L6.5 13.5"
                              stroke="#7C5AC7"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        <button onClick={handleAdd}>
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 7L16 7"
                              stroke="#7C5AC7"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.5 1L16.5 7L11.5 13.5"
                              stroke="#7C5AC7"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div
                      className={
                        'sliderIMGMCON ' +
                        (index === 5 && ' BOA ') +
                        (index === 0 && ' ZE ') +
                        (index === 6 && ' SIX ') +
                        (index === 1 && ' OA ') +
                        (index === 2 && ' TA ') +
                        (index === 3 && ' THA ') +
                        (index === 4 && ' IMGR ')
                      }
                    >
                      <div
                        onClick={() => {
                          setIndex(2)
                        }}
                        className={'sliderItemOwn  Fimg Fth'}
                      >
                        <img className="sliderImg" src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member3.png}' alt="" />
                      </div>
                      <div
                        onClick={() => {
                          setIndex(2)
                        }}
                        className={'sliderItemOwn  Fimg Ftwo'}
                      >
                        <img className="sliderImg" src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member2.png' alt="" />
                      </div>
                      <div
                        onClick={() => {
                          setIndex(1)
                        }}
                        className={'sliderItemOwn Fimg Fone '}
                      >
                        <img className="sliderImg" src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member1.png' alt="" />
                      </div>
                      <div
                        onClick={() => {
                          setIndex(3)
                        }}
                        className={
                          'sliderItemOwn imgTh ' + (index === 1 && ' activee ')
                        }
                      >
                        <img className="sliderImg" src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member3.png' alt="" />
                      </div>
                      <div
                        onClick={() => {
                          setIndex(2)
                        }}
                        className={
                          'sliderItemOwn imgT ' + (index === 2 && ' activee ')
                        }
                      >
                        <img className="sliderImg" src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member2.png' alt="" />
                      </div>
                      <div
                        onClick={() => {
                          setIndex(1)
                        }}
                        className={
                          'sliderItemOwn imgO  ' + (index === 3 && ' activee ')
                        }
                      >
                        <img className="sliderImg" src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member1.png' alt="" />
                      </div>
                      <div
                        onClick={() => {
                          setIndex(1)
                        }}
                        className={'sliderItemOwn  Ize '}
                      >
                        <img className="sliderImg " src='https://lilypads.s3.us-east-2.amazonaws.com/images/story/member3.png' alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

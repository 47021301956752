import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import 'slick-carousel/slick/slick.css'
import './LPContactUS.css'
import LPSVGIcons from '../../Components/LPSVGIcons/LPSVGIcons'
import { Link } from 'react-router-dom'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
export default function LPContactUS() {
  const followUsSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6.1,
    slidesToScroll: 1,
    mobileFirst: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5.1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4.2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 2.5,
        },
      },
    ],
  }

  const [BrokerCB, setBrokerCB] = useState(false)
  const [investmentCB, setInvestmentCB] = useState(false)
  const [lenderCB, setLenderCB] = useState(false)
  const [VentureFundCB, setVentureFundCB] = useState(false)
  const [DealbyDayCB, setDealbyDayCB] = useState(false)
  const [partnershipCB, setPartnershipCB] = useState(false)
  const [otherCB, setOtherCB] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [instituitonCB, setInstituitonCB] = useState(false)
  const handleSUBMIT = (e) => {
    e.preventDefault()
    var role =
      (BrokerCB ? 'Broker,' : '') +
      (investmentCB ? 'Retail Investor,' : '') +
      (lenderCB ? ' Lender' : '') +
      (instituitonCB ? ' Institution' : '')
    console.log('====================================')
    console.log(role)
    console.log('====================================')

    const config = {
      headers: {
        'X-Api-Key': 'FWwKVWXl.9wYfvH87RqYpfZCCp32IiraFjfQ1uVUf',
      },
    }
    const body = new FormData()
    body.append('first_name', firstName)
    body.append('last_name', lastName)
    body.append('email', email)
    body.append('role', role)
    body.append('company', phone)
    body.append('message', message)

    console.log(body)

    const url = `https://api.cogniertechnology.com/api/waitlist/`

    axios
      .post(url, body, config)
      .then((res) => {
        console.log(res)
        // alert('Thank you for subscribing to our newsletter')
        // alert('Thank you for joining our waiting list')
        alert('We will get back to you soon')
        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error)
        alert('Something went wrong')
        // alert('Failed toget Details')
      })
  }

  return (
    <>
      <section className="inner-hero space-bottom contact-us-hero">
        <Container>
          <Row className="justify-content-between align-items-center g-5">
            <Col lg={6}>
              <Image src="https://lilypads.s3.us-east-2.amazonaws.com/images/calendly/contact.jpg" fluid className="" />
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
              <p className="subtitle">Contact Us</p>
              <h1 className="small mb-5">Get In Touch</h1>
              <Form onSubmit={handleSUBMIT}>
                <Row className="gx-5 gy-4">
                  <Col sm={6}>
                    <Form.Group className="" controlId="FName">
                      <Form.Control
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        type="text"
                        placeholder="First Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="" controlId="LName">
                      <Form.Control
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        type="text"
                        placeholder="Last Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="" controlId="Email">
                      <Form.Control
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        placeholder="Email Address"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="" controlId="PNo">
                      <Form.Control
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        type="text"
                        placeholder="Company"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group
                      className="position-relative icon-input-wrap"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <div className="input-icon">
                        {LPSVGIcons.MailAttachIcon}
                      </div>
                      <Form.Control
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        as="textarea"
                        placeholder="Drop your query..."
                        rows={5}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <p className="small mb-3 color-gray">
                      What would you relate to the most ?
                    </p>
                    <label htmlFor="broker" className="contact-chip">
                      <input
                        onClick={() => setBrokerCB(!BrokerCB)}
                        type="checkbox"
                        name="relate"
                        id="broker"
                      />
                      <span>Broker</span>
                    </label>
                    <label htmlFor="investment" className="contact-chip">
                      <input
                        onClick={() => setInvestmentCB(!investmentCB)}
                        type="checkbox"
                        name="relate"
                        id="investment"
                      />
                      <span>Retail Investor</span>
                    </label>
                    {/* <label htmlFor="Lender" className="contact-chip">
                      <input
                        onClick={() => setLenderCB(!lenderCB)}
                        type="checkbox"
                        name="relate"
                        id="Lender"
                      />
                      <span>Lender</span>
                    </label> */}
                    <label htmlFor="Institution" className="contact-chip">
                      <input
                        onClick={() => setInstituitonCB(!instituitonCB)}
                        type="checkbox"
                        name="relate"
                        id="Institution"
                      />
                      <span>Institution</span>
                    </label>
                    {/* <label htmlFor="VentureFund" className="contact-chip">
                      <input
                        onClick={() => setVentureFundCB(!VentureFundCB)}
                        type="checkbox"
                        name="relate"
                        id="VentureFund"
                      />
                      <span>Venture Fund</span>
                    </label>
                    <label htmlFor="Dealbydeal" className="contact-chip">
                      <input
                        onClick={() => setDealbyDayCB(!DealbyDayCB)}
                        type="checkbox"
                        name="relate"
                        id="Dealbydeal"
                      />
                      <span>Deal by deal</span>
                    </label>
                    <label htmlFor="Partnerships" className="contact-chip">
                      <input
                        onClick={() => setPartnershipCB(!partnershipCB)}
                        type="checkbox"
                        name="relate"
                        id="Partnerships"
                      />
                      <span>Partnerships</span>
                    </label>
                    <label htmlFor="Others" className="contact-chip">
                      <input
                        onClick={() => setOtherCB(!otherCB)}
                        type="checkbox"
                        name="relate"
                        id="Others"
                      />
                      <span>Others</span>
                    </label> */}
                  </Col>
                  <Col sm={12}>
                    <Button
                      type="submit"
                      variant="none"
                      className="btn-purple w-100"
                    >
                      Get in touch
                    </Button>
                  </Col>
                  <Col sm={12}>
                    <p className="mb-0 contact-devider or text-center color-gray">
                      <span>OR</span>
                    </p>
                  </Col>
                  <Col sm={12}>
                    <Link
                      to={'/schedule'}
                      className="btn btn-bordered-purple w-100 d-flex align-items-center justify-content-center"
                    >
                      <span className="d-inline-block me-3">
                        {LPSVGIcons.CalendlyIcon}
                      </span>
                      Schedule meeting with calendly
                    </Link>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </section>
      {/* <section className="follow-us-section">
        <Container>
          <div className="follow-us-slider">
            <p className="small fw-600">Follow Us</p>
            <Slider {...followUsSettings}>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="follow-card-wrap">
                <div className="follow-card">
                  <Image
                    src={Building}
                    alt="building"
                    className="img-cover"
                    fluid
                  />
                  <div className="follow-icon">
                    <a href="https://www.instagram.com">
                      <Instagram size="24" color="#969797" />
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
      </section> */}
    </>
  )
}

import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
export default function LPBrokersStreamline() {
  const Streamline = [
    {
      text:
        'Full visibility of everything that’s going on with your properties',
    },
    {
      text:
        'Better access to insights. Keep track of leads and marketing data right from the app',
    },
    {
      text: 'Stay connected with buyers via logged communications',
    },
    {
      text: 'Automated contracting and closing, right where you want it',
    },
  ]
  return (
    <>
      <section className="space-top broker-streamline">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="g-5">
                <Col md={6} className="space-bottom">
                  <p className="subtitle">Streamline Every Aspect</p>
                  <h2 className="mb-4">
                    All your properties and deals in one place
                  </h2>
                  {Streamline.map((item, i) => {
                    return (
                      <div className="d-flex">
                        <span className="me-3 d-inline-block">
                          {LPSVGIcons.DoubleCheck}
                        </span>
                        <p key={i} className="font-lexend mb-4 fw-400 small">
                          {item.text}
                        </p>
                      </div>
                    )
                  })}
                  <div className="text-center text-md-start">
                    <a href="/join-waiting-list">
                      <Button
                        variant="none"
                        className="bold btn-purple fw-600 mt-4"
                      >
                        Become a Broker
                      </Button>
                    </a>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="streamline-img">
                    <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/streamlines.png" className="" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import { Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPNetworkPlatform() {
  return (
    <>
      <section className="investment-platform space-top bg-light-maroon">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10">
              <Row className="align-items-center">
                <Col md="6">
                  <div className="space-bottom">
                    <h2 className="">Lilypads Investment Platform</h2>
                    <p className="font-lexend mb-5 fw-400">
                      The marketplace is flourishing every day with new
                      investment-grade properties, investors, and resources.
                      Lilypads helps you convert an opportunity into a potential
                      investment
                    </p>
                    <div className="social-list mt-0 align-items-center justify-content-start">
                      <a
                        href="https://www.apple.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {LPSVGIcons.Apple}
                      </a>
                      <a
                        href="https://play.google.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {LPSVGIcons.GooglePlay}
                      </a>
                      <span className="color-purple opacity-50 fw-600 font-lexend">
                        Coming soon
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="investment-platform-img text-md-end text-center">
                    <Image fluid src='https://lilypads.s3.us-east-2.amazonaws.com/images/network/investment-platform.png' />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

import React, { useEffect, useState } from "react";
import "./LPBlog.scss";
import axios from "axios";
import moment from "moment";
import Loader from "./../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import LPSVGIcons from "../../Components/LPSVGIcons/LPSVGIcons";
import ReactPaginate from "react-paginate";
import { saveToLocal } from "../../Components/AllFunctions";

const LPBolg = () => {
  const [allblog, setAllBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(15);
  const [fliteringBlogs, setFliteringBlogs] = useState([]);
  const [searchT, setSearchT] = useState("");
  const [filterdBlogs, setFilterdBlogs] = useState([]);

  const [mode, setMode] = useState("1");
  const [allTags, setAllTags] = useState([]);
  useEffect(() => {
    const url = `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed`;
    async function handleSearchD() {
      // console.log('search started for ' + searchT)
      // const res = await axios.get(url)
      // console.log(fliteringBlogs)

      let result = fliteringBlogs.filter((user) => {
        return (
          user.title.rendered.toLowerCase().indexOf(searchT.toLowerCase()) >= 0
        );
      });

      if (mode === 1) {
        setFilterdBlogs(
          result.map((name, i) => {
            //check if image is present
            const tagsDATA = [];
            let datePub = name.date;
            let imgT = name?._embedded["wp:featuredmedia"][0]?.source_url;
            let datePubFINAL = moment(datePub).fromNow();
            let dmtagd = name._embedded["wp:term"].map((tags, i) => {
              // console.log('====================================')
              let subtag = tags.map((subtag, i) => {
                tagsDATA.push(subtag.name);
                // console.log(subtag.name)
              });
              // if (!tagsDATA.includes(searchT)) {
              //   return
              // }
            });

            let img = name.featured_media;
            let splicedLink = name.link.replace(
              "https://business.lilypads.com/",
              ""
            );
            if (img === 0 || img === null) {
              return (
                <BLOGINDI
                  id={name.id}
                  link={splicedLink}
                  index={i}
                  img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              );
            }
            return (
              <BLOGINDI
                id={name.id}
                link={splicedLink}
                index={i}
                // img={name.better_featured_image.source_url}
                img={imgT}
                title={name.title.rendered}
                content={name.excerpt.rendered}
                pub={datePubFINAL}
                author={name._embedded.author[0].name}
                tags={tagsDATA}
              />
            );
          })
        );
      }

      if (mode === 2) {
        setFilterdBlogs(
          fliteringBlogs.map((name, i) => {
            //check if image is present
            const tagsDATA = [];
            let datePub = name.date;
            let imgT =
              "https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg";
            // if (!name?._embedded['wp:featuredmedia'][0] === null) {
            // }
            // check if img present
            //get length of wp featured media
            // let imgLength = name?._embedded['wp:featuredmedia'].length
            // if (imgLength > 0) {
            if (name?._embedded["wp:featuredmedia"]) {
              imgT = name?._embedded["wp:featuredmedia"][0]?.source_url;
            }

            // imgT = name?._embedded['wp:featuredmedia'][0]?.source_url
            let datePubFINAL = moment(datePub).fromNow();
            let dmtagd = name._embedded["wp:term"].map((tags, i) => {
              // console.log('====================================')
              let subtag = tags.map((subtag, i) => {
                tagsDATA.push(subtag.name);
                // console.log(subtag.name)
              });
              // if (!tagsDATA.includes(searchT)) {
              //   return
              // }
            });

            if (tagsDATA.includes(searchT)) {
              let img = name.featured_media;
              let splicedLink = name.link.replace(
                "https://business.lilypads.com/",
                ""
              );
              if (img === 0 || img === null) {
                return (
                  <BLOGINDI
                    id={name.id}
                    link={splicedLink}
                    index={i}
                    img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                    title={name.title.rendered}
                    content={name.excerpt.rendered}
                    pub={datePubFINAL}
                    author={name._embedded.author[0].name}
                    tags={tagsDATA}
                  />
                );
              }
              return (
                <BLOGINDI
                  id={name.id}
                  link={splicedLink}
                  index={i}
                  // img={name.better_featured_image.source_url}
                  img={imgT}
                  // img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"

                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              );
            }
          })
        );
      }

      console.log(result);
      console.log(mode);
    }
    handleSearchD();
  }, [searchT]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // SaveAllPostToLocal

    axios
      .get(
        "https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed"
      )
      .then((res) => {
        console.log("FILTERED BLOGS");
        console.log(res);
        setFliteringBlogs(res.data);

        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error);

        // alert('Failed toget Details')
      });

    axios
      .get("https://business.lilypads.com/wp-json/wp/v2/categories")
      .then((res) => {
        console.log(res);
        let totalItems = 0;

        let tData = [];
        const atags = res.data.map((tag, i) => {
          tData.push(tag.name);
        });
        setAllTags(tData);
        let mapr = res.data.map((cat, i) => {
          totalItems = cat.count + totalItems;
        });
        console.log(totalItems);
        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error);

        // alert('Failed toget Details')
      });
    // setLoading(false)
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    axios
      // .get('https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100')
      .get(
        `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=6&page=${pageNumber}&_embed`
      )
      // .get('https://business.lilypads.com/wp-json/wp/v2/categories')
      .then((res) => {
        //getthe header
        console.log("======sasas==============================");
        // console.log();
        console.log(res);
        console.log("====================================");
        console.log(res.headers["x-wp-totalpages"]);
        setPageCount(res.headers["x-wp-totalpages"]);
        setLoading(false);
        setAllBlog(
          res.data.map((name, i) => {
            let datePub = name.date;
            let datePubFINAL = moment(datePub).fromNow();
            const tagsDATA = [];
            let imgT = name._embedded["wp:featuredmedia"]?.[0]?.source_url;
            let dmtagd = name._embedded["wp:term"].map((tags, i) => {
              // console.log('====================================')
              let subtag = tags.map((subtag, i) => {
                tagsDATA.push(subtag.name);
                // console.log(subtag.name)
              });
            });

            //check if image is present
            let img = name.featured_media;
            let splicedLink = name.link.replace(
              "https://business.lilypads.com/",
              ""
            );
            if (img === 0 || img === null) {
              return (
                <BLOGINDI
                  link={splicedLink}
                  id={name.id}
                  index={i}
                  img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              );
            }
            return (
              <BLOGINDI
                link={splicedLink}
                id={name.id}
                index={i}
                // img={name.better_featured_image.source_url}
                img={imgT}
                title={name.title.rendered}
                content={name.excerpt.rendered}
                pub={datePubFINAL}
                author={name._embedded.author[0].name}
                tags={tagsDATA}
              />
            );
          })
        );
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        // alert('Failed toget Details')
      });
  }, [pageNumber]);

  const handlePageClick = (data) => {
    let cd = parseInt(data.selected);
    setPageNumber(cd + 1);

    // console.log(data.selected + 1 *10)
    console.log(cd + 1);
  };

  return (
    <>
      <Helmet>
        <title>Lilypads | Blogs</title>
      </Helmet>
      <div className="mlc">
        {loading ? (
          <Loader />
        ) : (
          <div className="LPBolg-main">
            <Container>
              <p className="subtitle mb-5">
                Knowledge center {LPSVGIcons.AngleArrow} Blogs
              </p>
              <Row className="g-5">
                <Col lg={7}>
                  <div className="form-search">
                    <span className="icon-search">{LPSVGIcons.Search}</span>
                    <Form.Control
                      value={searchT}
                      onChange={(e) => {
                        setMode(1);
                        setSearchT(e.target.value);
                      }}
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                </Col>
                <Col lg={5}>
                  <Row className="g-5">
                    <Col sm={6}>
                      <Dropdown className="blog-dropdown">
                        <Dropdown.Toggle variant="none" id="dropdown-tags">
                          Select Tags
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {allTags.map((tag, i) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  setMode(2);
                                  setSearchT(tag);
                                }}
                              >
                                {tag}
                              </Dropdown.Item>
                            );
                          })}

                          {/* <Dropdown.Item href="#/action-1">Tag 1</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Tag 2</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Tag 3</Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col sm={6}>
                      <Dropdown className="blog-dropdown">
                        <Dropdown.Toggle variant="none" id="dropdown-category">
                          Select Category
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {allTags.map((tag, i) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  setMode(2);
                                  setSearchT(tag);
                                }}
                              >
                                {tag}
                              </Dropdown.Item>
                            );
                          })}
                          {/* <Dropdown.Item href="#/action-1">
                            Category 1
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Category 2
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Category 3
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>

            {searchT === "" ? (
              <>
                <div className="BlogCon">{allblog}</div>
                <ReactPaginate
                  onPageChange={handlePageClick}
                  containerClassName={"paginationOwn"}
                  previousLinkClassName={"pprevlcc"}
                  //  pageRangeDisplayed={5}
                  // renderOnZeroPageCount={null}
                  forcePage={pageNumber - 1}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  breakLabel="..."
                  nextLabel=" >>"
                  previousLabel="<<"
                />
              </>
            ) : (
              <>
                <div className="BlogCon">{filterdBlogs}</div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default LPBolg;

const BlogClick = (e) => {
  e.preventDefault();
  alert("clicked");
  console.log("====================================");
  console.log(e);
  console.log("====================================");
};

function BLOGINDI({ title, img, content, index, pub, author, tags, id, link }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        // history.push('/NewsDetails')
        console.log(link);
        // navigate('/blog')
        navigate(`/${link}`);
      }}
      className="solo-BLOGCON"
    >
      <img src={img} alt="" />
      <div className="BLOG-Tags">
        {tags.map((tag, i) => {
          return <p>{tag}</p>;
        })}

        {/* <p className="font-lexend">Real estate{tags}</p>
        <p className="font-lexend">Investment</p>
        <p className="font-lexend">Commercial</p> */}
      </div>
      <p className="tp">{title}</p>

      <div
        className="BLOG-content"
        dangerouslySetInnerHTML={{
          __html: `${content}`,
        }}
      ></div>
      <div className="Blog-BOTM">
        <p className="lp">{author} </p>
        <p className="rp">{pub} </p>
      </div>
    </div>
  );
}

import LPJoinWaitingListHero from "./LPJoinWaitingListHero/LPJoinWaitingListHero";
import "./LPJoinWaitingList.css";
import LPJoinWaitingLisJoin from "./LPJoinWaitingLisJoin/LPJoinWaitingLisJoin";
import LPJoinWaitingListNow from "./LPJoinWaitingListNow/LPJoinWaitingListNow"

export default function LPJoinWaitingList() {
    return (
        <>
            <div className="join-waiting-list-bg">
                <LPJoinWaitingListHero />
                <LPJoinWaitingLisJoin />
                <LPJoinWaitingListNow />
            </div>
        </>
    )
}
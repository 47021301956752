import { Container, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
import collectingcapital from '../../../assets/images/Manage/zxcvhb,.png'

export default function LPManageSection1(){
    return(
        <>
         <div className='discover2ndbg'>
           
         <section className="space-bottom">
        <Container style={{marginTop:100}}>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle">
                    Product {LPSVGIcons.AngleArrow} Manage
                  </p>
                  <h1 className="small">Your Complete Real Estate</h1>
                </Col>
                <Col lg={6}>
                  <p className="managepara">
                  Professionally manage relationships and interactions with your investors within a robust deal management system—from capturing leads and gathering interest in new offerings to collecting capital.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </section>
        <div className='collectingcapital'>
        <img className='collectingcapitalimg' src={collectingcapital}/>
        </div>
        </div>
        </>
    );
}
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import LPSVGIcons from '../../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPIInvestorBestDiscover() {
  const BecomeInvestor = [
    {
      text:
        'You can discover and research assets quicker with Lilypads platform.',
    },
    {
      text:
        'Intelligence assets matching algorithm helps you win more business and invest intelligently.',
    },
    {
      text:
        'Our database contains every source you will need to network and invest.',
    },
    {
      text:
        'Close deals faster, get a warm introduction from those with a close relationship.',
    },
  ]
  return (
    <>
      <section className="space-top space-bottom bg-light institutional-investors-discover">
        <Container className="py-4">
          <Row>
            <Col lg="6">
              <Row>
                <Col lg="2"></Col>
                <Col>
                  <h2 className="mb-4">
                    <span className="color-maroon">Discover</span> the best real
                    estate near around you
                  </h2>
                  {BecomeInvestor.map((item, i) => {
                    return (
                      <div className="d-flex" key={i}>
                        <span className="me-3 d-inline-block">
                          {LPSVGIcons.DoubleCheck}
                        </span>
                        <p key={i} className="font-lexend mb-4 fw-400 small">
                          {item.text}
                        </p>
                      </div>
                    )
                  })}
                  <div className="text-center text-md-start">
                    <a href="/join-waiting-list">
                      <Button
                        variant="none"
                        className="bold btn-bordered-purple fw-600 mt-4"
                      >
                        Start Investing Now
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Image
          fluid
          src="https://lilypads.s3.us-east-2.amazonaws.com/images/institutional-investor/discover.png"
          className="institutional-investors-discover-img img-reset-mob"
        />
      </section>
    </>
  )
}

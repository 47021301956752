import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import Discoverone from '../../../assets/images/Discover/01 (12).png'
import Discovertwo from '../../../assets/images/Discover/02 (12).png'
import Discoverthree from '../../../assets/images/Discover/03 (10).png'
import DataOriented from '../../../assets/images/Discover/Group 20826.png'
import Algorithm from '../../../assets/images/Discover/Group 20825.png'
import Privacy from '../../../assets/images/Discover/Group 20824.png'

export default function LPDiscoverSection4(){
    return(
       <>
       
       <Row className="justify-content-center">
       <section>
        <Container style={{marginTop:80}}>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle">
                  What we emphasize
                  </p>
                  <h1 className="small">Our focus</h1>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend">
                  Lilypads utilizes data-driven insights and Machine Learning
                   algorithms to streamline your property search and investment opportunities
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </section>
             <Col lg={12}>
            <Container className='container '>
             <div className='WhatWeEmphasize Discoverresponsiveness'>
                 <div className='WhatWeEmphasizecard'>
                    <img className='WhatWeEmphasizecardNumber' src={Discoverone}/>
                    <img className='WhatWeEmphasizecardicon' src={DataOriented}/>
                    <Discoverperpectivecard
                             CardHeading='Data Oriented'
                        Cardparagraph='Lilypads allows you to understand full landscape you uncover within and discover new opppurtunity'
                             />
                 </div>
                 <div className='WhatWeEmphasizecard'>
                   <img className='WhatWeEmphasizecardNumber'  src={Discovertwo}/>
                   <img className='WhatWeEmphasizecardicon' src={Algorithm}/>
                   <Discoverperpectivecard
                             CardHeading='Algorithm'
                             Cardparagraph='Our machine learning algorithm will resolve your data so that single property means single record accessed to single source'
                             />
                 </div>
                 <div className='WhatWeEmphasizecard'>
                   <img className='WhatWeEmphasizecardNumber'  src={Discoverthree}/>
                   <img className='WhatWeEmphasizecardicon' src={Privacy}/>
                   <Discoverperpectivecard
                             CardHeading='Privacy'
                             Cardparagraph='Your data privacy and security matter to us.keep your competitive advantage your own'
                             />
                 </div>
             </div>
             </Container>
             </Col>
       </Row>
     
       </>
    );
}
import { Button, Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import Slider from 'react-slick'
import invest from './../../../../assets/images/Home/invest.png'
import track from './../../../../assets/images/Home/track.png'
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../../Components/Slider/Slider'

// HomeImg
const HowToInvest = [
  {
    title: 'Create an Account',
    text:
      'Complete your account and create your profile online, share your investment goals and preferences and connect with the global network of investors.',
    Img:
      'https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/how-to-invest1.jpg',
  },
  {
    title: 'Select your Investments',
    text:
      'Select suitable institutional grade commercial real estate assets teeming with resilience and lucrative opportunities in the best locations across the country.',
    Img:
      'https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/how-to-invest2.jpg',
  },
  {
    title: 'Invest in Minutes',
    text:
      'Create seamless investments by purchasing real estate shares using trust tokens or by directly importing funds to your digitrust wallet.',
    Img: invest,
  },
  {
    title: 'Track your investments',
    text:
      'Gain real-time visibility into your investments and track your investor holdings with continuous reporting',
    Img: track,
  },
]

export default function LPRInvestmentHow() {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <>
      <section className="how-to-invest space-top space-bottom">
        <Container>
          <div className="d-none d-md-block">
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row className="justify-content-between align-items-center g-5">
                <Col md={6}>
                  <Tab.Content>
                    {HowToInvest.map((item, i) => {
                      return (
                        <Tab.Pane key={i} eventKey={i}>
                          <Image fluid className="" src={item.Img} />
                        </Tab.Pane>
                      )
                    })}
                  </Tab.Content>
                </Col>
                <Col lg={5} md={6}>
                  <Row className="justify-content-end">
                    <Col xl={11}>
                      <h2 className="mb-3">
                        How to <span className="color-maroon">Invest?</span>
                      </h2>
                      <p className="font-lexend fw-400 mb-5">
                        Modern ways of investing in Commercial Real Estate. 4
                        easy steps for Investments
                      </p>
                      <Nav className="flex-column custom-v-tabs mb-5">
                        {HowToInvest.map((item, i) => {
                          return (
                            <Nav.Link
                              key={i}
                              as="div"
                              className="custom-tab-link font-lexend small fw-400 mb-0"
                              eventKey={i}
                            >
                              <p className="big fw-600">{item.title}</p>
                              <p className="small mb-0">{item.text}</p>
                            </Nav.Link>
                          )
                        })}
                      </Nav>
                      <a href="/join-waiting-list">
                        <Button
                          variant="none"
                          className="btn-purple btn-shadow"
                        >
                          Start Investing Now
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div className="d-block d-md-none">
            <h2 className="mb-3">
              How to <span className="color-maroon">Invest?</span>
            </h2>
            <p className="font-lexend fw-400 mb-5">
              Modern ways of investing in Commercial Real Estate. 4 easy steps
              for Investments
            </p>
            <Slider {...settings} className="custom-slider arrows-center">
              {HowToInvest.map((item, i) => {
                return (
                  <div className="" key={i}>
                    <Image fluid className="mb-5" src={item.Img} />
                    <div className="text-center">
                      <p className="big fw-600">{item.title}</p>
                      <p className="small mb-0">{item.text}</p>
                    </div>
                  </div>
                )
              })}
            </Slider>
            <div className="text-center text-md-start mt-5">
              <a href="/join-waiting-list">
                <Button variant="none" className="btn-purple btn-shadow">
                  Start Investing Now
                </Button>
              </a>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

{
  /* <Col xl={5} md={6}>
<Row className="justify-content-end">
  <Col xl={11}>
    <p className="subtitle">Join the Community</p>
    <h2 className="mb-3">Connect to our Lilypads Network</h2>
    <Nav className="flex-column custom-v-tabs">
      {JoinCommunity.map((item, i) => {
        return (
          <Nav.Link
            key={i}
            as="div"
            className="custom-tab-link font-lexend small fw-700 mb-0"
            eventKey={i}
          >
            {item.text}
          </Nav.Link>
        )
      })}
    </Nav>
  </Col>
</Row>
</Col>
<Col lg={5} md={6}>
<div className="custom-tab-img h-100 d-flex align-items-center justify-content-center">
  <Tab.Content>
    {JoinCommunity.map((item, i) => {
      return (
        <Tab.Pane key={i} eventKey={i}>
          <Image
            fluid
            className="drop-shadow-purple"
            src={item.Img}
          />
        </Tab.Pane>
      )
    })}
  </Tab.Content>
</div>
</Col> */
}

import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'

export default function LPNetworkHero() {
  return (
    <>
      <div className="inner-hero space-bottom network-hero">
        <section className="space-bottom">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row className="align-items-center">
                  <Col lg={6}>
                    <p className="subtitle">
                      Network {LPSVGIcons.AngleArrow} Community
                    </p>
                    <h1 className="small">Community</h1>
                  </Col>
                  <Col lg={6}>
                    <p className="font-lexend">
                      The Lilypads real estate investor network allows you to
                      expand your book of contacts to access opportunities and
                      grow your business
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="network-hero-sec">
          <Image
            fluid
            src='https://lilypads.s3.us-east-2.amazonaws.com/images/network/hero.png'
            className="network-hero-sec-img maroon-shadow img-reset-mob"
          />
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row className="align-items-center  g-5">
                  <Col lg={6}>
                    <Row className="">
                      <Col lg={9}>
                        <h2 className="mb-3">
                          Connecting Investors, Lenders, Brokers and Asset Managers
                        </h2>
                      </Col>
                    </Row>
                    <p className="font-lexend mb-5">
                      Browse our Investment community to know more about the
                      amazing people who work and invest in
                      institutional-quality projects
                    </p>
                    <div className="text-center text-md-start">
                      <a href="/join-waiting-list">
                        <Button
                          variant="none"
                          className="bold btn-purple fw-600"
                        >
                          Join the community now
                        </Button>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

import { Col, Container, Image, Row } from "react-bootstrap";

export default function LPLendersWayToManage() {
  const WayToManageList = [
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/analysis.svg',
      title: "Real-Time Risk Monitoring",
      text: "Stay one step ahead of any possible risk of losses in your portfolio using our leading security systems⁤",
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/reporting.svg',
      title: "Seamless Reporting",
      text: "Lilypads shortens the preview turnaround time of projects and loan applications with in-built analytics",
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/policies.svg',
      title: "Automated Policies and Procedures",
      text: "Eliminate the never-ending trail of paperwork and administration with our automated contracting and closing",
    },
    {
      icon: 'https://lilypads.s3.us-east-2.amazonaws.com/images/icons/doc-storage.svg',
      title: "Online Document Storage",
      text: "Store and access critical documents in a secure, centralized location that organizes and safeguards your investments",
    },
  ];
  return (
    <>
      <section className="space-top space-bottom way-to-manage">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center g-5 space-small-bottom">
                <Col lg={6}>
                  <Row className="align-items-center">
                    <Col lg={10}>
                      <p className="subtitle">Modernize Management</p>
                      <h2>Powering faster, smarter lending decisions</h2>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend fw-400 small">
                    At Lilypads, we see thousands of deals a year and we know it
                    takes time and effort for you to review deals and figure out
                    what you can offer. We do the work upfront to bring you just
                    the deals that you’ll be a great fit for, and have a shot at
                    winning.
                  </p>
                </Col>
              </Row>
              <Row className="g-5">
                {WayToManageList.map((item, i) => {
                  return (
                    <>
                      <Col md={6} key={i}>
                        <div className="gredient-bg p-5 h-100 way-to-manage-card">
                          <div className="way-to-manage-card-inner">
                            <div className="circle-icon w-80p mb-4">
                              <Image src={item.icon} />
                            </div>
                            <p className="font-lexend big fw-600">
                              {item.title}
                            </p>
                            <p className="font-lexend fw-400 small mb-0 space-small-bottom">
                              {item.text}
                            </p>
                          </div>
                          <span className="bordered-text">0{i + 1}</span>
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

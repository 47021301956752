import { useRef, useLayoutEffect, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import SCLily from "./../../assets/images/icons/lily-logo.png";
import Helmet from "react-helmet";
import LPSVGIcons from "../../Components/LPSVGIcons/LPSVGIcons";
import SecuritiesMob from "./../../assets/images/blockchain/footer-img.png";
import SmartContractBg from "./../../assets/images/blockchain/blockchain-pattern.png";
import PhoneBuilding1 from "./../../assets/images/blockchain/p-building-1.png";
import PhoneBuilding2 from "./../../assets/images/blockchain/p-building-2.png";
import PhoneBuilding3 from "./../../assets/images/blockchain/p-building-3.png";
import PhoneBuilding4 from "./../../assets/images/blockchain/p-building-4.png";
import PhoneBuilding5 from "./../../assets/images/blockchain/p-building-5.png";
import PhoneBuilding6 from "./../../assets/images/blockchain/p-building-6.png";
import "./LPBlockchain.css";

export default function LPBlockchain() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const pageRef = useRef(null);
  const tl = useRef(gsap.timeline({ paused: false }));
  const scTl = useRef(gsap.timeline({ paused: false }));
  const pbTl = useRef(gsap.timeline({ paused: false }));

  useEffect(() => {
    // window.location.reload()
    setTimeout(() => {
      sessionStorage.setItem("page", "elsewhere");
    }, 6000);

    const page = sessionStorage.getItem("page");
    if (page === "blockChain") {
    } else {
      sessionStorage.setItem("page", "blockChain");
      window.location.reload();
    }
  }, []);

  useLayoutEffect(() => {
    const el = gsap.utils.selector(pageRef.current);

    tl.current
      .from(el(".bc-text"), {
        y: "-10%",
        opacity: 0,
        duration: 1,
      })
      .from(el(".blockchain-cat-left"), {
        x: "-20%",
        opacity: 0,
        duration: 1,
      })
      .from(
        el(".blockchain-cat-right"),
        {
          x: "20%",
          opacity: 0,
          duration: 1,
        },
        "<"
      );

    // BANNER CIRCLE
    gsap.from(el(".smart-contract-content"), {
      opacity: 0,
      scale: 0.5,
      duration: 1,
      scrollTrigger: {
        trigger: el(".smart-contract-inner"),
        start: "center center",
        start: `+=${
          document.querySelector(".smart-contract-content").offsetHeight / 2
        } center`,
      },
    });
    // BANNER CIRCLE END

    // CONTRACT TIMELINE
    scTl.current
      .to(el(".sc-buildings"), {
        opacity: 0,
      })
      .to(el("#stack"), {
        xPercent: -30,
        yPercent: 14,
        scale: 0.85,
      })
      .to(
        el("#stack2"),
        {
          scale: 0.95,
        },
        "<"
      )
      .to(el("#sc-text-1"), {
        autoAlpha: 0,
        display: "none",
      })
      .to(el("#sc-text-2"), {
        display: "flex",
      })
      .to(el("#sc-text-2"), {
        autoAlpha: 1,
      })
      .to(
        el("#layer-top, #layer-top-2"),
        {
          xPercent: -75,
          yPercent: 10,
        },
        ">"
      )
      .to(
        el("#layer-mid, #layer-mid-2"),
        {
          xPercent: 75,
          yPercent: 90,
        },
        "<"
      )
      .to(el("#sc-text-2"), {
        autoAlpha: 0,
        display: "none",
      })
      .to(el("#sc-text-3"), {
        display: "flex",
      })
      .to(el("#sc-text-3"), {
        autoAlpha: 1,
      })
      .to(
        el(".sc-svg"),
        {
          yPercent: 10,
          autoAlpha: 0,
        },
        ">"
      )
      .to(
        el(".sc-svg"),
        {
          display: "none",
        },
        ">"
      )
      .to(el("#leftPlotSvg, #rightPlotSvg"), {
        display: "block",
      })
      .fromTo(
        el("#leftPlotSvg, #rightPlotSvg"),
        {
          yPercent: 100,
          autoAlpha: 0,
        },
        {
          yPercent: 0,
          autoAlpha: 1,
        }
      )
      .to(el("#leftPlotSvg, #rightPlotSvg"), {
        yPercent: 100,
        scale: 0,
        autoAlpha: 0,
      })
      .from(el(".coinstack-wrap"), {
        yPercent: 100,
        scale: 0,
        autoAlpha: 0,
      });

    pbTl.current
      .to(el("#p-building-1"), {
        yPercent: 125,
        xPercent: -300,
      })
      .to(el("#p-building-1"), {
        yPercent: 250,
        xPercent: -570,
      })
      .to(
        el("#p-building-2"),
        {
          yPercent: 540,
          xPercent: -480,
        },
        "<"
      )
      .to(
        el("#p-building-3"),
        {
          yPercent: 370,
          xPercent: -100,
        },
        "<"
      )
      .to(
        el("#p-building-4"),
        {
          yPercent: 340,
          xPercent: 90,
        },
        "<"
      )
      .to(
        el("#p-building-5"),
        {
          yPercent: 480,
          xPercent: 275,
        },
        "<"
      )
      .to(
        el("#p-building-6"),
        {
          yPercent: 130,
          xPercent: 505,
        },
        "<"
      )
      .to(el("#p-building-6"), {
        yPercent: 270,
        xPercent: 1060,
      })
      .to(
        el(
          "#p-building-1,#p-building-2,#p-building-3,#p-building-4,#p-building-5,#p-building-6"
        ),
        {
          y: 100,
          autoAlpha: 0,
        }
      )
      .to(el(".phone-block"), {
        yPercent: -100,
        autoAlpha: 0,
      })
      .from(el(".phone-building-img"), {
        y: -100,
        autoAlpha: 0,
      });

    ScrollTrigger.create({
      animation: pbTl.current,
      trigger: el("#phoneBlockSection"),
      start: "top top",
      end: "+=100%",
      scrub: 1,
      pin: true,
    });

    ScrollTrigger.create({
      animation: scTl.current,
      trigger: el(".sticky-contract"),
      start: "center center",
      end: "+=100%",
      scrub: 1,
      pin: true,
    });

    // CONTRACT TIMELINE END
  }, []);

  return (
    <>
      <Helmet>
        <title>Technology | Blockchain</title>
      </Helmet>
      <main className="bc-page" ref={pageRef}>
        <section className="inner-hero space-bottom">
          <Container>
            <Row className="justify-content-center text-md-center">
              <Col lg={9}>
                <div className="bc-text">
                  <p className="subtitle no-line">
                    Technology {LPSVGIcons.AngleArrow} Blockchain
                  </p>
                  <h1 className="color-white small mb-3">
                    Lilypads Distributed Ledger Network
                  </h1>
                  <p className="color-light-dark font-urbanist">
                    The Lilypads platform has created an unprecedented network
                    of data providers. When you’re transacting on Lilypads, you
                    are transacting on a deal flow network which encompasses
                    leading industry data providers from across the industry
                  </p>
                </div>
              </Col>
            </Row>
            <div id="phoneBlockSection" className="phone-block-section">
              <Row className=" justify-content-center text-center gx-5">
                <Col xs={{ span: 6 }} lg={{ span: 3 }}>
                  <div className="blockchain-cat blockchain-cat-left">
                    <p className="mb-0 color-white">
                      Real Estate Asset for Fractionalization
                    </p>
                  </div>
                </Col>
                <Col xs={{ span: 6 }} lg={{ span: 3, order: 3 }}>
                  <div className="blockchain-cat blockchain-cat-right">
                    <p className="mb-0 color-white">
                      Real Estate Portfolio for Fractionalization
                    </p>
                  </div>
                </Col>
                <Col lg={{ order: 2 }}>
                  <div className="phone-block">{LPSVGIcons.PhoneBuilding}</div>
                </Col>
              </Row>

              <Row className="align-items-end">
                <Col xs={2}>
                  <div className="phone-building-img">
                    <Image src={PhoneBuilding1} className="img-contain" fluid />
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="phone-building-img">
                    <Image src={PhoneBuilding2} className="img-contain" fluid />
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="phone-building-img">
                    <Image src={PhoneBuilding3} className="img-contain" fluid />
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="phone-building-img">
                    <Image src={PhoneBuilding4} className="img-contain" fluid />
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="phone-building-img">
                    <Image src={PhoneBuilding5} className="img-contain" fluid />
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="phone-building-img">
                    <Image src={PhoneBuilding6} className="img-contain" fluid />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className="smart-contract text-center">
          <div className="smart-contract-inner">
            <div className="smart-contract-content">
              <p className="color-white big mb-sm-1 mb-2 fw-700">
                Smart Contract
              </p>
              <p className="color-light-dark mb-0 small">
                We leverage both the security of Blockchain and real-world data
                inputs to digitize assets as NFTs, automate transactions, manage
                risks and others
              </p>
            </div>
          </div>
          <Image
            src={SmartContractBg}
            className="smart-contract-bg img-contain"
            fluid
          />
        </section>
        <section className="text-center">
          <div className="smart-contract-releases bg-dark space-small-top space-bottom">
            <Container className="space-bottom">
              <div className="smart-contract-wrap">
                <div className="sticky-contract space-top space-bottom">
                  <Row className="justify-content-center">
                    <Col xl={10}>
                      <Row className="align-items-center g-5">
                        <Col
                          xs={{
                            span: 6,
                            order: 2,
                          }}
                          lg={4}
                        >
                          <div className="sc-svg">{LPSVGIcons.BCLeft}</div>

                          <div className="sc-plot">
                            {LPSVGIcons.BlockchainLeftPlot}
                          </div>
                        </Col>
                        <Col
                          xs={{
                            span: 12,
                            order: 1,
                          }}
                          lg={{
                            span: 4,
                            order: 2,
                          }}
                        >
                          <div className="space-small-top space-small-bottom">
                            <p
                              id="sc-text-1"
                              className="sc-contract-mid-text text-white mb-0"
                            >
                              NFT issued by Lilypads blockchain network
                            </p>
                            <p
                              id="sc-text-2"
                              className="sc-contract-mid-text text-white mb-0"
                            >
                              <span className="d-flex align-items-center justify-content-center">
                                <Image
                                  src={SCLily}
                                  className={"img-contain mb-4"}
                                  fluid
                                />
                              </span>
                              Shares released by Lilypads against NFTs
                            </p>
                            <p
                              id="sc-text-3"
                              className="sc-contract-mid-text text-white mb-0"
                            >
                              Shares listed on lilypads platform for investors
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={{
                            span: 6,
                            order: 3,
                          }}
                          lg={4}
                        >
                          <div className="sc-svg">{LPSVGIcons.BCRight}</div>
                          <div className="sc-plot">
                            {LPSVGIcons.BlockchainRightPlot}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xl={10}>
                      <Row className="align-items-end">
                        <Col xs={6} md={3}>
                          <div className="coinstack-wrap coinstack-lg">
                            {LPSVGIcons.CoinStackLG}
                          </div>
                        </Col>
                        <Col xs={6} md={3}>
                          <div className="coinstack-wrap coinstack-md">
                            {LPSVGIcons.CoinStackMD}
                          </div>
                        </Col>
                        <Col xs={6} md={3}>
                          <div className="coinstack-wrap coinstack-sm">
                            {LPSVGIcons.CoinStackSM}
                          </div>
                        </Col>
                        <Col xs={6} md={3}>
                          <div className="coinstack-wrap coinstack-md-2">
                            {LPSVGIcons.CoinStackMD}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </section>
        <section className="lilypads-securities space-top">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row className="align-items-center">
                  <Col lg={4}>
                    <h2 className="mb-1">Lilypads Securities</h2>
                    <p className="small">
                      Our trading platform enables investors to place trades and
                      monitor accounts through financial intermediaries.
                      Besides, you can access bonus features like real-time
                      quotes, charting tools, news feeds, and even premium
                      research.
                    </p>
                    <a href="/join-waiting-list" className="d-none d-md-block">
                      <Button
                        variant="none"
                        className="bold btn-purple fw-600 mt-4"
                      >
                        Join the waiting list
                      </Button>
                    </a>
                  </Col>
                  <Col
                    xs={{
                      order: 3,
                    }}
                    lg={{
                      span: 5,
                      order: 2,
                    }}
                  >
                    <div className="securities-img-wrapper d-none d-md-block">
                      <Image src={SecuritiesMob} fluid />
                    </div>
                    <div className="securities-img-wrapper securities-img-wrapper-mob d-block d-md-none pt-5">
                      <Image src={SecuritiesMob} fluid />
                    </div>
                  </Col>
                  <Col
                    xs={{
                      order: 2,
                    }}
                    lg={{
                      span: 3,
                      order: 3,
                    }}
                    className="mt-4 mt-lg-0"
                  >
                    <div className="d-flex mb-3">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p className="font-lexend mb-4 fw-400 small">
                        Receive current prices of stocks, real time market
                        updates and quotes
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p className="font-lexend mb-4 fw-400 small">
                        Ramp up your trading with advanced charting and visual
                        solutions
                      </p>
                    </div>
                    <div className="d-flex">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p className="font-lexend mb-4 fw-400 small">
                        Create winning predictions with our unmatched Analytical
                        tools
                      </p>
                    </div>{" "}
                    <div className="d-flex">
                      <span className="me-3 d-inline-block">
                        {LPSVGIcons.DoubleCheck}
                      </span>
                      <p className="font-lexend mb-4 fw-400 small">
                        Zero gas fees for every transaction on the Lilypads
                        securities platform
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

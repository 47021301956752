import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Typical from 'react-typical'
export default function LPJoinWaitingListHero() {
  const [BrokerCB, setBrokerCB] = useState(false)
  const [investmentCB, setInvestmentCB] = useState(false)
  const [lenderCB, setLenderCB] = useState(false)
  const [VentureFundCB, setVentureFundCB] = useState(false)
  const [DealbyDayCB, setDealbyDayCB] = useState(false)
  const [partnershipCB, setPartnershipCB] = useState(false)
  const [instituitonCB, setInstituitonCB] = useState(false)
  const [otherCB, setOtherCB] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const handleSUBMIT = (e) => {
    e.preventDefault()
    // var role =
    //   (BrokerCB ? 'Broker,' : '') +
    //   (investmentCB ? 'Retail Investor,' : '') +
    //   (lenderCB ? ' Lender' : '') +
    //   (VentureFundCB ? ' Venture Fund,' : '') +
    //   (DealbyDayCB ? ' Deal by Day,' : '') +
    //   (partnershipCB ? ' Partnership,' : '') +
    //   (otherCB ? ' Other' : '')
    var role =
      (BrokerCB ? 'Broker,' : '') +
      (investmentCB ? 'Retail Investor,' : '') +
      (lenderCB ? ' Lender' : '') +
      (instituitonCB ? ' Institution' : '')

    console.log('====================================')
    console.log(role)
    console.log('====================================')

    const config = {
      headers: {
        'X-Api-Key': 'FWwKVWXl.9wYfvH87RqYpfZCCp32IiraFjfQ1uVUf',
      },
    }
    const body = new FormData()
    body.append('first_name', firstName)
    body.append('last_name', lastName)
    body.append('email', email)
    body.append('role', role)
    body.append('company', phone)
    body.append('message', message)

    console.log(body)

    const url = `https://api.cogniertechnology.com/api/waitlist/`
    // const url = `https://business.lilypads.com/api/waitlist/`

    axios
      .post(url, body, config)
      .then((res) => {
        console.log(res)
        // alert('Thank you for subscribing to our newsletter')
        alert('Thank you for joining our waiting list')
        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error)
        alert('Something went wrong')
        // alert('Failed toget Details')
      })
  }
  return (
    <section className="inner-hero join-waiting-list-hero">
      <div className="position-relative space-bottom">
        <Container>
          <Row className="gy-5">
            <Col lg={7} className="order-lg-last text-end">
              <Image fluid src="https://lilypads.s3.us-east-2.amazonaws.com/images/join-waiting-list/hero.png" className="" />
            </Col>
            <Col lg={5}>
              <h1 className="small mb-5">
                Real estate capital markets made better <br />
                <span className="color-maroon typanim mb-5">
                  <div className="insSUPPORT">a</div>
                  <Typical
                    loop={Infinity}
                    wrapper="b"
                    steps={[
                      'Retail investors ',
                      3000,
                      'Institutional Investors',
                      3000,
                      'Brokers',
                      3000,
                    ]}
                  />
                </span>
              </h1>
              {/* <h2>saas</h2> */}
              <Form onSubmit={handleSUBMIT}>
                <Row className="gx-5 gy-4">
                  <Col sm={6}>
                    <Form.Group className="" controlId="FName">
                      <Form.Control
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        type="text"
                        placeholder="First Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="" controlId="LName">
                      <Form.Control
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        type="text"
                        placeholder="Last Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="" controlId="Email">
                      <Form.Control
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        placeholder="Email Address"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="" controlId="PNo">
                      <Form.Control
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        type="text"
                        placeholder="Company"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group
                      className="position-relative icon-input-wrap"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <div className="input-icon">
                        {LPSVGIcons.MailAttachIcon}
                      </div>
                      <Form.Control
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        as="textarea"
                        placeholder="Drop your query..."
                        rows={5}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <p className="small mb-3 color-gray">
                      What would you relate to the most ?
                    </p>
                    <label htmlFor="broker" className="contact-chip">
                      <input
                        onClick={() => setBrokerCB(!BrokerCB)}
                        type="checkbox"
                        name="relate"
                        id="broker"
                      />
                      <span>Broker</span>
                    </label>
                    <label htmlFor="investment" className="contact-chip">
                      <input
                        onClick={() => setInvestmentCB(!investmentCB)}
                        type="checkbox"
                        name="relate"
                        id="investment"
                      />
                      <span>Retail Investor</span>
                    </label>
                    {/* <label htmlFor="Lender" className="contact-chip">
                      <input
                        onClick={() => setLenderCB(!lenderCB)}
                        type="checkbox"
                        name="relate"
                        id="Lender"
                      />
                      <span>Lender</span>
                    </label> */}
                    <label htmlFor="Institution" className="contact-chip">
                      <input
                        onClick={() => setInstituitonCB(!instituitonCB)}
                        type="checkbox"
                        name="relate"
                        id="Institution"
                      />
                      <span>Institution</span>
                    </label>
                    {/* <label htmlFor="VentureFund" className="contact-chip">
                      <input
                        onClick={() => setVentureFundCB(!VentureFundCB)}
                        type="checkbox"
                        name="relate"
                        id="VentureFund"
                      />
                      <span>Venture Fund</span>
                    </label>
                    <label htmlFor="Dealbydeal" className="contact-chip">
                      <input
                        onClick={() => setDealbyDayCB(!DealbyDayCB)}
                        type="checkbox"
                        name="relate"
                        id="Dealbydeal"
                      />
                      <span>Deal by deal</span>
                    </label> */}
                    {/* <label htmlFor="Partnerships" className="contact-chip">
                      <input
                        onClick={() => setPartnershipCB(!partnershipCB)}
                        type="checkbox"
                        name="relate"
                        id="Partnerships"
                      />
                      <span>Partnerships</span>
                    </label>
                    <label htmlFor="Others" className="contact-chip">
                      <input
                        onClick={() => setOtherCB(!otherCB)}
                        type="checkbox"
                        name="relate"
                        id="Others"
                      />
                      <span>Others</span>
                    </label> */}
                  </Col>
                  <Col sm={12}>
                    <Button
                      type="submit"
                      variant="none"
                      className="btn-purple w-100"
                    >
                      Join the waitlist
                    </Button>
                  </Col>
                  <Col sm={12}>
                    <p className="mb-0 contact-devider or text-center color-gray">
                      <span>OR</span>
                    </p>
                  </Col>
                  <Col sm={12}>
                    <Link
                      to={'/schedule'}
                      className="btn btn-bordered-purple w-100 d-flex align-items-center justify-content-center"
                    >
                      <span className="d-inline-block me-3">
                        {LPSVGIcons.CalendlyIcon}
                      </span>
                      Schedule meeting with calendly
                    </Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

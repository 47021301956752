import React from "react";
import { useEffect, useState } from "react";
import "./KnowledgeCenter.scss";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
const KnwoledgeCenterMain = () => {
  const [searchT, setSearchT] = useState("");
  const [mainData, setMainData] = useState("");
  const [loading, setLoading] = useState(true);
  const [mainMenu, setMainMenu] = useState({});
  const onlyWidth = useWindowWidth();
  const [navigationData, setNavigationData] = useState({
    mainIndex: 0,
    subIndex: 0,
    menu: "",
  });
  const [rightMenu, setRightMenu] = useState([]);
  const [menuSelector, setMenuSelector] = useState(0);
  const [accordianData, setAccordianData] = useState([]);
  const [submenuHead, setSubmenuHead] = useState("");
  const [rightmenuIndex, setRightmenuIndex] = useState(0);
  const [vModeMOB, setVModeMOB] = useState(false);
  const [dMode, setDMode] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    const config = {
      headers: {
        "X-Api-Key": "FWwKVWXl.9wYfvH87RqYpfZCCp32IiraFjfQ1uVUf",
      },
    };

    const url = `https://api.cogniertechnology.com/api/knowledge/`;
    // const url = `https://business.lilypads.com/api/waitlist/`
    axios
      .get(url, config)
      .then((res) => {
        console.log(res);
        setMainData(res.data);
        //setSessionStorage
        sessionStorage.setItem("knowledgeData", JSON.stringify(res.data));

        setAccordianData(res.data[0].submenu[0].knowledge[0].content);
        setRightMenu(res.data[0].submenu[0].knowledge);
        console.log("================eeeee====================");
        console.log(res.data[0].submenu[0].knowledge[0].title);
        console.log("====================================");
        setNavigationData({
          mainIndex: 0,
          subIndex: 0,
          menu: res.data[0].submenu[0].knowledge[0].title,
        });
        setSubmenuHead(res.data[0].submenu[0].knowledge[0].title);
        setLoading(false);
        // alert('Thank you for subscribing to our newsletter')
        // alert("Thank you for joining our waiting list");
        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        // alert("Something went wrong");
        // alert('Failed toget Details')
      });
  }, []);

  // useEffect(() => {
  //   //store in session storage
  //   sessionStorage.setItem("submenuHead", JSON.stringify(submenuHead));
  // }, [submenuHead]);

  useEffect(() => {
    let mainMenuARR = [];

    if (mainData === "") {
      return;
    } else {
      mainData.map((item, index) => {
        let subMenuARR = [];
        item.submenu.map((subItem, subIndex) => {
          let subMapr = {
            menu: subItem.submenu,
            data: subItem.knowledge,
          };
          //   subMenuARR.push(subItem.submenu);
          subMenuARR.push(subMapr);
        });

        let dataCon = {
          menu: item.menu,
          icon: item.image_url,
          submenu: subMenuARR,
        };
        mainMenuARR.push(dataCon);
      });
      setMainMenu(
        mainMenuARR.map((item, index) => {
          return (
            <div
              onClick={() => {
                console.log("main " + index);
                setNavigationData({
                  menu: item.menu,
                  mainIndex: index,
                  subIndex: 0,
                });
              }}
              className="kc-l-con"
            >
              <div className="kc-l-con-t">
                <img src={item.icon} alt="" />
                {item.menu}
              </div>
              <div className="kc-l-con-b">
                {item.submenu.map((subItem, subIndex) => {
                  return (
                    <div className="menuHolder">
                      <p
                        className={
                          menuSelector === index + "" + subIndex && " active"
                        }
                        onClick={() => {
                          console.log(subItem);
                          sessionStorage.setItem("subItem", subItem.menu);
                          setNavigationData({
                            menu: item.menu,
                            mainIndex: index,
                            subIndex: subIndex,
                          });
                          setMenuSelector(index + "" + subIndex);
                          setRightMenu(subItem.data);
                        }}
                      >
                        {subItem.menu}
                      </p>

                      <ul
                        className={
                          (menuSelector === index + "" + subIndex &&
                            " active") + " uprUL"
                        }
                      >
                        {rightMenu.length > 0 ? (
                          <>
                            {/* map rightMenu data */}
                            {rightMenu.map((item, i) => {
                              return (
                                <li
                                  className={
                                    i + (rightmenuIndex === i && " active ")
                                  }
                                  onClick={() => {
                                    console.log(item.content);
                                    setAccordianData(item.content);

                                    sessionStorage.setItem(
                                      "accordianData",
                                      JSON.stringify(item.content)
                                    );

                                    setSubmenuHead(item.title);
                                    setRightmenuIndex(i);

                                    sessionStorage.setItem(
                                      "submenuHead",
                                      item.title
                                    );
                                    navigate("/knowledgeCenterM");

                                    // setDMode(2)
                                  }}
                                >
                                  {item.title}
                                </li>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      );
    }
  }, [mainData, menuSelector]);
  // window.onbeforeunload = function () { return "Your work will be lost."; };
  useEffect(() => {
    //add class on page load and remove on page change

    document.querySelector("body").classList.remove("header-secondary");
    document.querySelector("body").classList.add("header-none");

    return () => {
      document.querySelector("body").classList.remove("header-none");
    };
  }, []);

  useEffect(() => {
    if (onlyWidth > 768) {
      document.querySelector("body").classList.remove("header-secondary");
      document.querySelector("body").classList.add("header-none");
      setVModeMOB(false);
    } else {
      setVModeMOB(true);
      document.querySelector("body").classList.remove("header-none");
      // document.querySelector("body").classList.add("header-secondary");
    }
  }, [onlyWidth]);

  useEffect(() => {
    setDMode(1);
    let searchData = [];
    if (mainData === "") {
      return;
    } else {
      if (searchT === "") {
        setAccordianData(
          mainData[navigationData.mainIndex].submenu[navigationData.subIndex]
            .knowledge[0].content
        );
        setSubmenuHead(
          mainData[navigationData.mainIndex].submenu[navigationData.subIndex]
            .knowledge[0].title
        );
        setRightMenu(
          mainData[navigationData.mainIndex].submenu[navigationData.subIndex]
            .knowledge
        );
        setRightmenuIndex(0);
      } else {
        mainData.map((item, index) => {
          item.submenu.map((subItem, subIndex) => {
            subItem.knowledge.map((knwItem, knwIndex) => {
              if (knwItem.title.toLowerCase().includes(searchT.toLowerCase())) {
                searchData.push(knwItem);
              }
            });
          });
        });

        // setAccordianData(searchData);
        try {
          setAccordianData(searchData[0].content);
          setSubmenuHead("Search Results");
          setRightMenu(searchData);
          setRightmenuIndex(0);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [searchT]);

  const FDTList = [
    {
      title: "General Services",
      text: (
        <ul>
          <li>Private Placements</li>
          <li>Raise on a deal-by-deal basis</li>
        </ul>
      ),
    },
    {
      title: "Fundraising Services",
      text: (
        <ul>
          <li>
            Acquiring and introduction to LP's via Lilypads platform. Vetted
            capital network algorithm for top tier deals
          </li>
        </ul>
      ),
    },
    {
      title: "Investment Advisory Services",
      text: (
        <ul>
          <li>KYC/AML checks for all LPs and GPs</li>
          <li>
            Conduct review of investments for lifetime of the fund to ensure
            compliance with fund thesis
          </li>
        </ul>
      ),
    },
    {
      title: "Fund Setup Services",
      text: (
        <ul>
          <li>Turn Key Special Purchase Vehicle setup</li>
          <li>Generate standardized fund agreements and formation documents</li>
          <li>
            Prepare regulatory filings and registration services on behalf of
            the funds
          </li>
          <li>
            Setup and manage all fund bank accounts and handle wiring funds
          </li>
          <li>Streamline fund transfer and distribution process</li>
        </ul>
      ),
    },
    {
      title: "Fund Account Management",
      text: (
        <ul>
          <li>
            Dedicated account manager to help run fund operations and answer
            questions
          </li>
          <li>Dividend Re-investment</li>
          <li>Auto Invest</li>
          <li>Create and manage investment goals</li>
          <li>Invest via RIA</li>
          <li>Directly allocate the funds</li>
        </ul>
      ),
    },
    {
      title: "Portfolio Management Services",
      text: (
        <ul>
          <li>
            Streamline all investment after professional review of term sheets
          </li>
          <li>Track active investment by cost, value, multiple and date</li>
          <li>Market index the CRE investment landscape</li>
          <li>Provide live valuation, position and finance reporting</li>
          <li>
            Provide all capital balance valuation details and IRR return
            information
          </li>
        </ul>
      ),
    },
    {
      title: "Investor Communication",
      text: (
        <ul>
          <li>
            Provide fund manager with a secure online portal to communicate with
            fund LPs
          </li>
          <li>Generate template for investor updates</li>
          <li>Store record of communication for future reference</li>
          <li>
            Automatically generate portfolio updates based on curated public
            data sources
          </li>
        </ul>
      ),
    },
    {
      title: "LP Management and Reporting",
      text: (
        <ul>
          <li>
            Manage a fully electronic and integrated closing process for LPs
          </li>
          <li>Execute and store investment documents digitally</li>
          <li>
            Blockchain ledger to track LP commitments, contributed capital and
            distributions
          </li>
          <li>
            Prepare and distribute capital call notices through Lilypads
            platform
          </li>
          <li>
            Mark valuation information available at any time through the
            Lilypads platform
          </li>
          <li>
            Email notifications sent to LPs each time GP provides an updates
          </li>
        </ul>
      ),
    },
    {
      title: "Tax and Fund Administration",
      text: (
        <ul>
          <li>Maintain fund ownership ledger and capital account</li>
          <li>
            Conduct payment of ongoing fund registration and tax expenses for
            the life of the fund
          </li>
          <li>
            Prepare tax return and K-I distributions for the life of the fund
          </li>
          <li>Track and allocate income and expenses by tax characteristics</li>
        </ul>
      ),
    },
    {
      title: "Accounting Services",
      text: (
        <ul>
          <li>
            Facililate payment of fund expenses(covered in the fund LPA) from
            the fund's balance
          </li>
          <li>Support and calculate investor management fees</li>
          <li>
            Maintain accounting transaction log, downloadable by GP at any
            moment
          </li>
          <li>
            As per fund requirements, maintain books and records of the fund
          </li>
          <li>Track all accruals</li>
          <li>Ensure fund accounts held at bank/custodian are accurate</li>
          <li>
            Determine daily calculation of the net asset value of the fund
          </li>
          <li>
            Support live valuation of the fund based on the latest valuation
            data provided
          </li>
        </ul>
      ),
    },
    {
      title: "Additional Services",
      text: (
        <ul>
          <li>Provide quarterly or annual cash-basis financials</li>
          <li>Provide quarterly GAAP financials</li>
          <li>Support audit response for institutional LPs</li>
          <li>Fee waiver per successful invite</li>
        </ul>
      ),
    },
    {
      title: "Proprietary Software",
      text: (
        <ul>
          <li>Manage all investments from one dashboard</li>
          <li>View the funding status for each investor</li>
          <li>Seamlessly manage your investors and other general partners</li>
          <li>Network with investors and other general partners</li>
        </ul>
      ),
    },
    {
      title: "Cognitive Market Insights",
      text: (
        <ul>
          <li>Factor analysis</li>
          <li>Scenario analysis</li>
          <li>Economical analysis</li>
          <li>Drawdown analysis</li>
          <li>Correlations</li>
          <li>Performance attribution</li>
          <li>Asset category analysis</li>
        </ul>
      ),
    },
  ];
  return (
    <div className="KnwoledgeCenterMain sectionTwoCL">
      {loading ? <Loader /> : <></>}
      <div className={"knwoledgecenter-Header " + (vModeMOB && " mob")}>
        <div className="h-l">
          {!vModeMOB && (
            <>
              <svg
                width="103"
                height="62"
                viewBox="0 0 103 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M102.089 46.6028C96.3632 54.0577 88.0886 59.3822 78.3814 60.9594C68.4007 62.5812 58.7314 59.9447 51.0352 54.3389C56.7607 46.8841 65.0375 41.5595 74.7424 39.9823C84.7209 38.3629 94.3924 40.9971 102.089 46.6028Z"
                  fill="#322D76"
                />
                <path
                  d="M102.089 46.6028C96.3632 54.0577 88.0886 59.3822 78.3814 60.9594C68.4007 62.5812 58.7314 59.9447 51.0352 54.3389C56.7607 46.8841 65.0375 41.5595 74.7424 39.9823C84.7209 38.3629 94.3924 40.9971 102.089 46.6028Z"
                  stroke="white"
                />
                <path
                  d="M0 46.4669C7.50054 41.0345 16.8696 38.4074 26.6034 39.8018C36.6108 41.2361 45.1701 46.6591 51.0357 54.3389C43.5352 59.7712 34.164 62.3983 24.4324 61.0039C14.4294 59.5673 5.86786 54.1443 0 46.4669Z"
                  fill="#322D76"
                />
                <path
                  d="M0 46.4669C7.50054 41.0345 16.8696 38.4074 26.6034 39.8018C36.6108 41.2361 45.1701 46.6591 51.0357 54.3389C43.5352 59.7712 34.164 62.3983 24.4324 61.0039C14.4294 59.5673 5.86786 54.1443 0 46.4669Z"
                  stroke="white"
                />
                <g opacity="0.8">
                  <path
                    d="M84.9669 13.4102C85.2383 22.9976 82.2554 32.7163 75.8692 40.5789C69.3029 48.6618 60.3388 53.3044 51.0365 54.3402C50.7651 44.7528 53.748 35.0341 60.1341 27.1715C66.6982 19.0886 75.6624 14.4437 84.9669 13.4102Z"
                    fill="#A6244B"
                  />
                  <path
                    d="M84.9669 13.4102C85.2383 22.9976 82.2554 32.7163 75.8692 40.5789C69.3029 48.6618 60.3388 53.3044 51.0365 54.3402C50.7651 44.7528 53.748 35.0341 60.1341 27.1715C66.6982 19.0886 75.6624 14.4437 84.9669 13.4102Z"
                    stroke="white"
                  />
                </g>
                <g opacity="0.8">
                  <path
                    d="M17.6905 12.8779C26.7281 14.0286 35.4031 18.5845 41.8292 26.4119C48.4356 34.4597 51.454 44.49 51.0359 54.3376C41.9983 53.1869 33.3211 48.6287 26.8971 40.8036C20.2908 32.7582 17.2723 22.7255 17.6905 12.8779Z"
                    fill="#A6244B"
                  />
                  <path
                    d="M17.6905 12.8779C26.7281 14.0286 35.4031 18.5845 41.8292 26.4119C48.4356 34.4597 51.454 44.49 51.0359 54.3376C41.9983 53.1869 33.3211 48.6287 26.8971 40.8036C20.2908 32.7582 17.2723 22.7255 17.6905 12.8779Z"
                    stroke="white"
                  />
                </g>
                <path
                  d="M51.5602 0C57.6817 7.09861 61.4142 16.5244 61.4142 26.8735C61.4142 37.5132 57.4681 47.1803 51.0375 54.3398C44.9161 47.2412 41.1836 37.8131 41.1836 27.4664C41.1836 16.829 45.1296 7.15954 51.5602 0Z"
                  fill="#765EA8"
                />
                <path
                  d="M51.5602 0C57.6817 7.09861 61.4142 16.5244 61.4142 26.8735C61.4142 37.5132 57.4681 47.1803 51.0375 54.3398C44.9161 47.2412 41.1836 37.8131 41.1836 27.4664C41.1836 16.829 45.1296 7.15954 51.5602 0Z"
                  stroke="white"
                />
              </svg>
            </>
          )}
        </div>
        <div className={"h-m " + (vModeMOB && " mob")}>
          <div data-aos="fade-up" className="searchBOX">
            {/* <span>Search</span> */}
            <div className="inputHolder">
              <input
                placeholder="Search for keywoard..."
                value={searchT}
                onChange={(e) => {
                  // setSearchMode(1);
                  setSearchT(e.target.value);
                }}
              />
              {/* <img src={sImg} alt="" /> */}
              <div className="SearchBtn">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2635 20.5271C12.5407 20.5266 14.7523 19.7643 16.5461 18.3615L22.1859 24.0013L24 22.1872L18.3602 16.5474C19.7637 14.7534 20.5265 12.5413 20.5271 10.2635C20.5271 4.60448 15.9226 0 10.2635 0C4.60448 0 0 4.60448 0 10.2635C0 15.9226 4.60448 20.5271 10.2635 20.5271ZM10.2635 2.56588C14.5088 2.56588 17.9612 6.01828 17.9612 10.2635C17.9612 14.5088 14.5088 17.9612 10.2635 17.9612C6.01828 17.9612 2.56588 14.5088 2.56588 10.2635C2.56588 6.01828 6.01828 2.56588 10.2635 2.56588Z"
                    fill="#7C5AC7"
                  />
                  <path
                    d="M12.0738 8.4496C12.5601 8.93712 12.8282 9.58116 12.8282 10.2637H15.3941C15.3952 9.5894 15.2627 8.92159 15.0042 8.29884C14.7456 7.67609 14.3662 7.11075 13.8879 6.63552C11.9455 4.69571 8.57781 4.69571 6.63672 6.63552L8.44823 8.45217C9.42327 7.4797 11.1039 7.48226 12.0738 8.4496Z"
                    fill="#7C5AC7"
                  />
                </svg>
              </div>
            </div>
          </div>
          {vModeMOB && (
            <>
              {dMode === 1 && (
                <>
                  <p>Browse help topics</p>
                </>
              )}
            </>
          )}
        </div>

        {!vModeMOB && (
          <>
            <div className="h-r">
              <a href="/contact">
                <div className="btncon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9974 3H5.33073C4.41156 3 3.66406 3.7475 3.66406 4.66667V8.83333H5.33073V6.33333L10.6641 10.3333C10.8084 10.4414 10.9838 10.4998 11.1641 10.4998C11.3443 10.4998 11.5198 10.4414 11.6641 10.3333L16.9974 6.33333V13.8333H10.3307V15.5H16.9974C17.9166 15.5 18.6641 14.7525 18.6641 13.8333V4.66667C18.6641 3.7475 17.9166 3 16.9974 3ZM11.1641 8.625L5.88573 4.66667H16.4424L11.1641 8.625Z"
                      fill="#7D59C3"
                    />
                    <path
                      d="M2 9.66699H7.83333V11.3337H2V9.66699ZM3.66667 12.167H8.66667V13.8337H3.66667V12.167ZM6.16667 14.667H9.5V16.3337H6.16667V14.667Z"
                      fill="#7D59C3"
                    />
                  </svg>
                  Contact Us
                </div>
              </a>

              <a href="/" className="btncon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.66797 5.83301V9.16634L1.66797 4.99967L6.66797 0.833008V4.16634H10.8346C12.6027 4.16634 14.2984 4.86872 15.5487 6.11896C16.7989 7.3692 17.5013 9.0649 17.5013 10.833C17.5013 12.6011 16.7989 14.2968 15.5487 15.5471C14.2984 16.7973 12.6027 17.4997 10.8346 17.4997H3.33464V15.833H10.8346C12.1607 15.833 13.4325 15.3062 14.3702 14.3685C15.3079 13.4309 15.8346 12.1591 15.8346 10.833C15.8346 9.50693 15.3079 8.23516 14.3702 7.29747C13.4325 6.35979 12.1607 5.83301 10.8346 5.83301H6.66797Z"
                    fill="#7D59C3"
                  />
                </svg>
                Back Home
              </a>
            </div>
          </>
        )}
      </div>
      <div className="KC-content">
        {dMode === 1 && (
          <>
            <div className="KC-content-L">
              {mainMenu.length > 0 ? mainMenu : <></>}
            </div>
          </>
        )}

        {dMode === 2 && (
          <>
            {vModeMOB && (
              <>
                <div className="KC-content-M">
                  <div className="kc-m-con-title">
                    {submenuHead === "" ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M27.9987 19.0339C24.8596 23.1211 20.3229 26.0404 15.0008 26.9051C9.52877 27.7943 4.22742 26.3488 0.0078125 23.2753C3.14691 19.1881 7.68482 16.2688 13.0057 15.4041C18.4765 14.5162 23.7791 15.9604 27.9987 19.0339Z"
                            fill="#7C5AC7"
                          />
                          <path
                            d="M27.9987 19.0339C24.8596 23.1211 20.3229 26.0404 15.0008 26.9051C9.52877 27.7943 4.22742 26.3488 0.0078125 23.2753C3.14691 19.1881 7.68482 16.2688 13.0057 15.4041C18.4765 14.5162 23.7791 15.9604 27.9987 19.0339Z"
                            stroke="white"
                          />
                          <g opacity="0.8">
                            <path
                              d="M18.6122 0.835937C18.7609 6.09241 17.1255 11.4208 13.6242 15.7316C10.0242 20.1632 5.10942 22.7086 0.00929944 23.2765C-0.139484 18.02 1.49592 12.6916 4.99722 8.3808C8.59608 3.94922 13.5108 1.40257 18.6122 0.835937Z"
                              fill="#9E2349"
                            />
                            <path
                              d="M18.6122 0.835937C18.7609 6.09241 17.1255 11.4208 13.6242 15.7316C10.0242 20.1632 5.10942 22.7086 0.00929944 23.2765C-0.139484 18.02 1.49592 12.6916 4.99722 8.3808C8.59608 3.94922 13.5108 1.40257 18.6122 0.835937Z"
                              stroke="white"
                            />
                          </g>
                        </svg>
                      </>
                    )}

                    {submenuHead}
                  </div>
                  <Accordion
                    defaultActiveKey="0"
                    className="accordionMain   p-5"
                  >
                    {accordianData.length > 0 ? (
                      <>
                        {accordianData.map((item, i) => {
                          return (
                            <Accordion.Item
                              key={i}
                              eventKey={`${i}`}
                              className="bg-transparent border-0"
                            >
                              <Accordion.Header className="bg-transparent">
                                {item.title}
                              </Accordion.Header>
                              <Accordion.Body className="">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.body,
                                  }}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}{" "}
                      </>
                    ) : (
                      <></>
                    )}
                  </Accordion>
                </div>
              </>
            )}
          </>
        )}

        {!vModeMOB && (
          <>
            <div className="KC-content-M">
              <div className="kc-m-con-title">
                {submenuHead === "" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.9987 19.0339C24.8596 23.1211 20.3229 26.0404 15.0008 26.9051C9.52877 27.7943 4.22742 26.3488 0.0078125 23.2753C3.14691 19.1881 7.68482 16.2688 13.0057 15.4041C18.4765 14.5162 23.7791 15.9604 27.9987 19.0339Z"
                        fill="#7C5AC7"
                      />
                      <path
                        d="M27.9987 19.0339C24.8596 23.1211 20.3229 26.0404 15.0008 26.9051C9.52877 27.7943 4.22742 26.3488 0.0078125 23.2753C3.14691 19.1881 7.68482 16.2688 13.0057 15.4041C18.4765 14.5162 23.7791 15.9604 27.9987 19.0339Z"
                        stroke="white"
                      />
                      <g opacity="0.8">
                        <path
                          d="M18.6122 0.835937C18.7609 6.09241 17.1255 11.4208 13.6242 15.7316C10.0242 20.1632 5.10942 22.7086 0.00929944 23.2765C-0.139484 18.02 1.49592 12.6916 4.99722 8.3808C8.59608 3.94922 13.5108 1.40257 18.6122 0.835937Z"
                          fill="#9E2349"
                        />
                        <path
                          d="M18.6122 0.835937C18.7609 6.09241 17.1255 11.4208 13.6242 15.7316C10.0242 20.1632 5.10942 22.7086 0.00929944 23.2765C-0.139484 18.02 1.49592 12.6916 4.99722 8.3808C8.59608 3.94922 13.5108 1.40257 18.6122 0.835937Z"
                          stroke="white"
                        />
                      </g>
                    </svg>
                  </>
                )}

                {submenuHead}
              </div>
              <Accordion defaultActiveKey="0" className="accordionMain   p-5">
                {accordianData.length > 0 ? (
                  <>
                    {accordianData.map((item, i) => {
                      return (
                        <Accordion.Item
                          key={i}
                          eventKey={`${i}`}
                          className="bg-transparent border-0"
                        >
                          <Accordion.Header className="bg-transparent">
                            {item.title}
                          </Accordion.Header>
                          <Accordion.Body className="">
                            <div
                              dangerouslySetInnerHTML={{ __html: item.body }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}{" "}
                  </>
                ) : (
                  <></>
                )}
              </Accordion>
            </div>
          </>
        )}
        {dMode === 1 && (
          <>
            <div className="KC-content-R">
              {navigationData.menu === "" ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className="kc-r-con-t">Under {navigationData.menu}</div>
                </>
              )}

              <div className="kc-r-con-t-p">
                {/* <p>Carry</p> */}
                {rightMenu.length > 0 ? (
                  <>
                    {/* map rightMenu data */}
                    {rightMenu.map((item, i) => {
                      return (
                        <p
                          className={i + (rightmenuIndex === i && " active ")}
                          onClick={() => {
                            console.log(item.content);
                            setAccordianData(item.content);

                            sessionStorage.setItem(
                              "accordianData",
                              JSON.stringify(item.content)
                            );

                            setSubmenuHead(item.title);
                            setRightmenuIndex(i);
                            if (vModeMOB) {
                              navigate("/knowledgeCenterM");
                            }
                            // setDMode(2)
                          }}
                        >
                          {item.title}
                        </p>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KnwoledgeCenterMain;

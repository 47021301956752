import React from 'react'
import secTwoIMG from '../../assets/images/newLanding/secTwo.gif'
import secTwoIMGLOT from '../../assets/images/newLanding/secondSec.json'
import Lottie from "lottie-react";

const LPNSecTwo = () => {
  return (
    <div className="LPNSecOne-MAIN">
      <div className="LPNSecOne">
        
        <div className="LPNSecOne-r">
        <Lottie animationData={secTwoIMGLOT} loop={true} />
          {/* <img src={secTwoIMG} alt="" /> */}
        </div>
        <div className="LPNSecOne-l">
          <div className="tcon">
            <h3>/ Why Now?</h3>
            <h2>
              Commercial real estate has proved{' '}
              <span> historically more stable </span> than stocks or bonds-
              hedging against inflation and yielding steady cash flow
            </h2>

            <a href="/story">
              Read More
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.999 10.5005H2.00098M17.999 10.5005L15.0445 14.046M17.999 10.5005L15.0445 6.95508"
                  stroke="#7C5AC7"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LPNSecTwo

import { Container, Row, Col, Image } from 'react-bootstrap'
import LPSVGIcons from '../../../Components/LPSVGIcons/LPSVGIcons'
import { Discoverperpectivecard } from '../../../Components/Discover/Discoverperpectivecard'
import pink1 from '../../../assets/images/Manage/01 (15).png'
import pink2 from '../../../assets/images/Manage/02 (15).png'
import pink3 from '../../../assets/images/Manage/03 (13).png'
import pink4 from '../../../assets/images/Manage/04 (5).png'
import pink5 from '../../../assets/images/Manage/05 (2).png'

import letter from '../../../assets/images/Manage/letter 1.png'
import balancesheet from '../../../assets/images/Manage/balance-sheet 1.png'
import journal from '../../../assets/images/Manage/journal 1.png'
import secure from '../../../assets/images/Manage/secure 1.png'
import favorite from '../../../assets/images/Manage/favorite 1.png'

import cashflow from '../../../assets/images/Manage/Group 22110.png'
import ReviewInvestments from '../../../assets/images/Manage/bx-file-find 2.png'
import ShareDocuments from '../../../assets/images/Manage/bx-copy-alt 1.png'

export default function LPInvestSection3(){
    return(
         <>
          <div className="LPDiscoverSection3  .Discoverresponsiveness ">
          <section className="space-bottom ">
        <Container style={{marginTop:100}}>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={5}>
                  <p className="subtitle">
                  Comprehensive SPV administration
                  </p>
                  <h1 className="small">From square one toshut-down</h1>
                </Col>
                <Col lg={6}>
                  <p className="font-lexend">
                    Setting up a syndicate or special purpose vehicle (SPV) to raise capital for a new venture is overwhelming. Lilypads bundle a full suite of services that simplify, expedite, and consolidate the SPV investment lifecycle
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        </section>
       <Col lg={12}>
            <div className='Legalcards'>
                 <div >
                      <img className='Legalcardsone' src={pink1}/>
                     <div className='legalone lettericon'>
                       <img className='Legalcardsone ' src={letter}/>
                       <h6 className='legalheading'>Legal</h6>
                     </div>
                 </div>
                 <div >
                      <img className='Legalcardsone' src={pink2}/>
                     <div className='legalone lettericon'>
                       <img className='Legalcardsone' src={balancesheet}/>
                       <h6 className='legalheading'>Tax</h6>
                     </div>
                 </div>
                 <div >
                      <img className='Legalcardsone' src={pink3}/>
                     <div className='legalone lettericon'>
                       <img className='Legalcardsone' src={journal}/>
                       <h6 className='legalheading'>Accounting</h6>
                     </div>
                 </div>
                 <div >
                      <img className='Legalcardsone' src={pink4}/>
                     <div className='legalone lettericon'>
                       <img className='Legalcardsone' src={secure}/>
                       <h6 className='legalheading'>Compliance</h6>
                     </div>
                 </div>
                 <div >
                      <img className='Legalcardsone' src={pink5}/>
                     <div className='legalone lettericon'>
                       <img className='Legalcardsone' src={favorite}/>
                       <h6 className='legalheading'>Adminstration</h6>
                     </div>
                 </div>
                
            </div>
       </Col>
        <Container>
          <Row className="justify-content-center Section3space">
          
            <Col lg={12}>
            <div className='discoverfeatureresponsiveness'>
           
            {/* <Col lg={6}> */}
                     <div className=''>
                       <h1 className='Custombuilt'>Custom built portfolio using data centric approach</h1>
                       <div style={{marginBottom:30}}>
                       <Col lg={11}>
                           <img className='featuresicons' src={ReviewInvestments}/>
                           <Discoverperpectivecard
                           CardHeading='Review Investmentst​'
                           Cardparagraph='Provide investors with real-time, 24/7 access to a summary of their investment positions, and their invested and distributed capital.'
                           />
                            </Col>
                       </div>
                       <div>
                       <Col lg={11}>
                       <img className='featuresicons' src={ShareDocuments}/>
                       <Discoverperpectivecard
                           CardHeading='Individual property investment'
                           Cardparagraph='Investors can visit the Lilypads Investor Portal to easily find reports, tax forms, and other shared documents and securely sign agreements.                           ​'
                           />
                            </Col>
                       </div>
                      
                     </div>
                     <div >
                     
                     </div>
                 {/* </Col>
                 <Col lg={6}> */}
                 <div className='cashflowimg'>
                     <img className='cashflow' src={cashflow}/>
                 </div>
                 {/* </Col> */}
            </div>
            </Col>
          </Row>
        </Container>
        </div>
        </>
        
        );
}
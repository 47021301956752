import { ArrowLeft, ArrowRight } from 'iconsax-react'
import { useRef, useState, useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import './LHSchedule.scss'

export default function LPSchedule() {
  const slider = useRef(null)

  const [ImageIndex, setImageIndex] = useState(0)

  const scheduleSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => {
      console.log(current)
      setImageIndex(next)
    },
  }

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js',
    )
    head.appendChild(script)
  }, [])

  const handleArrowPrev = (slider) => {
    slider?.current?.slickPrev()
    console.log('====================================')
    //get current slide index
    console.log(slider?.current?.state)

    console.log('====================================')
  }

  const handleArrowNext = (slider) => {
    slider?.current?.slickNext()
    console.log('====================================')
    console.log(slider?.current?.state)
    console.log('====================================')
  }

  return (
    <div className="inner-hero space-bottom network-hero">
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <p className="subtitle">Choose a time to speak with</p>

                  {ImageIndex === 0 ? (
                    <>
                      <h1 className={'small slick-activ '}>Joe Copsey</h1>
                    </>
                  ) : ImageIndex === 1 ? (
                    <>
                      {' '}
                      <h1 className={'small slick-activ '}>Thomas P. Lah </h1>
                    </>
                  ) : (
                    <>
                      {' '}
                      <h1 className={'small slick-activ '}>
                        Karthik Krishnan{' '}
                      </h1>
                    </>
                  )}
                </Col>
                <Col lg={6}></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="schedule-meeting-section space-small-top">
        <Container>
          <div className="SLIDERCALENDER-con">
            <div className="SLIDER-L">
              <Row className="justify-content-center">
                <Col>
                  <div className="schedule-slider">
                    <Slider ref={slider} {...scheduleSettings}>
                      <div className="schedule-item">
                        <div className="schedule-img">
                          <Image src="https://lilypads.s3.us-east-2.amazonaws.com/images/story/member2.png" className="img-cover" fluid />
                        </div>

                        <div className="schedule-quote">
                          Meet our culture agent and steward obsessed with
                          creating efficiencies and great experiences for our
                          clients and business partners
                        </div>
                      </div>

                      <div className="schedule-item">
                        <div className="schedule-img">
                          <Image src="https://lilypads.s3.us-east-2.amazonaws.com/images/story/member1.png" className="img-cover" fluid />
                        </div>

                        <div className="schedule-quote">
                          Thomas P. Lah directs investment strategy, and
                          decisions, governance and investor relations.
                          ecological responsibility and diversity and inclusion
                          for the prosperity of all.
                        </div>
                      </div>

                      <div className="schedule-item">
                        <div className="schedule-img">
                          <Image src="https://lilypads.s3.us-east-2.amazonaws.com/images/story/member3.png" className="img-cover" fluid />
                        </div>

                        <div className="schedule-quote">
                          Karthik Krishnan is the co-founder and executive vice
                          president of the Lilypads Platform.
                        </div>
                      </div>
                    </Slider>
                    <div className="schedule-arrows">
                      <button
                        className="custom-arrow custom-prev-arrow"
                        onClick={() => handleArrowPrev(slider)}
                      >
                        <ArrowLeft size="20" color="#7C5AC7" />
                      </button>
                      <button
                        className="custom-arrow custom-prev-arrow"
                        onClick={() => handleArrowNext(slider)}
                      >
                        <ArrowRight size="20" color="#7C5AC7" />
                      </button>
                    </div>
                  </div>
                </Col>
                <Col lg={6}></Col>
              </Row>
            </div>
            <div className="SLIDER-R">
              <div
                className="calendly-inline-widget"
                data-url={'https://calendly.com/joe-copsey/15min?back'}
                style={{
                  minWidth: '520px',
                  height: '100%',
                  overflow: 'hidden',
                }}
              />
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}
